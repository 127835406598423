import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';

import { useGroups } from '../../hooks/useGroups';


export default function AddGroupButton({ groupLabel }) {
  const [label, setLabel] = useState('');
  const { addGroup } = useGroups();

  return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField 
              label={'Gruppenname'}
              variant="outlined"
              fullWidth
              value={label}
              onChange={event => setLabel(event.target.value) }
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={useCallback(() => {
                if (label) {
                  addGroup({ label, groupLabel });
                }
              }, [label, groupLabel, addGroup])}
            >
              Gruppe hinzufügen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
}
