import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import get from 'lodash/get';
import { completeFields, getFieldValue } from '../../utils/getAllFields';
import { useOwnFields } from '../hooks/useOwnFields';

export default function CSVExportButton({ title, persons, fields = completeFields }) {
  
  const { ownFields } = useOwnFields();

  const headers = useMemo(
    () => fields
      .map(field => ({ 
        ...field,
        key: get(field, 'name', ''), 
        label: get(field, 'label', ''),
      }))
      .concat(ownFields.map(({ label, fieldId }) => ({ label, key: fieldId, name: fieldId })))
      .concat([
        { 
          label: 'Erstellungsdatum', 
          key: 'created', 
          name: 'created',
          formatFunc: 'date', 
        },
        { 
          label: 'Anmeldedatum', 
          key: 'appliedDate', 
          name: 'appliedDate',
          formatFunc: 'date', 
        },
        { 
          label: 'Bestätiung TN', 
          key: 'confirmedOn', 
          name: 'confirmedOn',
          formatFunc: 'date', 
        },
        { 
          label: 'Bestätiung MA', 
          key: 'confirmedOnMitarbeiter', 
          name: 'confirmedOnMitarbeiter',
          formatFunc: 'date', 
        },
        {
          label: 'TN-Betrag', 
          key: 'hasToPay', 
          name: 'hasToPay',
        },
        {
          label: 'Bereits bezahlt', 
          key: 'paid', 
          name: 'paid',
        },
        {
          label: 'Übertrag', 
          key: 'paySum', 
          name: 'paySum',
        },
        {
          label: 'Zugewiesene Gruppen', 
          key: 'groups', 
          name: 'groups',
        },
      ]), 
    [fields, ownFields]
  );

  const data = useMemo(
    () => persons
      .map(person => {
        const result = {};
        headers.forEach(header => {
          result[header.key] = getFieldValue(person, header)?.replaceAll?.('\r\n', ' ')?.replaceAll?.('\n', ' ');
        });

        return result;
      }), 
    [persons, headers]
  );

  return (
    <CSVLink data={data} headers={headers} separator={";"} filename={`${title}_${(new Date()).toLocaleDateString()}.csv`}>
      {title}
    </CSVLink>
  );
}
