import React, { useState, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import GroupAdd from '@material-ui/icons/GroupAdd';

import { useRoles } from '../hooks/useRoles';
import FormStep from '../../forms/components/FormStep';

export default function RoleManagement() {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { addRole, removeRole, roles } = useRoles();

  const handleEditing = useCallback(() => setEditing(true), [setEditing]);
  const handleStopEditing = useCallback(() => setEditing(false), [setEditing]);
  const handleStopDeleting = useCallback(() => setDeleting(false), [setDeleting]);

  const handleAddRole = useCallback(async (data) => {
    await addRole(data.role);
  }, [addRole]);

  const handleRemoveRole = useCallback(async () => {
    await removeRole(deleting);
    handleStopDeleting();
  }, [removeRole, deleting, handleStopDeleting]);

  return (
    <>
      <Button
        startIcon={<GroupAdd />} 
        onClick={handleEditing} 
        color="primary"
        variant="outlined"
      >
        Rollen editieren
      </Button>
      <Dialog 
        open={editing} 
        onClose={handleStopEditing} 
        aria-labelledby={`edit-roles-dialog`}
        scroll={'paper'}
        maxWidth={'lg'}
      >
        <DialogTitle id={`edit-roles-dialog`}>Rollen</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={10} md={8}>
              <List>
                {
                  roles.map(({ name }) => (
                    <ListItem disableGutters key={name}>
                      <ListItemText primary={name}/>
                      <ListItemIcon>
                        <IconButton onClick={() => setDeleting(name)}>
                          <DeleteOutline/>
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
              <Divider/>
            </Grid>
          </Grid>
          <FormStep
            handleNext={handleAddRole}
            nextText={'Hinzufügen'}
            hideBack
            fields={[
              {
                name: 'role',
                label: 'Rollenname',
                required: true,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStopEditing} color="primary" variant="contained">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={Boolean(deleting)} 
        onClose={handleStopDeleting} 
        aria-labelledby={`delete-role-dialog`}
      >
        <DialogTitle id={`delete-role-dialog`}>Wirklich entfernen?</DialogTitle>
        <DialogActions>
          <Button onClick={handleStopDeleting}>
            Abbrechen
          </Button>
          <Button onClick={handleRemoveRole} color="primary" variant="contained">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
