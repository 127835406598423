import React, { useState, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

const useStyles = makeStyles((theme) => ({
  textButtons: {
    fontSize: theme.spacing(2.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(1.5),
  }
}));

const options = {
  global: {
    keysFunc: list => list.reduce((result, next) => {
      Object.keys(next).forEach(key => {
        if (!result.includes(key)) {
          result.push(key);
        }
      });

      return result;
    }, []),
    threshold: 0,
  },
  adressDistrict: {
    keys: ['adressDistrict'],
    threshold: 0,
  },
  gender: {
    keys: ['gender'],
    threshold: 0,
  },
  selectedSola: {
    keys: ['solaTeilnahme', 'solaMitarbeit'],
    threshold: 0,
  },
};

const getFilteredItems = (searchType, list, searchString, isMitarbeiter) => {
  if (!searchString) {
    return list;
  }

  const usedFilterOptions = options[searchType];

  if (searchType === 'selectedSola') {
    usedFilterOptions.keys = isMitarbeiter ? ['solaMitarbeit'] : ['solaTeilnahme'];
  }

  if (usedFilterOptions.filterFunc) {
    return usedFilterOptions.filterFunc(list, searchString);
  }

  if (usedFilterOptions.keysFunc) {
    usedFilterOptions.keys = usedFilterOptions.keysFunc(list);
  }

  const fuse = new Fuse(list, usedFilterOptions)

  return fuse.search(searchString).map(result => result.item);
};

const doAllFilters = (filters = {}, list, isMitarbeiter = false) => reduce(
  Object.keys(filters), 
  (result, key) => getFilteredItems(key, result, filters[key], isMitarbeiter),
  list
);

const removeNotEnabledFilters = (enabledFilters = [], filters = {}) => {
  const newFilters = cloneDeep(filters);
  const keys = Object.keys(newFilters)

  keys.forEach(key => {
    if (key !== 'global' && !enabledFilters.includes(key) && (newFilters[key] || newFilters[key] === '')) {
      delete newFilters[key];
    }
  });

  return newFilters;
};

export default function FilterBar({ teilis, mitarbeiter, setFilteredTeilis, setFilteredMitarbeiter, enabledFilters = [] }) {
  const classes = useStyles();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const newFilters = removeNotEnabledFilters(enabledFilters, filters);
    if (!isEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  }, [enabledFilters, filters, setFilters]);

  useEffect(() => {
    const filteredTeilis = doAllFilters(filters, teilis, false)
    setFilteredTeilis(filteredTeilis);
  }, [teilis, filters, setFilteredTeilis]);

  useEffect(() => {
    setFilteredMitarbeiter(doAllFilters(filters, mitarbeiter, true));
  }, [mitarbeiter, filters, setFilteredMitarbeiter]);

  const setFilter = useCallback((value, key) => {
    setFilters({ ...filters, [key]: value });
  }, [filters, setFilters]);

  const getSetFilter = key => event => setFilter(event.target.value, key);

  const setGenderFilter = useCallback((gender) => {
    setFilter(filters.gender === gender ? '' : gender, 'gender');
  }, [setFilter, filters]);

  const setSelectedSolaFilter = useCallback((selectedSola) => {
    setFilter(filters.selectedSola === selectedSola ? '' : selectedSola, 'selectedSola');
  }, [setFilter, filters]);

  return (
    <Grid container spacing={3} className={classes.marginBottom}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <TextField
          placeholder="Suchen..."
          label="Alles"
          fullWidth
          onChange={getSetFilter('global')}
          id="filterbar-search-input-global"
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
          }}
        />
      </Grid>
      {
        enabledFilters.includes('adressDistrict') &&
        <Grid item xs={12} sm={6} md={2} lg={1}>
          <TextField
            label="Kennzeichen"
            fullWidth
            onChange={getSetFilter('adressDistrict')}
            id="filterbar-search-input-adressDistrict"
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
            }}
          />
        </Grid>
      }
      {
        enabledFilters.includes('gender') &&
        <Grid item xs={12} sm={3} md={2}>
          <ButtonGroup disableElevation>
            <Button
              onClick={() => setGenderFilter('Weiblich')} 
              className={classes.textButtons}
              color="secondary"
              variant={filters.gender === 'Weiblich' ? 'contained' : 'outlined'}
            >
                &#9792;
            </Button>
            <Button
              onClick={() => setGenderFilter('Männlich')} 
              className={classes.textButtons}
              color="primary"
              variant={filters.gender === 'Männlich' ? 'contained' : 'outlined'}
            >
              &#9794;
            </Button>
          </ButtonGroup>
        </Grid>
      }
      {
        enabledFilters.includes('selectedSola') &&
        <Grid item xs={12} sm={9} md={6} lg={4} xl={3}>
          <ButtonGroup disableElevation color="primary">
            <Button
              onClick={() => setSelectedSolaFilter('Kids')} 
              className={classes.textButtons}
              variant={filters.selectedSola === 'Kids' ? 'contained' : 'outlined'}
            >
              Kids
            </Button>
            <Button
              onClick={() => setSelectedSolaFilter('Teens')} 
              className={classes.textButtons}
              variant={filters.selectedSola === 'Teens' ? 'contained' : 'outlined'}
            >
              Teens
            </Button>
            <Button
              onClick={() => setSelectedSolaFilter('Aufbau')} 
              className={classes.textButtons}
              variant={filters.selectedSola === 'Aufbau' ? 'contained' : 'outlined'}
            >
              Aufbau
            </Button>
          </ButtonGroup>
        </Grid>
      }
    </Grid>
  );
}
