import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { Controller } from "react-hook-form";

const ControlledSelect = ({ 
  name, 
  defaultValue, 
  label, 
  required, 
  error,
  options,
  control,
  preLabel,
  postLabel,
}) => (
  <>
    { preLabel && <p>{preLabel}</p> }
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{ 
        validate: (value) => !required || (Boolean(value) && value.length > 0),
      }}
      render={(properties) => (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel id={`${name}-label`}>
            {`${label}${required ? ' *' : ''}`}
          </InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            label={`${label}${required ? ' *' : ''}`}
            {...properties}
            MenuProps={{
                getContentAnchorEl: () => null,
            }}
            renderValue={name => name}
            error={error}
          >
            {
              options.map(option => (
                <MenuItem value={option.value ? option.value : option} key={option.value ? option.value : option} style={{ whiteSpace: 'break-spaces' }}>
                  <Checkbox checked={Boolean(properties.value && properties.value.includes(option.value ? option.value : option))} />
                  <ListItemText primary={option.label ? option.label : option} />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )}
    />
    { 
      postLabel && 
      typeof postLabel === 'string' && 
      <Typography variant="body1">{postLabel}</Typography> 
    }
    { 
      postLabel && 
      typeof postLabel !== 'string' && 
      postLabel.map((value, index) => <Typography key={index} variant="body1">{value}</Typography>)
    }
  </>
);

export default ControlledSelect;
