import React, { useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Description from '@material-ui/icons/Description';
import GetApp from '@material-ui/icons/GetApp';
import Print from '@material-ui/icons/Print';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import EmailButton from '../EmailButton';
import { downloadPdf, printPDF, getAsDefinition } from '../../../utils/pdfMake';
import getConfig from './config';
import { useConfig } from '../../../hooks/useConfig';
import getBackend from '../../../hooks/getBackend';
import { useSecret } from '../../../hooks/useSecret';

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export default function MitarbeiterBestätigungFührungszeugnisButton(props) {
  const { onClick } = props;
  const { 
      config: { 
          kidsTerminEnd,
          teensTerminStart,
      }, 
  } = useConfig();

  const { secret } = useSecret();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const [unterschrift, setUnterschrift] = useState('');

  useEffect(
    () => {
      const getUnterschrift = async () => {
          try {
              const response = await fetch(
                  `${getBackend()}/admin/getUnterschrift.php`,
                  { 
                      method: 'POST', 
                      headers: {
                          'Content-Type': 'image/jpeg'
                      },
                      body: JSON.stringify({
                          secret,
                      })
                  }
              );

              setUnterschrift(await blobToBase64(await response.blob()));
          } catch(error) {
              console.log(error);
              setUnterschrift('');
          }
      };

      getUnterschrift();
    },
    [secret],
  );

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        startIcon={<Description/>}
        onClick={handleOpen}
      >
        {'Führungszeugnis-Bestätigung'}
      </Button>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Führungszeugnis-Bestätigung</DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={3} justify="center">
                <Grid item>
                    <IconButton 
                        onClick={
                            () => downloadPdf(
                                getAsDefinition(
                                    { 
                                      ...getConfig({...props, kidsTerminEnd, teensTerminStart}),
                                      images: { unterschrift },
                                    }
                                )
                            )
                        }
                        color="primary" 
                        aria-label="download pdf" 
                        component="span"
                    >
                        <GetApp />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton 
                        onClick={
                            () => printPDF(
                                getAsDefinition(
                                    { 
                                      ...getConfig({...props, kidsTerminEnd, teensTerminStart}),
                                      images: { unterschrift },
                                    }
                                )
                            )
                        }
                        color="primary" 
                        aria-label="print pdf" 
                        component="span"
                    >
                        <Print />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <EmailButton
                      onClick={onClick}
                      email={props.email}
                      subject={'Führungszeugnis Bestätigung der Befreiung SOLA Zieko'}
                      message={
                        `Hallo ${props.prename},\n`
                        + 'für das SOLA Zieko brauchen wir von dir ein polizeiliches Führungszeugnis. Anbei erhälst du unsere Bestätigung, die du zur Kostenbefreiung zum Antrag einreichen kannst.'
                        + '\n\nViele Grüße\n'
                        + 'SOLA-Orgateam'
                      }
                      label={'Email öffnen'}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
