import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardWithDialog from './CardWithDialog';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function MediaCard() {
  const classes = useStyles();

  return (
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <CardWithDialog
            md={6}
            title={'Mitarbeiten'}
            description={'Wir freuen uns immer über neue Gesichter und frischen Wind. Als Mitarbeiter bewerben kann man sich jederzeit über das Anmeldeformular.'}
            image={'./img/Mitarbeiter.JPG'}
            actionProps={{ href: '#mitarbeiter' }}
          />
          <CardWithDialog
            md={6}
            title={'Spendenkonto'}
            description={
              <table width="100%">
                <tbody>
                  <tr>
                    <td className="p-2">Name:</td>
                    <td className="p-2">Hoffnungsgemeinde Zieko - SOLA</td>
                  </tr>
                  <tr>
                    <td className="p-2">IBAN:</td>
                    <td className="p-2">DE11 3506 0190 1565 8020 34</td>
                  </tr>
                  <tr>
                    <td className="p-2">BIC:</td>
                    <td className="p-2">GENODED1DKD</td>
                  </tr>
                  <tr>
                    <td className="p-2">Institut:</td>
                    <td className="p-2">KD-Bank Dortmund</td>
                  </tr>
                </tbody>
              </table>
            }
            image={'./img/Spenden.jpg'}
            actionProps={{ href: '#kontakt' }}
          />
        </Grid>
      </Container>
    
  );
}