import React from 'react';
import Button from '@material-ui/core/Button';
import Send from '@material-ui/icons/Send';

const generateEmailUrl = (props) => `mailto:${props.email}?subject=${encodeURIComponent(props.subject)}&body=${encodeURIComponent(props.message)}`;

export default function EmailButton(props = {}) {
  return (
    <Button
        variant="contained"
        color="primary"
        startIcon={<Send/>}
        onClick={props.onClick}
        href={generateEmailUrl(props)}
        target="_blank"
    >
        {props.label}
    </Button>
  );
}
