import React, { useCallback } from "react";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import InputAdornment from '@material-ui/core/InputAdornment';

import ControlledTextField from '../../forms/components/fields/ControlledTextField';
import { useAllPersons } from '../hooks/useAllPersons'

export default function FormStep({
  hasToPay,
  id
}) {
  const { control, handleSubmit, errors } = useForm({ defaultValues: { hasToPay } });
  const { setPersonHasToPay } = useAllPersons();

  const onSubmit = useCallback((data) => {
    setPersonHasToPay(id, data.hasToPay);
  }, [id, setPersonHasToPay]);

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={2}>
          <Grid
            item 
            xs={12} 
            sm={6} 
          >
            <ControlledTextField
              name={'hasToPay'}
              control={control}
              required
              type={'number'}
              label={'Muss bezahlen'}
              error={Boolean(errors['hasToPay'])}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    &euro;
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item 
            xs={12} 
            sm={6} 
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {'Speichern'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
