import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Slide from '@material-ui/core/Slide';
import TeilnehmerBedingungen from './bedingungen/teilnehmer';
import MitarbeiterBedingungen from './bedingungen/mitarbeiter';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'fixed',
    bottom: theme.spacing(1),
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenReminder(props) {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        {props.isMitarbeiter ? <MitarbeiterBedingungen/> : <TeilnehmerBedingungen/>}
        <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={props.onClose}
            startIcon={<CheckCircle />}
        >
            Akzeptieren
        </Button>
    </Dialog>
  );
}
