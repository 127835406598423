import React, { useCallback } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { DatePicker } from '@material-ui/pickers';

import { useConfig } from '../../hooks/useConfig';

export default function AnmeldungConfig() {
    const { 
        config: { 
            teiliRegistrationEnabled,
            mitarbeiterRegistrationEnabled,
            solaTitle,
            solaPreviewEnabled,
            solaThemeTitle,
            solaThemeRegistration,
            kidsTerminStart,
            kidsTerminEnd,
            kidsPrice,
            teensTerminStart,
            teensTerminEnd,
            teensPrice,
            solaEndBewerbungTermin,
            solaAufbauTermin,
            grossZeltTermin,
            lagerPlatzTermin,
            abbauTermin,
        }, 
        setBackendConfig 
    } = useConfig();

    const onTeiliEnableChange = useCallback(
        () => setBackendConfig('teiliRegistrationEnabled', !Boolean(teiliRegistrationEnabled)),
        [setBackendConfig, teiliRegistrationEnabled]
    );

    const onMitarbeiterEnableChange = useCallback(
        () => setBackendConfig('mitarbeiterRegistrationEnabled', !Boolean(mitarbeiterRegistrationEnabled)),
        [setBackendConfig, mitarbeiterRegistrationEnabled]
    );

    const onPreviewEnableChange = useCallback(
        () => setBackendConfig('solaPreviewEnabled', !Boolean(solaPreviewEnabled)),
        [setBackendConfig, solaPreviewEnabled]
    );

    const onSOLATitleChange = useCallback(
        (event) => setBackendConfig('solaTitle', event.target.value),
        [setBackendConfig]
    );

    const onSOLAThemeTitleChange = useCallback(
        (event) => setBackendConfig('solaThemeTitle', event.target.value),
        [setBackendConfig]
    );

    const onSOLAThemeRegistrationChange = useCallback(
        (event) => setBackendConfig('solaThemeRegistration', event.target.value),
        [setBackendConfig]
    );

    const onKidsStartTerminChange = useCallback(
        (value) => setBackendConfig('kidsTerminStart', value),
        [setBackendConfig]
    );

    const onKidsEndTerminChange = useCallback(
        (value) => setBackendConfig('kidsTerminEnd', value),
        [setBackendConfig]
    );

    const onKidsPriceChange = useCallback(
        (event) => setBackendConfig('kidsPrice', event.target.value),
        [setBackendConfig]
    );

    const onTeensStartTerminChange = useCallback(
        (value) => setBackendConfig('teensTerminStart', value),
        [setBackendConfig]
    );

    const onTeensEndTerminChange = useCallback(
        (value) => setBackendConfig('teensTerminEnd', value),
        [setBackendConfig]
    );

    const onTeensPriceChange = useCallback(
        (event) => setBackendConfig('teensPrice', event.target.value),
        [setBackendConfig]
    );

    const onSolaEndBewerbungTerminChange = useCallback(
        (event) => setBackendConfig('solaEndBewerbungTermin', event.target.value),
        [setBackendConfig]
    );

    const onSolaAufbauTerminChange = useCallback(
        (event) => setBackendConfig('solaAufbauTermin', event.target.value),
        [setBackendConfig]
    );

    const onAufbauZeltTerminChange = useCallback(
        (event) => setBackendConfig('grossZeltTermin', event.target.value),
        [setBackendConfig]
    );

    const onAufbauLagerTerminChange = useCallback(
        (event) => setBackendConfig('lagerPlatzTermin', event.target.value),
        [setBackendConfig]
    );

    const onAbbauTerminChange = useCallback(
        (event) => setBackendConfig('abbauTermin', event.target.value),
        [setBackendConfig]
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <h2>SOLA Anmeldung</h2>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={Boolean(teiliRegistrationEnabled)} onChange={onTeiliEnableChange} name="teiliRegistrationEnabled" />}
                    label="Anmeldung Teilmehmer aktiviert"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={Boolean(mitarbeiterRegistrationEnabled)} onChange={onMitarbeiterEnableChange} name="mitarbeiterRegistrationEnabled" />}
                    label="Anmeldung Mitarbieter aktiviert"
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ margin: '40px', marginLeft: '40%', marginRight: '40%' }} />
            </Grid>
            <Grid item xs={12}>
                <h2>SOLA Termine</h2>
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <DatePicker
                    label={'Teens Sola Start'}
                    emptyLabel="Bitte auswählen"
                    inputVariant="outlined"
                    openTo="year"
                    format="dd.MM.yyyy"
                    fullWidth
                    disablePast
                    cancelLabel="Abbrechen"
                    okLabel="Bestätigen"
                    value={teensTerminStart || null}
                    onChange={onTeensStartTerminChange}
                    ref={undefined}
                />
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <DatePicker
                    label={'Teens Sola Ende'}
                    emptyLabel="Bitte auswählen"
                    inputVariant="outlined"
                    openTo="year"
                    format="dd.MM.yyyy"
                    fullWidth
                    disablePast
                    cancelLabel="Abbrechen"
                    okLabel="Bestätigen"
                    value={teensTerminEnd || null}
                    onChange={onTeensEndTerminChange}
                    ref={undefined}
                />
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <TextField
                    label={'Teens Sola Preis'}
                    defaultValue={teensPrice}
                    fullWidth
                    onBlur={onTeensPriceChange}
                />
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <DatePicker
                    label={'Kids Sola Start'}
                    emptyLabel="Bitte auswählen"
                    inputVariant="outlined"
                    openTo="year"
                    format="dd.MM.yyyy"
                    fullWidth
                    disablePast
                    cancelLabel="Abbrechen"
                    okLabel="Bestätigen"
                    value={kidsTerminStart || null}
                    onChange={onKidsStartTerminChange}
                    ref={undefined}
                />
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <DatePicker
                    label={'Kids Sola Ende'}
                    emptyLabel="Bitte auswählen"
                    inputVariant="outlined"
                    openTo="year"
                    format="dd.MM.yyyy"
                    fullWidth
                    disablePast
                    cancelLabel="Abbrechen"
                    okLabel="Bestätigen"
                    value={kidsTerminEnd || null}
                    onChange={onKidsEndTerminChange}
                    ref={undefined}
                />
            </Grid>
            <Grid item sm={4} xs={12} style={{ padding: '8px' }}>
                <TextField
                    label={'Kids Sola Preis'}
                    defaultValue={kidsPrice}
                    fullWidth
                    onBlur={onKidsPriceChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={solaEndBewerbungTermin}
                    label="Ende Bewerbung (15 Euro mehr nach)"
                    fullWidth
                    onBlur={onSolaEndBewerbungTerminChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ margin: '40px', marginLeft: '40%', marginRight: '40%' }} />
            </Grid>
            <Grid item xs={12}>
                <h2>SOLA Aufbau / Abbau</h2>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={solaAufbauTermin}
                    label="Aufbau"
                    fullWidth
                    onBlur={onSolaAufbauTerminChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={grossZeltTermin}
                    label="Großzeltaufbau und Materialtransport"
                    fullWidth
                    onBlur={onAufbauZeltTerminChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={lagerPlatzTermin}
                    label="Aufbau des Lagerplatzes"
                    fullWidth
                    onBlur={onAufbauLagerTerminChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={abbauTermin}
                    label="Abbautag"
                    fullWidth
                    onBlur={onAbbauTerminChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ margin: '40px', marginLeft: '40%', marginRight: '40%' }} />
            </Grid>
            <Grid item xs={12}>
                <h2>SOLA Beschreibung</h2>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={Boolean(solaPreviewEnabled)} onChange={onPreviewEnableChange} name="solaPreviewEnabled" />}
                    label="SOLA Beschreibung aktiviert"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={solaTitle}
                    label="SOLA-Titel"
                    fullWidth
                    onBlur={onSOLATitleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={solaThemeTitle}
                    label="Thema-Titel"
                    fullWidth
                    onBlur={onSOLAThemeTitleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={solaThemeRegistration}
                    label="Thematische Einladung zur Anmeldung"
                    fullWidth
                    onBlur={onSOLAThemeRegistrationChange}
                />
            </Grid>
        </Grid>
    );
}
