import React, { useState, useCallback, useMemo } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GroupContainer from './GroupContainer';
import PickShownField from './PickShownField';
import EditSharedItem from './sharedItems/EditSharedItem';
import GroupsProvider from '../../hooks/useGroups';

export default function Groups({ bestätigtTeilis, bestätigtMitarbeiter }) {
  const [expanded, setExpanded] = useState('teens');
  const [editingSharedItem, setEditingSharedItem] = useState(false);

  const handleChange = useCallback(
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }, 
    [setExpanded]
  );

  const allPersons = useMemo(
    () => [...new Set([...bestätigtTeilis, ...bestätigtMitarbeiter])], 
    [bestätigtTeilis, bestätigtMitarbeiter]
  );

  return (
    <GroupsProvider editingSharedItem={editingSharedItem} setEditingSharedItem={setEditingSharedItem}>
      {
        editingSharedItem
        ? <EditSharedItem />
        : <>
          <PickShownField/>
          <Accordion expanded={expanded === 'teens'} onChange={handleChange('teens')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Teens</AccordionSummary>
            <AccordionDetails>
              <GroupContainer groupLabel="Teens" allPersons={allPersons}/>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'kids'} onChange={handleChange('kids')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Kids</AccordionSummary>
            <AccordionDetails>
              <GroupContainer groupLabel="Kids" allPersons={allPersons}/>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'aufbau'} onChange={handleChange('aufbau')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Aufbau</AccordionSummary>
            <AccordionDetails>
              <GroupContainer groupLabel="Aufbau" allPersons={allPersons}/>
            </AccordionDetails>
          </Accordion>
        </>
      }
    </GroupsProvider>
  );
}
