import React from 'react';
import { format } from 'date-fns';
import CompleteConfig from '../forms/configs/complete.json';
import TeilnehmerConfig from '../forms/configs/teilnehmer.json';
import MitarbeiterConfig from '../forms/configs/mitarbeiter.json';

export const completeFields = [];
CompleteConfig.formSteps.forEach(formStep => formStep.items.forEach(item => completeFields.push(item)));

export const teilnehmerFields = [];
TeilnehmerConfig.formSteps.forEach(formStep => formStep.items.forEach(item => teilnehmerFields.push(item)));

export const mitarbeiterFields = [];
MitarbeiterConfig.formSteps.forEach(formStep => formStep.items.forEach(item => mitarbeiterFields.push(item)));

export const getFieldValue = (person, field) => {
  if (!person[field.name] && person[field.name] !== false) {
    return null;
  }

  if (field.presentation === 'DatePicker') {
    return (new Date(person[field.name])).toLocaleDateString();
  }

  if (field.presentation === 'Checkbox') {
    return (person[field.name] === true || person[field.name] === 'true') ? 'Ja' : 'Nein';
  }

  if (field.formatFunc === 'date') {
    return person[field.name] && person[field.name] !== '0000-00-00 00:00:00' && format(new Date(person[field.name]), 'dd.MM.yyy');
  }

  if (field.presentation === 'MultiSelect' || Array.isArray(person[field.name])) {
    return person[field.name].join(', ');
  }

  return person[field.name] || '';
};

export const getLabeldFields = (person, skipNames = [], usedFields = completeFields) => 
  usedFields
    .filter(field => !skipNames.includes(field.name))
    .map(field => ({ label: field.label, value: getFieldValue(person, field) }));

export const getFieldsForEmail = (person, skipNames = [], usedFields) => 
  <table>
    {
      getLabeldFields(person, skipNames, usedFields)
        .filter(field => field.value !== null && field.value !== '' && field.value !== undefined)
        .map(field => <tr><td>{field.label}:</td> <td>{field.value}</td></tr>)
    }
  </table>;
