import React, { PureComponent } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import Delete from '@material-ui/icons/Delete';
import MenuBook from '@material-ui/icons/MenuBook';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
import Fastfood from '@material-ui/icons/Fastfood';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import SportsHandball from '@material-ui/icons/SportsHandball';
import Group from '@material-ui/icons/Group';
import Hotel from '@material-ui/icons/Hotel';

export default class DailyRoutine extends PureComponent {
  render() {
    return (
      <List style={{ width: '100%' }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccessAlarm />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Wecken" secondary="8:30 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Fastfood />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Frühstück" secondary="9:00 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Delete />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Ämter - Waschräume säubern, Müll u. Holz sammeln..." secondary="9:30 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <LocalLibrary />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Stille Zeit - gemeinsames Bibellesen in Kleingruppen" secondary="10:00 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmojiPeople />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Vormittagsprogramm" secondary="10:55 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Fastfood />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Mittagessen" secondary="13:00 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PauseCircleOutline />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Pause" secondary="13:45 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <SportsHandball />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Nachmittagsprogramm" secondary="15:00 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MenuBook />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="freiwillige Gebetszeit" secondary="17:15 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Fastfood />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Abendessen" secondary="18:00 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Group />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Abendprogramm" secondary="19:30 Uhr" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <Hotel />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Nachtruhe" secondary="22:00 Uhr (23:00 bei den Teens)" />
        </ListItem>
      </List>
    );
  };
}