const fieldText = '____________________________';
const halfText = '_____________';
const fullPageText = fieldText + fieldText + '___';

const getConfig = data => [
    {
        alignment: 'justify',
        columns: [
            {
                columns: [[
                    {
                        text: 'Teilnehmer',
                        style: 'fatLabel',
                    },
                    {
                        text: 'Familienname',
                        style: 'label',
                    },
                    {
                        text: data.lastname || fieldText,
                        style: data.lastname ? 'dataText' : 'errorText',
                    },
                    {
                        text: 'Vorname',
                        style: 'label',
                    },
                    {
                        text: data.prename || fieldText,
                        style: data.prename ? 'dataText' : 'errorText',
                    },
                    {
                        text: 'Anschrift',
                        style: 'label',
                    },
                    {
                        text: data.adressStreet || fieldText,
                        style: data.adressStreet ? 'dataText' : 'errorText',
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {
                                width: '50%',
                                text: [
                                    {
                                        text: 'PLZ',
                                        style: 'label',
                                    },
                                    '\n',
                                    {
                                        text: data.adressPlz || '______',
                                        style: data.adressPlz ? 'dataText' : 'errorText',
                                    },
                                ]
                            },
                            {
                                width: '50%',
                                text: [
                                    {
                                        text: 'Wohnort, (Land)',
                                        style: 'label',
                                    },
                                    '\n',
                                    {
                                        text: data.adressTown || '___________________',
                                        style: data.adressTown ? 'dataText' : 'errorText',
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {
                                width: '50%',
                                text: [
                                    {
                                        text: 'Geburtsdatum',
                                        style: 'label',
                                    },
                                    '\n',
                                    {
                                        text: data.birthdate ? (new Date(data.birthdate)).toLocaleDateString() : halfText,
                                        style: data.birthdate ? 'dataText' : 'errorText',
                                    },
                                ]
                            },
                            {
                                width: '50%',
                                text: [
                                    {
                                        text: 'Geschlecht',
                                        style: 'label',
                                    },
                                    '\n',
                                    {
                                        text: data.gender || halfText,
                                        style: data.gender ? 'dataText' : 'errorText',
                                    },
                                ]
                            },
                        ]
                    },
                ]],
                width: '50%',
            },
            [
                {
                    width: '50%',
                    alignment: 'justify',
                    columns: [
                        {
                            image: 'logo',
                            width: 75,
                        },
                        [
                            {
                                text: 'Name der Veranstaltung',
                                style: 'fatLabel',
                            },
                            '{solaTitle}',
                            data.solaTeilnahme || 'Teens',
                            '\n',
                            {
                                text: 'Termin',
                                style: 'fatLabel',
                            },
                            data.solaTeilnahme === 'Kids' ? '{solaKidsTermin}' : '{solaTeensTermin}',
                        ],
                    ],
                },
                '\n',
                {
                    text: 
                        'Liebe Eltern, liebe Sorgeberechtigten,\n\n' +
                        'die Verantwortlichen des SOLA wollen Ihrem Kind eine unbeschwerte und erlebnisreiche Ferienfreizeit bieten. Die Aufsicht über Ihr Kind wollen wir inhaltlich angemessen, seinen sowie den Interessen der anderen Teilnehmer entsprechend, wahrnehmen. Hierzu benötigen wir einige wichtige Informationen zu evtl. persönlichen Besonderheiten Ihres Kindes, die für eine individuelle Aufsichtsführung unerlässlich sind. Wir versichern einen vertraulichen Umgang mit diesen Informationen und geben sie nur soweit wie nötig an verantwortliche Gruppenmitarbeiter weiter.\n' +
                        'Bitte füllen Sie diesen FREIZEITPASS ehrlich und vollständig aus und senden ihn unterschrieben an uns zurück. Bei Bedarf ergänzen Sie weitere Hinweise bitte auf einem Beiblatt.\n' +
                        '\nIhr SOLA-Leitungsteam',
                    style: 'small',
                },
            ],
        ],
    },
    '\n',
    {
        text: 'Erreichbarkeit des/der Sorgeberechtigten während der Veranstaltung',
        style: 'fatLabel',
    },
    {
        alignment: 'justify',
        columns: [
            {
                width: '50%',
                text: [
                    {
                        text: 'Nachname',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentLastname || data.lastname || fieldText,
                        style: data.parentLastname || data.lastname ? 'dataText' : 'errorText',
                    },
                    '\n',
                    {
                        text: 'Vorname',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentPrename || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                    {
                        text: 'Straße / Hausnummer (falls abweichend vom Teilnehmer)',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentAdress || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                    {
                        text: 'PLZ / Ort (falls abweichend vom Teilnehmer)',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentTown || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                    {
                        text: 'E-Mail-Adresse',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentEmail || fieldText,
                        style: data.parentEmail ? 'dataText' : 'errorText',
                    },
                ]
            },
            {
                width: '50%',
                text: [
                    {
                        text: 'Telefonnummer',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentTelefon || fieldText,
                        style: data.parentTelefon ? 'dataText' : 'errorText',
                    },
                    '\n',
                    {
                        text: '(Mobil) Telefonnummer 1',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentHandy || fieldText,
                        style: data.parentHandy ? 'dataText' : 'errorText',
                    },
                    '\n',
                    {
                        text: 'Telefonnummer 2',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentHandy2 || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                    {
                        text: 'Name(n) Notfallkontakt Verwandte / Freunde',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentFriendNames || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                    {
                        text: '(Mobil) Telefonnummer Notfallkontakt Verwandte / Freunde',
                        style: 'label',
                    },
                    '\n',
                    {
                        text: data.parentHandyFriends || fieldText,
                        style: 'dataText',
                    },
                    '\n',
                ]
            },
        ]
    },
    {
        text: 'Gesundheitsfürsorge',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        alignment: 'justify',
        columns: [
            {
                width: '50%',
                columns: [[
                    {
                        text: 'Krankenversichert bei folgender Krankenkasse',
                        style: 'label',
                    },
                    {
                        text: data.healthInsurance || fieldText,
                        style: 'dataText',
                    },
                    {
                        text: 'Name der Krankenkasse',
                        style: 'label',
                    },
                    {
                        text: data.healthInsuranceName || fieldText,
                        style: data.healthInsuranceName ? 'dataText' : 'errorText',
                    },
                ]]
            },
            {
                width: '50%',
                columns: [[
                    {
                        text: 'Versicherungsnummer',
                        style: 'label',
                    },
                    {
                        text: data.healthInsuranceNumber || fieldText,
                        style: data.healthInsuranceNumber ? 'dataText' : 'errorText',
                    },
                    {
                        text: 'Name der Person, über die der Teilnehmer versichert ist',
                        style: 'label',
                        margin: [0,6,0,2],
                    },
                    {
                        text: data.healthInsuranceRefererName || fieldText,
                        style: data.healthInsuranceRefererName ? 'dataText' : 'errorText',
                    },
                ]]
            },
        ]
    },
    {
        text: 'Kontaktdaten der Hausärztin oder des Hausarztes',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        alignment: 'justify',
        columns: [
            {
                width: '50%',
                columns: [[
                    {
                        text: 'Name',
                        style: 'label',
                    },
                    {
                        text: data.doctorName || fieldText,
                        style: data.doctorName ? 'dataText' : 'errorText',
                    },
                    {
                        text: [
                            {
                                text: 'Teilnahme am Hausarztmodell',
                                style: 'label',
                            },
                            '\n',
                        ]
                    },
                    {
                        text: data.doctorModel || fieldText,
                        style: data.doctorModel ? 'dataText' : 'errorText',
                    },
                ]]
            },
            {
                width: '50%',
                columns: [[
                    {
                        text: 'Anschrift',
                        style: 'label',
                    },
                    {
                        text: data.doctorAdress || fieldText,
                        style: data.doctorAdress ? 'dataText' : 'errorText',
                    },
                    {
                        text: 'Telefonnummer',
                        style: 'label',
                    },
                    {
                        text: data.doctorTelefon || fieldText,
                        style: data.doctorTelefon ? 'dataText' : 'errorText',
                    },
                ]]
            },
        ]
    },
    {
        text: 'Impfungen',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        alignment: 'justify',
        columns: [
            {
                width: '53%',
                columns: [
                    {
                        width: '68%',
                        columns: [[
                            {
                                text: 'Geimpft gegen Tetanus (Wundstarrkrampf)',
                                style: 'label',
                            },
                            {
                                text: data.tetanus ? 'Ja' : 'Nein',
                                style: 'dataText',
                            },
                        ]]
                    },
                    [
                        {
                            text: 'Letztes Datum',
                            style: 'label',
                        },
                        {
                            text: data.tetanusDate ? (new Date(data.tetanusDate)).toLocaleDateString() : '___________',
                            style: 'dataText',
                        },
                    ],
                ]
            },
            {
                width: '47%',
                columns: [
                    {
                        width: '60%',
                        columns: [[
                            {
                                text: 'Geimpft gegen FSME (Zeckenbiss)',
                                style: 'label',
                            },
                            {
                                text: data.fsme ? 'Ja' : 'Nein',
                                style: 'dataText',
                            },
                        ]]
                    },
                    [
                        {
                            text: 'Letztes Datum',
                            style: 'label',
                        },
                        {
                            text: data.fsmeDate ? (new Date(data.fsmeDate)).toLocaleDateString() : '___________',
                            style: 'dataText',
                        },
                    ],
                ]
            },
        ]
    },
    {
        text: 'Worauf muss besonders geachtet werden?',
        style: 'fatLabel',
        margin: [0,8,0,2],
    },
    {
        text: '(z. B. Allergien, Behinderungen, Herzfehler, Hitzeempfindlichkeit, Bettnässen, Medikamentenunverträglichkeit, Abhängigkeiten von Medikamenten'
            + ' und Rauschmitteln, ADS/ADHS, Zahnspange usw. - ggf. gesonderte Mitteilung beifügen). Ggf. können ärztliche Atteste beigefügt werden',
        style: 'label',
    },
    {
        text: data.deseases || fullPageText,
        style: data.deseases ? 'dataText' : 'fieldText',
        margin: [0,8,0,2],
    },
    {
        text: 'Folgende Atteste sind beigelegt',
        style: 'label',
        margin: [0,8,0,2],
    },
    {
        text: fullPageText,
        style: 'fieldText',
    },
    {
        text: 'Weitere Angaben für ärztliche Hilfe, Anwendung von Hausmitteln, Erlaubnis Zeckenentfernung',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 
            'Falls bei Ihrem Kind mit dem Auftreten von bestimmten Krankheiten, Schmerzen oder Empfindlichkeiten (z.Bsp. Übelkeit, Kopfschmerzen, ' +
            'Regelschmerzen, Durchfall, Magenprobleme etc.) zu rechnen ist, informieren Sie uns bitte, wie wir darauf nicht-medikamentös reagieren können. ' +
            'Geben Sie Ihrem Kind bitte ggf. Medikamente hierfür mit und weisen Sie es genau an, wann und wie diese anzuwenden sind. Führen Sie dies bitte im ' +
            'Pass nachstehend auf.',
        style: 'small',
        margin: [0,8,0,2],
    },
    {
        text: 'Unser Kind muss aufgrund unserer eigenen oder einer ärztlichen Verordnung folgende Medikamente zu sich nehmen:',
        style: 'small',
        margin: [0,8,0,2],
    },
    {
        text: data.medicaments || '-',
        style: 'dataText',
        margin: [0,0,0,2],
    },
    data.medicamentsCoolant ? {
        text: 'Folgende Medikamente müssen gekühlt aufbewahrt werden:',
        style: 'label',
        margin: [0,6,0,2],
    } : '',
    data.medicamentsCoolant ? {
        text: data.medicamentsCoolant,
        style: 'dataText',
        margin: [0,0,0,2],
    } : '',
    data.medicamentsHints ? {
        text: 'Weitere Hinweise zur Medikamenteneinnahme',
        style: 'label',
        margin: [0,2,0,2],
    } : '',
    data.medicamentsHints && data.medicamentsHints.length ? {
        text: data.medicamentsHints.join('\n\n'),
        style: 'smallDataText',
        margin: [0,4,0,6],
    } : '',
    {
        text: 
            'Den Mitarbeitenden des SOLA ist es ohne ausdrückliche Einwilligung der Sorgeberechtigten nicht gestattet, eigene über die Erste Hilfe hinausgehende Maßnahmen zu ergreifen oder Medikamente zu verabreichen. Bei kleineren ' +
            'Bei Verletzungen verhindert rasches Eingreifen meist eine Verschlimmerung  der Verletzung/ Erkrankung. Ein Arzt- oder Krankenhausbesuch kann so oft vermieden werden. Ohne Ihre Zustimmung müssten wir bei jeder kleinsten ' +
            'Verletzung (z.B. Wespenstich) mit Ihrem Kind  zum Arzt fahren. Das tun wir in Zweifelsfällen immer, bitten Sie für die eindeutig "leichten" Fälle jedoch nachfolgend um Ihr Einverständnis.',
        style: 'small',
        margin: [0,4,0,6],
    },
    data.medicamentsHintsExtended && data.medicamentsHintsExtended.length ? {
        text: 'Wir gestatten den Verantwortlichen des SOLA im Bedarfsfall bei unserem Kind:',
        style: 'small',
        margin: [0,4,0,2],
    } : '',
    data.medicamentsHintsExtended && data.medicamentsHintsExtended.length ? {
        text: data.medicamentsHintsExtended.map(text => '- ' + text).join('\n'),
        style: 'smallDataText',
        margin: [0,2,0,2],
    } : '',
    data.medicamentsHintsBemerkungen ? {
        text: 'Ergänzende Hinweise',
        style: 'label',
        margin: [0,2,0,0],
    } : '',
    data.medicamentsHintsBemerkungen ? {
        text: data.medicamentsHintsBemerkungen,
        style: 'dataText',
        margin: [0,2,0,2],
    } : '',
    {
        text: 'Dürfen wir bei Ihrem Kind Zecken entfernen und die Einstichstelle ggf. zu desinfizieren? Wir geben Ihrem Kind eine Information darüber mit.',
        style: 'label',
        margin: [0,2,0,0],
    },
    {
        text: data.tickRemoval,
        style: 'dataText',
        margin: [0,2,0,2],
    },
    data.doctorActivities ? {
        text: 'Arzt- und Krankenhausbesuch',
        style: 'fatLabel',
        margin: [0,12,0,2],
    }: '',
    data.doctorActivities ? {
        text: 
            'Wir sind damit einverstanden, dass vom Arzt ggf. für dringend erachtete Schutzimpfungen (z.B. Tetanus) ' +
            'sowie sonstige ärztliche Maßnahmen veranlasst werden können, wenn unser Einverständnis aufgrund besonderer ' +
            'Umstände nicht mehr rechtzeitig vor der Maßnahme eingeholt werden kann. ' +
            'Sollte ein stationärer Aufenthalt nötig sein, sind wir damit einverstanden, dass unser Kind nach Beendigung ' +
            'der Behandlung nach Vorlage dieses Freizeitpasses von einem Mitarbeitenden des Sommerlagers abgeholt werden darf.',
        style: 'small',
        margin: [0,8,0,2],
    } : '',
    data.doctorActivitiesBemerkungen ? {
        text: 'Ergänzende Hinweise',
        style: 'label',
        margin: [0,8,0,2],
    } : '',
    data.doctorActivitiesBemerkungen ? {
        text: data.doctorActivitiesBemerkungen,
        style: 'dataText',
    } : '',
    {
        text: 'Private Haftpflichtversicherung',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 'Besteht eine private Haftpflichtversicherung?',
        style: 'label',
        margin: [0,8,0,2],
    },
    {
        text: data.haftpflichtversicherung ? 'Ja' : 'Nein',
        style: 'dataText',
    },
    {
        pageBreak: 'before',
        text: 'Einverständnis zu Aktivitäten beim SOLA Zieko',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 
            'Uns ist bekannt, dass zum SOLA-Programm auch die Teilnahme an nicht alltäglichen Aktivitäten wie z.B. Wanderungen, schwimmen in natürlichen ' +
            'Gewässern, (Nacht)geländespiele, der Umgang mit Werkzeugen sowie kurze Zeiten ohne unmittelbare Beaufsichtigung der Teilnehmer durch ' +
            'Aufsichtspersonen gehören können. Uns ist insbesondere bewusst, dass unser Kind bei solchen Gelegenheiten neue, für die Entwicklung seiner ' +
            'Persönlichkeit und das Erkennen, Ausschöpfen und Erweitern seiner Fähigkeiten wertvolle Erfahrungen machen kann. Dabei kann es sich ggf. ' +
            'unbekannten und im Einzelfall auch riskanten Aufgaben aussetzen. Auch bei größter Sorgfalt durch die Betreuer kann nie ausgeschlossen werden, ' +
            'dass hierbei Verletzungen und/ oder andere Schäden entstehen.',
        style: 'small',
        margin: [0,6,0,2],
    },
    {
        text: 
            'Unser Kind darf an allen Aktivitäten und Programmangeboten des SOLA (siehe auch Reisebedingungen Punkt 4) teilnehmen. Dazu gehört nach ' +
            'Einweisung durch die SOLA-Mitarbeiter auch der Umgang mit Hammer, Beil, Säge, Taschenmesser und anderen Werkzeugen',
        style: 'small',
        margin: [0,6,0,2],
    },
    {
        text: 'Das Kind darf an allen Aktivitäten des SOLA teilnehmen',
        style: 'label',
        margin: [0,8,0,2],
    },
    {
        text: data.activities,
        style: 'dataText',
    },
    data.activitiesHints ? {
        text: 'Einschränkungen / Hinweise',
        style: 'label',
        margin: [0,8,0,2],
    } : '',
    data.activitiesHints ? {
        text: data.activitiesHints,
        style: 'dataText',
    } : '',
    {
        text: 'Bade - Erlaubnis',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        columns: [
            {
                width: '30%',
                columns: [[
                    {
                        text: 'Bade - Erlaubnis (Unter Aufsicht)',
                        style: 'label',
                        margin: [0,8,0,2],
                    },
                    {
                        text: data.badeerlaubnis ? 'Ja' : 'Nein',
                        style: 'dataText',
                    },
                ]],
            },
            {
                width: '45%',
                columns: [[
                    {
                        text: 'Schwimmer (kann sich mind. 10 min über Wasser halten)',
                        style: 'label',
                        margin: [0,8,0,2],
                    },
                    {
                        text: data.canSwim ? 'Ja' : 'Nein',
                        style: 'dataText',
                    },
                ]],
            },
            [
                {
                    text: 'Schwimmabzeichen',
                    style: 'label',
                    margin: [0,8,0,2],
                },
                {
                    text: data.swimmingBagde || halfText,
                    style: data.swimmingBagde ? 'dataText' : 'fieldText',
                },
            ]
        ]
    },
    {
        text: 'Ernährung',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 'Besondere Hinweise zur Ernährung',
        style: 'label',
    },
    {
        text: [
            data.vegetarier ? '- Vegetarier/in\n' : '',
            data.noPig ? '- Kein Schweinefleisch\n' : '',
            !data.noPig && !data.vegetarier ? 'Keine' : '',
        ],
        style: 'dataText',
        margin: [0,8,0,2],
    },
    {
        text: 'Lebensmittelallergien / Unverträglichkeiten',
        style: 'label',
        margin: [0,8,0,2],
    },
    {
        text: data.foodAllergic || fieldText,
        style: data.foodAllergic ? 'dataText' : 'fieldText',
    },
    {
        text: 'Gruppeneinteilung',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 'Bitte sortiere deine max. 3  Wünsche nach der Wichtigkeit. Wenn möglich, erfüllen wir alle Wünsche, garantieren dir aber nur den 1. Wunsch. Bitte, wenn bekannt, jeweils Vor- und Nachnamen angeben.',
        style: 'small',
    },
    {
        text: data.desires || fullPageText,
        style: data.desires ? 'dataText' : 'fieldText',
    },
    data.teenieHelfer ? {
        text: 'Teeniehelfer',
        style: 'fatLabel',
        margin: [0,12,0,2],
    } : '',
    data.teenieHelfer ? {
        text: 'Unser Kind arbeitet beim Kids-SOLA als Teeniehelfer mit. Alle in diesem Freizeitpass gemachten Angaben und Zustimmungen gelten daher ausdrücklich auch für den Zeitraum vom {solaKidsTermin}.',
        style: 'smallDataText',
    } : '',
    {
        text: 'Datenspeicherung',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 'Wir sind mit der Speicherung der Angaben für eine ggf. weitere Anmeldung zum SOLA im nächsten bzw. ggf. übernächsten Jahr einverstanden. In diesem Fall müssen wir die gespeicherten Daten nur noch einmal aktualisieren.',
        style: 'small',
    },
    {
        text: 'Weitere Hinweise',
        style: 'fatLabel',
        margin: [0,12,0,2],
    },
    {
        text: 'Hier können Sie uns weitere Informationen oder Bemerkungen schreiben, die in den obigen Feldern keinen Platz hatten',
        style: 'label',
    },
    {
        text: fullPageText,
        style: 'fieldText',
        margin: [0,8,0,2],
    },
    {
        text: fullPageText,
        style: 'fieldText',
        margin: [0,8,0,2],
    },
    {
        text: fullPageText,
        style: 'fieldText',
        margin: [0,8,0,2],
    },

    //-------------------------------------
    {
        columns: [
            {
                width: '32%',
                text: 'Informationen des Veranstalters', 
                style: 'fatLabel',
            },
            {
                width: '68%',
                text: '(nachfolgend vereinfachend SOLA genannt)', 
                style: 'label',
            },
        ],
        margin: [0,12,0,2],
        pageBreak: 'before',
    },
    {
        text: 'sowie Einwilligungen und Bestätigungen des/ der Sorgeberechtigten', 
        style: 'fatLabel',
        margin: [0,0,0,2],
    },
    {
        layout: 'noBorders',
        table: {
            headerRows: 0,
            widths: [ 10, 450 ],
            body: [
                [
                    '•',
                    {
                        text: 
                            'Das SOLA übernimmt keine Haftung für abhanden gekommene, beschädigte oder verlorene Gegenstände, die Ihrem Kind ' +
                            'oder einem Dritten gehören, es sei denn, dass dem SOLA ein Verschulden anzulasten ist.', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Während der Veranstaltung werden Fotos und ggf. Videos durch Mitarbeiter und/ oder Teilnehmer des SOLA sowie ggf. ' +
                            'Vertreter von Presse, Funk und Fernsehen gemacht, auf denen ggf. auch Ihr Kind zu sehen ist. Vereinzelt werden Fotos vom ' +
                            'SOLA in meist gemeindenahen Publikationen abgedruckt und/ oder im Internet einige Fotos und ggf. kurze Videos verwendet. ' +
                            'Weitere Informationen zum Umgang mit Bildern und Medien finden Sie in den Hinweisen und Bedingungen zur Teilnahme am {solaTitle} unter Punkt 11.', 
                        style: 'small',
                    }
                ],
                [
                    '',
                    {
                        text: 
                            'Mit Ihrer Unterschrift erteilen Sie die ausdrückliche, jederzeit widerrufliche, ansonsten jedoch unbefristete Zustimmung zur ' +
                            'entsprechenden Verwendung von Bildern und Videos, auf denen Ihr Kind abgebildet ist. ',
                        style: 'fatLabel',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Wenn Ihr Kind mit seinem Verhalten die Veranstaltung gefährdet oder durch sein Verhalten sich selbst oder andere gefährdet, ' +
                            'kann das SOLA es auf Ihre Kosten nach Hause schicken. In diesem Fall ist das SOLA berechtigt, den Vertrag der Teilnahme am ' +
                            'SOLA fristlos zu kündigen. Die vom SOLA eingesetzten Verantwortlichen sind ausdrücklich bevollmächtigt, Abmahnungen und ' +
                            'Kündigungen namens des SOLA vorzunehmen. Die zusätzlich entstandenen Kosten gehen in diesem Fall zu Ihren Lasten. ', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Mir ist bewusst, dass es sich beim SOLA um ein christlich geprägtes Zeltlager, bei dem auch das gemeinsame Lesen ' +
                            'von biblischen Texten, das Gebet und Singen von christlichen Liedern Teil des Programms sind, handelt. Vom Veranstalter ' +
                            'werden keinerlei religiöse Voraussetzungen, jedoch aber die Teilnahme am Programm erwartet.', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Mir ist bekannt, dass mein Kind während des SOLA keinerlei Sachen von besonderem  Wert wie  z.B. wertvollen ' +
                            'Schmuck, teure oder aufwendige Kleidung, elektronische Geräte, Mobiltelefone etc. mit sich führen soll. ', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Mein Kind ist von mir angewiesen worden, den Anordnungen der Verantwortlichen des SOLA Folge zu leisten. ' +
                            'Mir ist bekannt, dass das SOLA für Folgen von selbstständigen Unternehmungen und dadurch verursachte Schäden nicht haftet.', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Mir ist bekannt, dass die Teilnehmenden während des SOLA im Rahmen des Programms und ihrem Alter entsprechend ' +
                            'freie Zeit haben, in der sie selbstständig und ohne direkte Aufsicht unterwegs sein dürfen.', 
                        style: 'small',
                    }
                ],
                [
                    '•',
                    {
                        text: 
                            'Ich versichere, dass mein Kind an keiner ansteckenden Krankheit leidet und frei von Ungeziefer ' +
                            '(z. B. Kopfläusen, Flöhen) ist bzw. zum SOLA kommt. Das per E-Mail zugesandte Merkblatt GEMEINSAM VOR INFEKTIONEN ' +
                            'SCHÜTZEN des Robert-Koch-Instituts zu § 34 Abs. 5 S. 2 Infektionsschutzgesetz habe ich gelesen und mein Kind entsprechend belehrt.', 
                        style: 'small',
                    }
                ],
            ]
        },
        fontSize: 10, 
    },
    {
        text: 
            'Hiermit melde ich mein Kind verbindlich zum Sommerlager an.',
        style: 'small',
        margin: [0,6,0,2],
    },
    {
        text: 
            'Die vorstehenden Informationen sowie die Hinweise und Bedingungen zur Teilnahme am {solaTitle} ' +
            'habe ich zur Kenntnis genommen und akzeptiert. Alle im Freizeitpass gemachten Angaben sind richtig und vollständig.',
        style: 'small',
        margin: [0,0,0,2],
    },
    {
        text: 
            'Sollte sich bis zum Beginn des SOLA an diesen Angaben etwas ändern (insbesondere im Falle der Änderung von Adress- und Kontaktdaten, ' +
            'wenn neue Erkrankungen bzw. Beeinträchtigungen bekannt werden, mein Kind zwischenzeitlich operiert wurde, andere schwerere Verletzungen ' +
            'oder Krankheiten überstanden hat oder in den letzten 6 Wochen vor dem SOLA an einer ansteckenden Krankheit nach § 34 des ' +
            'Infektionsschutzgesetzes (z.B. Masern, Windpocken, Röteln, Keuchhusten, Scharlach, Läusebefall usw.) erkrankt war bzw. wenn andere ' +
            'einzelne Informationen nicht mehr zutreffen), werde ich dies dem SOLA unaufgefordert umgehend mitteilen.',
        style: 'small',
        margin: [0,0,0,2],
    },
    {
        text: 
            'Falls zutreffend bestätige ich als Alleinunterzeichner/in gleichzeitig, dass ich alleinige/r Sorgeberechtigte/r bzw. vom ' +
            'anderen Sorgeberechtigten (anderen Elternteil) mit der Abgabe der entsprechenden Erklärungen beauftragt bin und in dessen Kenntnis und Einverständnis handle.',
        style: 'small',
        margin: [0,0,0,2],
    },
    {
        columns: [
            {
                width: '25%',
                columns: [[
                    {
                        text: 'Datum',
                        style: 'label',
                        margin: [0,8,0,2],
                    },
                    {
                        text: halfText,
                        style: 'fieldText',
                    },
                ]],
            },
            {
                width: '35%',
                columns: [[
                    {
                        text: 'Unterschrift Erziehungsberechtigte/r',
                        style: 'label',
                        margin: [0,8,0,2],
                    },
                    {
                        text: halfText + '_______',
                        style: 'fieldText',
                    },
                ]],
            },
            {
                width: '35%',
                columns: [[
                    {
                        text: 'Unterschrift Erziehungsberechtigte/r',
                        style: 'label',
                        margin: [0,8,0,2],
                    },
                    {
                        text: halfText+ '_______',
                        style: 'fieldText',
                    },
                ]],
            },
        ],
    },
    {
        columns: [
            {
                width: '24%',
                columns: [[
                    {
                        text: 'Datum',
                        style: 'label',
                        margin: [0,12,0,6],
                    },
                    {
                        text: halfText,
                        style: 'fieldText',
                    },
                ]],
            },
            {
                width: '75%',
                columns: [[
                    {
                        text: 'Unterschrift Jugendliche/r',
                        style: 'label',
                        margin: [0,12,0,6],
                    },
                    {
                        text: fieldText + '______________',
                        style: 'fieldText',
                    },
                ]],
            },
        ],
    },
    {
        columns: [
            {
                image: 'adress',
                width: 250,
                height: 150,
                margin: [0,60,0,6],
            },
            [
                {
                    text: 
                        'Bitte diesen FREIZEITPASS unterschreiben und  spätestens 3 Wochen nach Erhalt der Anmeldebestätigung im Original unterschrieben per Post an die nebenstehende Adresse zurücksenden. Er gilt als offizielle Anmeldung. Sollte Ihre Anmeldung kurzfristig ' +
                        'erfolgen, muss der ausgefüllte FREIZEITPASS mit der Anmeldung ' +
                        'vorliegen. Herzlichen Dank!',
                    style: 'fatLabel',
                    margin: [0,20,0,6],
                },
                {
                    text: 
                        'Hinweis: Die Mitarbeitenden des SOLA versichern den vertrauensvollen Umgang mit den Informationen in ' +
                        'diesem FREIZEITPASS. Die Daten Ihrer Tochter/Ihres Sohnes werden ' +
                        'von den Mitarbeitenden nur weitergegeben bei ' +
                        'Inanspruchnahme ärztlicher Leistungen und gegenüber Behörden. ' +
                        'Dieser FREIZEITPASS wird bis zur Verjährung von möglichen ' +
                        'rechtlichen Ansprüchen des/der Teilnehmenden oder des/der ' +
                        'Sorgeberechtigten beim Veranstalter, unter ' +
                        'Beachtung des Datenschutzes, aufbewahrt.',
                    style: 'label',
                    margin: [0,12,0,6],
                },
            ],
        ],
    },
];

export default getConfig;