import React, { useCallback } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { useConfig } from '../../hooks/useConfig';

export default function PopupConfig() {
    const { 
        config: { 
            popupEnabled,
            popupTitle,
            popupDescription,
        }, 
        setBackendConfig 
    } = useConfig();

    const onPopupEnableChange = useCallback(
        () => setBackendConfig('popupEnabled', !Boolean(popupEnabled)),
        [setBackendConfig, popupEnabled]
    );

    const onTitleChange = useCallback(
        (event) => setBackendConfig('popupTitle', event.target.value),
        [setBackendConfig]
    );

    const onDescriptionChange = useCallback(
        (event) => setBackendConfig('popupDescription', event.target.value),
        [setBackendConfig]
    );

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={Boolean(popupEnabled)} onChange={onPopupEnableChange} name="popupEnabled" />}
                    label="Popup aktiviert"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={popupTitle}
                    label="Titel"
                    fullWidth
                    onBlur={onTitleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={popupDescription}
                    label="Inhalt"
                    fullWidth
                    onBlur={onDescriptionChange}
                />
            </Grid>
        </Grid>
    );
}
