import React, { useCallback } from "react";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import SingleSelect from './fields/ControlledSelect';
import MultiSelect from './fields/ControlledMultiSelect';
import DatePicker from './fields/ControlledDatePicker';
import ControlledCheckbox from './fields/ControlledCheckbox';
import TextField from './fields/ControlledTextField';
import MyTypography from './fields/Typography';

import { useConfig } from '../../hooks/useConfig';
import replaceConfigInAllStrings from '../../utils/replaceConfigInAllStrings';

const getPresentation = type => {
  switch (type) {
    case 'MultiSelect':
      return MultiSelect;
    case 'SingleSelect':
      return SingleSelect;
    case 'DatePicker':
      return DatePicker;
    case 'Checkbox':
      return ControlledCheckbox;
    case 'Typography':
      return MyTypography;
    case 'Divider':
      return () => <Divider/>;
    default:
      return TextField;
  }
}

const enritchPresentation = item => ({ ...item, presentation: getPresentation(item.presentation) });

const getDefaultValues = (saved, fields) => {
  const defaultValues = cloneDeep(saved);

  fields.forEach(field => {
    if (defaultValues[field.name] === undefined || isEmpty(defaultValues[field.name])) {
      if (field.options && field.preSelected) {
        if (field.preSelected === 'all') {
          defaultValues[field.name] = field.options;
        } else {
          defaultValues[field.name] = field.preSelected.map(index => field.options[index]);
        }
      }

      if (field.default) {
        defaultValues[field.name] = field.default;
      }

      if (field.defaultValue) {
        defaultValues[field.name] = field.defaultValue;
      }
    }
  });

  return defaultValues;
};

export default function FormStep({
  handleBack,
  handleNext,
  nextText = 'Weiter',
  backText = 'Zurück',
  hideBack = false,
  defaultValues = {},
  fields = [],
}) {
  const { config } = useConfig();
  const usedDefaultValues = getDefaultValues(defaultValues, fields);
  const { control, handleSubmit, errors, getValues } = useForm({ defaultValues: usedDefaultValues });

  const goBack = useCallback(() => handleBack(getValues()), [getValues, handleBack]);
  
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={2}>
          {
            fields
              .map(enritchPresentation)
              .map(field => (
                <Grid
                  item 
                  xs={field.xs || 12} 
                  sm={field.sm} 
                  md={field.md} 
                  style={field.style} 
                  key={`field-grid-${field.name}`}
                >
                  {
                    field
                      .presentation(
                        replaceConfigInAllStrings(
                          {
                            ...field,
                            control,
                            defaultValue: usedDefaultValues[field.name] || field.defaultValue,
                            error: Boolean(errors[field.name]),
                          },
                          config
                        )
                      )
                  }
                </Grid>
              ))
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} justify="center">
          {
            !hideBack && 
            <Grid item>
              <Button disabled={!handleBack} onClick={goBack}>
                {backText}
              </Button>
            </Grid>
          }
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleNext)}
            >
              {nextText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
