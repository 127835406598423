import React from "react";
import Typography from '@material-ui/core/Typography';

const MyTypography = ({ 
  label, 
  defaultValue,
  value,
  align,
  color,
  component,
  display,
  gutterBottom,
  paragraph,
  variant,
}) => (
  <Typography 
    align={align}
    color={color}
    component={component}
    display={display}
    gutterBottom={gutterBottom}
    paragraph={paragraph}
    variant={variant}
  >
    {defaultValue || value || label}
  </Typography> 
);

export default MyTypography;
