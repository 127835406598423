import React from "react";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Controller } from "react-hook-form";


const ControlledCheckbox = ({ 
  name, 
  defaultValue, 
  label, 
  required, 
  control,
  preLabel,
  postLabel,
  error,
}) => (
  <>
    { preLabel && <p>{preLabel}</p> }
    <Controller
      name={name}
      control={control}
      defaultValue={Boolean(defaultValue)}
      rules={{ required }}
      render={(properties) => (
        <FormControl
          error={error}
          required={required}
        >
          <FormControlLabel
            control={
              <Checkbox
                {...properties}
                onChange={event => properties.onChange(event.target.checked)}
                checked={Boolean(properties.value)}
                color="primary"
              />
            }
            label={label}
          />
          { error && <FormHelperText>Dieses Feld muss angehakt sein.</FormHelperText> }
        </FormControl>
      )}
    />
    { postLabel && <Typography variant="body1">{postLabel}</Typography> }
  </>
);

export default ControlledCheckbox;
