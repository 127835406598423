import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress  from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';

import SharedItemTableWrapper from '../../authenticated/verwaltung/groups/sharedItems/SharedItemTableWrapper';
import { getGroupedPersons } from '../../authenticated/verwaltung/groups/GroupContainer';

import getBackend from '../../hooks/getBackend';
import { getParameterByName } from '../../hooks/useSecret';
import { useConfig } from '../../hooks/useConfig';
import formatPersonData from '../../utils/formatPersonData';

const sharedItemSecret = getParameterByName('sharedsecret');

export default function SharedItem() {
  const { enqueueSnackbar } = useSnackbar();
  const [sharedItemData, setSharedItemData] = useState({ loading: true });
  const { config } = useConfig();

  const displayedPersons = useMemo(
    () => sharedItemData.persons 
      ? getGroupedPersons(
        formatPersonData(sharedItemData.persons, config),
        sharedItemData?.groupLabel,
      )
      : [],
    [sharedItemData, config]
  );

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(
              `${getBackend()}/shared/getSharedItem.php`,
              { 
                  method: 'POST', 
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    groupsecret: sharedItemSecret,
                  })
              }
          );

          const newItems = await response.json() || {};
          setSharedItemData(newItems);
        } catch(error) {
          console.log(error);
          enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
          setSharedItemData({});
        }
      }

      fetchData();
    },
    [enqueueSnackbar, setSharedItemData]
  );

  return (
    <Container>
      <Grid container spacing={3}>
        { 
          sharedItemData.label && <Grid item xs={12}>
            <Typography variant="h6">
              {`${sharedItemData.groupLabel}: ${sharedItemData.label}`}
            </Typography> 
          </Grid> 
        }
        { sharedItemData.loading && <Grid item><CircularProgress /></Grid> }
        {
          sharedItemData.columns && <SharedItemTableWrapper
            sharedItem={sharedItemData}
            groupedPersons={displayedPersons}
          />
        }
        { !sharedItemData.loading && !sharedItemData.columns && <Grid item>Keine Daten gefunden</Grid> }
      </Grid>
    </Container>
  );
}
