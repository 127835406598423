import React, { useMemo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const getCellValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Ja' : 'Nein';
  }

  if (typeof value === 'number') {
    return value;
  }

  if (!Number.isNaN(Date.parse(value))) {
    return new Date(value).toLocaleDateString();
  }

  return value;
}

export default function SystemWidePersons({ allPersons = [] }) {
  const classes = useStyles();

  const parsedPersons = useMemo(
    () => allPersons.map(
      item => Object.keys(item).reduce(
        (lastResult, nextKey) => {
          lastResult[nextKey] = getCellValue(lastResult[nextKey])

          return lastResult;
        },
        item,
      )
    ),
    [allPersons],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Vorname</TableCell>
                <TableCell>Nachname</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedPersons.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.firstname}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastname}
                  </TableCell>
                  <TableCell>{JSON.stringify(row)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
      </Grid>
    </Grid>
  );
}
