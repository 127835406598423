import React, { useMemo } from 'react';
import reduce from 'lodash/reduce';

import PaymentCard from './PaymentCard';

import { usePayment } from '../hooks/usePayment';
import TabBar from '../components/TabBar';

export const enritchPayments = (person, payments) => {
  const personPayments = payments.filter(payment => payment.personId === person.id);

  const paid = reduce(personPayments, (result, payment) => result + payment.amount, 0);

  return ({
    ...person,
    paid,
    paySum: paid - person.hasToPay,
    payments: personPayments,
  })
};

export default function Payment({ bestätigt }) {
  const { payments } = usePayment();

  const usersWithPayments = useMemo(() => bestätigt.map(person => enritchPayments(person, payments)), [bestätigt, payments]);

  const offeneBezahlungen = useMemo(() => 
    usersWithPayments
      .filter(person => person.paySum < 0),
    [usersWithPayments]
  );

  const geschlosseneBezahlungen = useMemo(() => 
    usersWithPayments
      .filter(person => person.paySum >= 0), 
    [usersWithPayments]
  );

  const spenden = useMemo(() => 
    usersWithPayments
      .filter(person => person.paySum > 0), 
    [usersWithPayments]
  );

  return <TabBar
    tabs={[
      {
        label: `Offene Bezahlungen (${offeneBezahlungen.length})`,
        presentation: offeneBezahlungen.map(person => <PaymentCard {...person} key={person.id}/>)
      },
      {
        label: `Geschlossene Bezahlungen (${geschlosseneBezahlungen.length})`,
        presentation: geschlosseneBezahlungen.map(person => <PaymentCard {...person} key={person.id}/>)
      },
      {
        label: `Spenden (${spenden.length})`,
        presentation: spenden.map(person => <PaymentCard {...person} key={person.id}/>)
      },
    ]}
  />;
}
