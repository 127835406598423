import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useSecret } from '../../hooks/useSecret';
import getBackend from '../../hooks/getBackend';
import { useAllSolas } from './useAllSolas';

const MyContext = createContext();
const { Provider } = MyContext;

export const useRoles = () => useContext(MyContext);

export default function RolesProvider({children}) { 
    const [roles, setRoles] = useState([]);
    const { secret } = useSecret();
    const { prefix } = useAllSolas();

    const { enqueueSnackbar } = useSnackbar();

    const handleRolesResponse = useCallback(async (response) => {
        const newRoles = await response.json() || [];
        setRoles(newRoles);
    }, [setRoles]);

    const getRoles = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/roles/getRoles.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );

    useEffect(() => {
        if (secret) {
            getRoles();
        }
    }, [secret, getRoles]);
    
    
    const addRole = useCallback(
        async (name) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/roles/addRole.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            name,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );
    
    const removeRole = useCallback(
        async (name) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/roles/removeRole.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            name,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );

    return (
        <Provider 
            value={{
                roles, 
                setRoles, 
                getRoles,
                addRole,
                removeRole,
            }}
        >
            {children}
        </Provider>
    );
};
