import { forOwn } from 'lodash';

const defaultValues = {
    activities: "Ja",
    doctorActivities: true,
    doctorModel: "Ja",
    medicamentsHintsExtended: [
        "die Desinfektion von Wunden mit handelsüblichen Desinfektionsmitteln.",
        "das Kleben von Pflastern auf offene Wunden.",
        "bedarfsweise Anwendung von Hausmitteln (z.B. Wärmekissen, kalte oder warme Umschläge, Tee o.ä.) und ggf. der Gabe von rezeptfreien Medikamenten (z. B. Arnikasalbe, Hustensaft, Fenistil-Gel, Halstabletten, Magentropfen, Wund- oder Stichsalbe u.ä.).",
        "das Entfernen von Fremdkörpern aus den oberen Hautschichten (z.B. Holzsplitter, Glasscherbe etc.) mit speziell hierfür vorgesehenen Hilfsmitteln und die anschließende Desinfektion der Wunde mit handelsüblichen Desinfektionsmitteln."
    ],
    tickRemoval: "Ja",
};

const setDefaultValues = person => {
    forOwn(defaultValues, (value, key) => {
        if (person[key] === undefined) {
            person[key] = value;
        }
    });
};

export default setDefaultValues;