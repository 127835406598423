import React, { useState, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PostAdd from '@material-ui/icons/PostAdd';

import { useOwnFields } from '../../hooks/useOwnFields';
import FormStep from '../../../forms/components/FormStep';

export default function OwnFieldsManagement() {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { addOwnField, removeOwnField, ownFields } = useOwnFields();

  const handleEditing = useCallback(() => setEditing(true), [setEditing]);
  const handleStopEditing = useCallback(() => setEditing(false), [setEditing]);
  const handleStopDeleting = useCallback(() => setDeleting(false), [setDeleting]);

  const handleAdd = useCallback(async (data) => {
    await addOwnField(data.label);
  }, [addOwnField]);

  const handleRemove = useCallback(async () => {
    await removeOwnField(deleting);
    handleStopDeleting();
  }, [removeOwnField, deleting, handleStopDeleting]);

  return (
    <>
      <Button
        startIcon={<PostAdd />} 
        onClick={handleEditing} 
        color="primary"
        variant="outlined"
        style={{ marginTop: '8px', marginBottom: '8px' }}
      >
        Felder editieren
      </Button>
      <Dialog 
        open={editing} 
        onClose={handleStopEditing} 
        aria-labelledby={`edit-fields-dialog`}
        scroll={'paper'}
        maxWidth={'lg'}
      >
        <DialogTitle id={`edit-fields-dialog`}>Felder</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={10} md={8}>
              <List>
                {
                  ownFields.map(({ fieldId, label }) => (
                    <ListItem disableGutters key={fieldId}>
                      <ListItemText primary={label}/>
                      <ListItemIcon>
                        <IconButton onClick={() => setDeleting(fieldId)}>
                          <DeleteOutline/>
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item xs={12} sm={10} md={8}>
              <Divider/>
            </Grid>
          </Grid>
          <FormStep
            handleNext={handleAdd}
            nextText={'Hinzufügen'}
            hideBack
            fields={[
              {
                name: 'label',
                label: 'Feldname',
                required: true,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStopEditing} color="primary" variant="contained">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={Boolean(deleting)} 
        onClose={handleStopDeleting} 
        aria-labelledby={`delete-field-dialog`}
      >
        <DialogTitle id={`delete-field-dialog`}>Wirklich entfernen?</DialogTitle>
        <DialogActions>
          <Button onClick={handleStopDeleting}>
            Abbrechen
          </Button>
          <Button onClick={handleRemove} color="primary" variant="contained">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
