import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    height: 500,
    backgroundImage: 'url("./img/wide.jpg")',
    backgroundSize: 'cover',
  },
  overlay: {
    height: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  margin: {
    margin: theme.spacing(2),
  },
  infoBg: {
    backgroundColor: theme.palette.grey[600],
  }
}));

export default function Datenschutz() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titlePaper}>
        <div className={classes.overlay}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">
                Sommerlager Zieko
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Paper style={{ margin: '16px  0', padding: '16px', textAlign: 'left'}}>
              <section className="container"> <b><font size="+2">Datenschutzerklärung</font></b>
                <p>&nbsp; <br/>
                  <b>Geltungsbereich</b> </p>
                <p>Diese Datenschutzerklärung soll die Nutzer dieser Website
                  gemäß dem Kirchengesetz informieren über den
                  Datenschutz der Evangelischen Kirche in Deutschland
                  (EKD-Datenschutzgesetz-DSG-EKD) und dem Telemediengesetz über
                  die Art, den Umfang und den Zweck der Erhebung und Verwendung
                  personenbezogener Daten durch die Evangelische Hoffnungsgemeinde
                  Zieko. </p>
                <p>&nbsp; <br/>
                  Evangelische Hoffnungsgemeinde Zieko <br/>
                  Dorfstraße 2 <br/>
                  06869 Coswig <br/>
                  Tel. 034903 / 62645 <br/>
                  Fax. 034903 / 66558 <br/>
                  E-Mail: <img alt="info email" src={'./img/emails/dark/info.png'}/> </p>
                <p>Die Evangelische Hoffnungsgemeinde Zieko nimmt den Schutz Ihrer
                  personenbezogenen Informationen sehr ernst und behandelt Ihre
                  personenbezogenen Daten vertraulich und entsprechend der
                  gesetzlichen Vorschriften. Bedenken Sie, dass die
                  Datenübertragung im Internet grundsätzlich mit
                  Sicherheitslücken bedacht sein kann. Ein vollumfänglicher
                  Schutz vor dem Zugriff durch Fremde ist nicht realisierbar. <br/>
                  &nbsp; </p>
                <p><b>Landeskirchlicher Datenschutzbeauftragter</b> </p>
                <p>Johannes Killyen<br/>
                  Landeskirchenamt<br/>
                  Friedrichstraße 22/24,<br/>
                  06844 Dessau-Roßlau<br/>
                  Tel. 0340 / 2526-101<br/>
                  mobil 0178 / 5222 177<br/>
                  E-Mail: johannes.killyen@kircheanhalt.de<br/>
                  &nbsp; </p>
                <p><b>Zugriffsdaten</b> <br/>
                  Die Evangelische Hoffnungsgemeinde Zieko erhebt Daten über
                  Zugriffe auf die Seite und speichert diese als
                  „Server-Logfiles“ ab. Folgende Daten werden auf diese
                  Weise protokolliert: <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Besuchte Website <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Uhrzeit zum Zeitpunkt des Zugriffes
                  <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Menge der gesendeten Daten in Byte
                  <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Quelle/Verweis, von welchem Sie auf
                  die Seite gelangten <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Verwendeter Browser <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Verwendetes Betriebssystem <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Verwendete IP-Adresse, um zwei
                  Bytes gekürzt <br/>
                  Die erhobenen Daten dienen lediglich statistischen Auswertungen und
                  zur Verbesserung der Website. Die Evangelische Hoffnungsgemeinde
                  Zieko behält sich allerdings vor, die Server-Logfiles
                  nachträglich zu überprüfen, sollten konkrete
                  Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen. <br/>
                  &nbsp; </p>
                <p><b>Cookies</b> </p>
                <p>Diese Website verwendet Cookies. Dabei handelt es sich um kleine
                  Textdateien, welche auf Ihrem Endgerät gespeichert werden. Ihr
                  Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies
                  erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser
                  Website. Gängige Browser bieten die Einstellungsoption, Cookies
                  nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie
                  auf alle Funktionen dieser Website ohne Einschränkungen
                  zugreifen können, wenn Sie entsprechende Einstellungen
                  vornehmen. <br/>
                  &nbsp; </p>
                <p><b>Umgang mit personenbezogenen Daten</b> </p>
                <p>Die Evangelische Hoffnungsgemeinde Zieko erhebt, nutzt und gibt
                  Ihre personenbezogenen Daten nur dann weiter, wenn dies im
                  gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung
                  einwilligen. Als personenbezogene Daten gelten sämtliche
                  Informationen, welche dazu dienen, Ihre Person zu bestimmen und
                  welche zu Ihnen zurückverfolgt werden können – also
                  beispielsweise Ihr Name, Ihre E-Mail Adresse und Telefonnummer. <br/>
                  Die Übertragung Ihrer personenbezogenen Informationen erfolgt
                  technisch über eine SSL- Verschlüsselung. <br/>
                  &nbsp; </p>
                <p><b>Ihre Rechte als Nutzer der Website: Auskunft, Berichtigung und
                    Löschung</b> </p>
                <p>Sie als Nutzer erhalten auf Antrag Ihrerseits kostenlose Auskunft
                  darüber, welche personenbezogenen Daten über Sie
                  gespeichert wurden. Sofern Ihr Wunsch nicht mit einer gesetzlichen
                  Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung)
                  kollidiert, haben Sie ein Anrecht auf Berichtigung falscher Daten
                  und auf die Sperrung oder Löschung Ihrer personenbezogenen
                  Daten. </p>
                <p>Umgang mit Kommentaren und Beiträgen (beispielsweise bei
                  Nutzung von Gästebüchern) Hinterlassen Sie auf dieser
                  Website einen Beitrag oder Kommentar, wird Ihre IP-Adresse
                  gespeichert. Dies dient der Sicherheit des Websitebetreibers:
                  Verstößt Ihr Text gegen das Recht, möchte er Ihre
                  Identität nachverfolgen können. </p>
                <p>&nbsp; </p>
                <p>&nbsp; <br/>
                  &nbsp; <br/>
                  &nbsp;Evangelische Hoffnungsgemeinde Zieko&nbsp; | 034903-62645 </p>
              </section>    
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
