import React, { useState, useEffect, useCallback, useMemo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Alert from '@material-ui/lab/Alert';

import { usePersons } from '../../hooks/usePersons';
import TeilnehmerConfig from '../configs/teilnehmer.json';
import MitarbeiterConfig from '../configs/mitarbeiter.json';

import CompleteForm from './CompleteForm';
import PersonSelect from './PersonSelect';

import FullScreenReminder from '../fullScreenReminder';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const teilnehmerSuccess = <>
  <p>
    Der Teilnehmer wurde angemeldet. Sollten Sie innerhalb von 2 Wochen keine Anmeldebestätigung per 
    Mail erhalten haben, geben Sie uns bitte Bescheid über:
    <br/>
    <img alt="anmeldung email" src={'./img/emails/dark/anmeldung.png'}/> 
    <br/>
    Schauen Sie bitte vorher im Spamordner nach, ob sich die Mail dorthin verirrt hat.
  </p>
  <p>
    Einen weiteren Teilnehmer können Sie anmelden, indem Sie oben auf "Neue Anmeldung" klicken.
  </p>
</>;

const mitarbeiterSuccess = <>
  <p>
    Der Mitarbeiter wurde angemeldet. Sollten Sie innerhalb von 2 Wochen keine Anmeldebestätigung per 
    Mail erhalten haben, geben Sie uns bitte Bescheid über:
    <br/>
    <img alt="anmeldung email" src={'./img/emails/dark/anmeldung.png'}/> 
    <br/>
    Schauen Sie bitte vorher im Spamordner nach, ob sich die Mail dorthin verirrt hat.
  </p>
  <p>
    Einen weiteren Mitarbeiter können Sie anmelden, indem Sie oben auf "Neue Anmeldung" klicken.
  </p>
</>;

export default function PersonForm({
    isMitarbeiter = false,
}) {
  const classes = useStyles();
  const [openReminder, setOpenReminder] = useState(true);
  const onCloseReminder = useCallback(() => setOpenReminder(false), [setOpenReminder]);
  const { teilnehmer, mitarbeiter, doMemberSignup, doWorkerSignup, selectedPerson, setSelectedPerson, persons } = usePersons();

  const personLength = isMitarbeiter ? mitarbeiter.length : teilnehmer.length;

  const [activeStep, setActiveStep] = useState(personLength ? 0 : 1);
  
  useEffect(() => window.scrollTo(0, 0), [activeStep]);

  const usedConfig = useMemo(() => isMitarbeiter ? MitarbeiterConfig : TeilnehmerConfig, [isMitarbeiter]);
  const usedSuccessAlert = useMemo(() => isMitarbeiter ? mitarbeiterSuccess : teilnehmerSuccess, [isMitarbeiter]);
  const usedSignup = useMemo(
    () => isMitarbeiter ? doWorkerSignup : doMemberSignup, 
    [isMitarbeiter, doMemberSignup, doWorkerSignup]
  );

  const onSubmit = useCallback(async(newData) => {
    setActiveStep(2);
    const hasWorked = await usedSignup(newData);
    setActiveStep(hasWorked ? 3 : 4);

    if (hasWorked) {
      setSelectedPerson(false);
    }
  }, [setActiveStep, usedSignup, setSelectedPerson]);

  const gotoForm = useCallback(() => setActiveStep(1), [setActiveStep]);

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          { 
            (activeStep === 0 || activeStep === 3 || activeStep === 4 || persons.length > 0) && 
              <Grid item xs={12}>
                <PersonSelect gotoForm={gotoForm}/> 
              </Grid>
          }
          { 
            activeStep === 1 && 
              <Grid item xs={12}>
                <CompleteForm 
                  {...usedConfig} 
                  onSubmit={onSubmit} 
                  defaultValues={selectedPerson}
                  key={`person-form-${selectedPerson.id || 'new'}`}
                  submitText={selectedPerson.id ? 'Bestätigen' : 'Anmelden'}
                />
              </Grid>
          }
          { activeStep === 2 && <Grid item xs={12}><CircularProgress/></Grid> }
          { 
            activeStep === 3 && 
              <Grid item xs={12}>
                <Alert severity="success">
                  {usedSuccessAlert}
                </Alert>
              </Grid>
          }
          { 
            activeStep === 4 && 
              <Grid item xs={12}>
                <Alert severity="error">
                  <p>Die Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es später erneut.</p>
                </Alert>
              </Grid>
          }
        </Grid>
      </Paper>
      <FullScreenReminder isMitarbeiter={isMitarbeiter} open={openReminder} onClose={onCloseReminder}/>
    </Container>
  );
}
