import React, { useState, useCallback, useMemo, useEffect } from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";

import FormStep from './FormStep';

const useStyles = makeStyles((theme) => ({
    bigStepLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    smallStepLabel: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
}));

export default function CompleteForm({
    formSteps = [],
    defaultValues = {},
    onSubmit,
    submitText = 'Anmelden',
    label,
}) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => window.scrollTo(0, 0), [activeStep]);

  const handleNext = useCallback((values) => {
    const newData = { ...formData, ...values };
    if (activeStep >= formSteps.length - 1) {
      onSubmit(newData);
    } else {
      setFormData(newData);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [formData, activeStep, setActiveStep, onSubmit, formSteps, setFormData]);

  const handleBack = useCallback((values) => {
    const newData = { ...formData, ...values }; 
    setFormData(newData);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [formData, setFormData, setActiveStep]);

    const displayedFormStep = useMemo(() => (
        <FormStep
            key={get(formSteps, `[${activeStep}].stepLabel`, '')}
            handleBack={activeStep > 0 && handleBack}
            handleNext={handleNext}
            nextText={activeStep === formSteps.length - 1 ? submitText : 'Weiter'}
            defaultValues={formData || {}}
            fields={get(formSteps, `[${activeStep}].items`, [])}
        />
    ), [handleBack, handleNext, activeStep, formSteps, submitText, formData]);

  return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="h4">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {
                    formSteps.map(({ stepLabel }) => (
                        <Step key={stepLabel}>
                            <StepLabel>
                              <span className={classes.bigStepLabel}>
                                {stepLabel}
                              </span>
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <Typography variant="h6" className={classes.smallStepLabel}>
              {get(formSteps, `[${activeStep}].stepLabel`, '')}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            {displayedFormStep}
        </Grid>
    </Grid>
  );
}
