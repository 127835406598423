import React from 'react';
import UserCard from './UserCard';
import TabBar from '../components/TabBar';


export default function Anmeldungen({ display, neueAnmeldungen, bestätigt, abgelehnt }) {
    return <TabBar
    tabs={[
      {
        label: `Neue Anmeldungen (${neueAnmeldungen.length})`,
        presentation: neueAnmeldungen.map(person => <UserCard {...person} display={display} key={person.id}/>),
      },
      {
        label: `Bestätigte Anmeldungen (${bestätigt.length})`,
        presentation: bestätigt.map(person => <UserCard {...person} display={display} key={person.id}/>),
      },
      {
        label: `Abgelehnte Anmeldungen (${abgelehnt.length})`,
        presentation: abgelehnt.map(person => <UserCard {...person} display={display} key={person.id}/>),
      },
    ]}
  />;
}
