import React from "react";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Controller } from "react-hook-form";


const ControlledTextField = ({ 
  name, 
  defaultValue, 
  label, 
  required, 
  error,
  control,
  type,
  multiline,
  rows,
  preLabel,
  postLabel,
  InputProps,
}) => (
  <>
    { preLabel && <p>{preLabel}</p> }
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{ required }}
      render={(properties) => (
        <TextField 
            label={`${label}${required ? ' *' : ''}`}
            fullWidth
            variant="outlined" 
            error={error}
            type={type}
            multiline={multiline}
            rows={rows}
            InputProps={InputProps}
            {...properties}
          />
      )}
    />
    { postLabel && <Typography variant="body1">{postLabel}</Typography> }
  </>
);

export default ControlledTextField;
