import React, { useState, useCallback, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RotateLeft from '@material-ui/icons/RotateLeft';
import Edit from '@material-ui/icons/Edit';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Autorenew from '@material-ui/icons/Autorenew';
import sample from 'lodash/sample';
import sortBy from 'lodash/sortBy';

import { useAllPersons } from '../hooks/useAllPersons';
import CompleteForm from '../../forms/components/CompleteForm';
import TeilnehmerConfig from '../../forms/configs/teilnehmer.json';
import MitarbeiterConfig from '../../forms/configs/mitarbeiter.json';

const useStyles = makeStyles(() => ({
  secretEditor: {
    minHeight: '88px',
  },
}));

const insertAtPositions = (inputString, positions, insertLetters) => {
  const output = positions.map(
    (position, index) => {
      if (index === 0) {
        return inputString.slice(0, position) + insertLetters[index];
      } else {
        return inputString.slice(positions[index-1], position) + insertLetters[index];
      }
    }
  );

  output.push(inputString.slice(positions[positions.length - 1]))

  return output.join('');
};

const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
const generateNewSecret = () => {
  const randomNumber = Math.round(Math.random() * 100000000000) / 10000;
  const positions = sortBy(letters.map(() => Math.round(Math.random() * 10)));
  const insertLetters = letters.map(() => sample(letters));

  return insertAtPositions(randomNumber.toString(), positions, insertLetters);
}

export default function ActionMenu(person) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const { setPersonConfirmed, updatePerson, removePerson } = useAllPersons();

  const handleAproove = useCallback(() =>{
    setPersonConfirmed(person.id, '1', person.display === 'mitarbeiter');
    handleClose();
  }, [handleClose, setPersonConfirmed, person]);

  const handleDeny = useCallback(() =>{
    setPersonConfirmed(person.id, '2', person.display === 'mitarbeiter');
    handleClose();
  }, [handleClose, setPersonConfirmed, person]);

  const handleReset = useCallback(() =>{
    setPersonConfirmed(person.id, '0', person.display === 'mitarbeiter');
    handleClose();
  }, [handleClose, setPersonConfirmed, person]);

  const [editing, setEditing] = useState(false);
  const editData = useCallback(() => setEditing(true), []);
  const stopEditing = useCallback(() => setEditing(false), []);

  const onSubmitEditing = useCallback(async (newData) => {
    await updatePerson(newData);
    stopEditing();
    handleClose();
  }, [updatePerson, stopEditing, handleClose]);

  const onSubmitNewSecret = useCallback(async (newData) => {
    await updatePerson(newData);
  }, [updatePerson]);

  const [removing, setRemoving] = useState(false);
  const initializeRemoveData = useCallback(() => setRemoving(true), []);
  const cancelRemove = useCallback(() => setRemoving(false), []);

  const onSubmitRemoving = useCallback(async () => {
    await removePerson(person.id);
    cancelRemove();
    handleClose();
  }, [person, removePerson, cancelRemove, handleClose]);

  const usedConfig = useMemo(() => person.display === 'mitarbeiter' ? MitarbeiterConfig : TeilnehmerConfig, [person]);

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="person-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { 
            ((person.display === 'mitarbeiter' && person.confirmedMitarbeiter !== 1) || (person.display === 'teilis' && person.confirmed !== 1)) &&
            <MenuItem onClick={handleAproove}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <CheckCircleOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Bestätigen" />
            </MenuItem>
        }
        { 
            ((person.display === 'mitarbeiter' && person.confirmedMitarbeiter !== 2) || (person.display === 'teilis' && person.confirmed !== 2)) &&
            <MenuItem onClick={handleDeny}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <HighlightOff fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Ablehnen" />
            </MenuItem>
        }
        { 
            ((person.display === 'mitarbeiter' && person.confirmedMitarbeiter !== 0 && person.confirmedMitarbeiter) || (person.display === 'teilis' && person.confirmed !== 0 && person.confirmed)) &&
            <MenuItem onClick={handleReset}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <RotateLeft fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Status zurücksetzen" />
            </MenuItem>
        }
        <MenuItem onClick={editData}>
            <ListItemIcon style={{ minWidth: '28px' }}>
                <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Daten bearbeiten" />
        </MenuItem>
        <MenuItem onClick={initializeRemoveData}>
            <ListItemIcon style={{ minWidth: '28px' }}>
                <DeleteForever fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Person löschen" />
        </MenuItem>
      </Menu>
      
      <Dialog onClose={stopEditing} open={editing} maxWidth={false}>
        <DialogTitle>Daten bearbeiten</DialogTitle>
        <DialogContent dividers classes={{ root: classes.secretEditor }}>
          <TextField 
            defaultValue={person.newSecret || person.secret}
            label={'Secret'}
            fullWidth
            onChange={event => person.newSecret = event.target.value }
            onBlur={event => onSubmitNewSecret({ ...person, newSecret: event.target.value })}
            InputProps={{ 
              endAdornment: 
                <InputAdornment position="end">
                  <IconButton
                    onClick={
                      () => onSubmitEditing({ 
                        ...person, 
                        newSecret: generateNewSecret() 
                      })}
                  >
                    <Autorenew/>
                  </IconButton>
                </InputAdornment>
            }}
          />
        </DialogContent>
        <DialogContent dividers>
          <CompleteForm 
            {...usedConfig} 
            onSubmit={onSubmitEditing} 
            defaultValues={person}
            key={`person-form-${person.id}`}
            submitText={'Speichern'}
          />
        </DialogContent>
      </Dialog>
      
      <Dialog 
        open={Boolean(removing)} 
        onClose={cancelRemove} 
        aria-labelledby={`delete-person-dialog-${person.id}`}
      >
        <DialogTitle id={`delete-person-dialog-${person.id}`}>Daten unwiederruflich entfernen?</DialogTitle>
        <DialogActions>
          <Button onClick={cancelRemove}>
            Abbrechen
          </Button>
          <Button onClick={onSubmitRemoving} color="primary" variant="contained">
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
