import React, { useState, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import GetApp from "@material-ui/icons/GetApp";
import Print from "@material-ui/icons/Print";
import CircularProgress from '@material-ui/core/CircularProgress';

import getConfig from './config';
import { downloadPdf, printPDF, getAsDefinition, showInIframe } from '../../utils/pdfMake';
import { usePersons } from '../../hooks/usePersons';
import PersonSelect from '../../forms/components/PersonSelect';
import CompleteForm from '../../forms/components/CompleteForm';
import FreizeitpassForm from '../../forms/configs/freizeitpass.json';
import replaceConfigInAllStrings from '../../utils/replaceConfigInAllStrings';
import { useConfig } from '../../hooks/useConfig';

import setDefaultValues from './setDefaultValues';

export default function Freizeitpass() {
    const { selectedPerson, doMemberSignup } = usePersons();
    const { config } = useConfig();

    if (selectedPerson) {
        setDefaultValues(selectedPerson);
    }

    const [activePage, setActivePage] = useState(selectedPerson ? 1 : 0);

    const onPersonSelectForm = useCallback(() => setActivePage(1), [setActivePage]);
    
    const onSubmit = useCallback(async(newData) => {
        setActivePage(2);
        const hasWorked = await doMemberSignup(newData);
        setActivePage(hasWorked ? 3 : 4);

        if (newData && !isEmpty(newData)) {
            showInIframe(
                getAsDefinition(
                    {
                        content: replaceConfigInAllStrings(
                            getConfig(newData),
                            config
                        )
                    }
                ),
                'pdfcontainer'
            );
        }
    }, [setActivePage, doMemberSignup, config]);

    return (
        <div style={{margin: '24px' }}>
            { !selectedPerson && 'Bitte wählen Sie eine Person aus.'}
            <PersonSelect gotoForm={onPersonSelectForm} disableNew onlyTeili/>
            
            { 
                activePage === 1 && 
                <Container maxWidth="md" style={{ marginBottom: '32px', marginTop: '16px' }}>
                    <Paper>
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12}>
                                <CompleteForm 
                                    {...FreizeitpassForm} 
                                    onSubmit={onSubmit} 
                                    defaultValues={selectedPerson}
                                    key={`freizeitpass-form-${selectedPerson.id || 'new'}`}
                                    submitText={'PDF generieren'}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            }
            { 
                activePage === 2 && 
                <Container maxWidth="md" style={{ marginBottom: '32px', marginTop: '16px' }}>
                    <Paper>
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12}><CircularProgress/></Grid>
                        </Grid>
                    </Paper>
                </Container> 
            }
            { 
                activePage === 3 && 
                (selectedPerson && !isEmpty(selectedPerson)) && 
                <div style={{ margin: '16px' }}>
                    <Grid container spacing={3} justify="center">
                        <Grid item>
                            <IconButton 
                                onClick={
                                    () => downloadPdf(
                                        getAsDefinition(
                                            {
                                                content: replaceConfigInAllStrings(
                                                    getConfig(selectedPerson),
                                                    config
                                                )
                                            }
                                        )
                                    )
                                }
                                color="primary" 
                                aria-label="download pdf" 
                                component="span"
                            >
                                <GetApp />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton 
                                onClick={
                                    () => printPDF(
                                        getAsDefinition(
                                            {
                                                content: replaceConfigInAllStrings(
                                                    getConfig(selectedPerson),
                                                    config
                                                )
                                            }
                                        )
                                    )
                                }
                                color="primary" 
                                aria-label="print pdf" 
                                component="span"
                            >
                                <Print />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            }
            
            {
                (activePage === 2  || activePage === 3) && 
                <div id="pdfcontainer" style={{ height: '75vH', width: '95%', margin: 'auto' }}></div>
            }
        </div>
    );
}
