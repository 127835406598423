const getPersonKarteikarte = person => ({
    style: 'karteiKarte',
    stack: [
        {
            alignment: 'justify',
            columns: [
                {
                    width: '50%',
                    text: person.gender,
                    style: person.gender === 'Weiblich' ? 'weiblich' : 'maennlich',
                },
                {
                    width: '50%',
                    text: person.personGroup,
                    style: person.personGroup === 'Mitarbeiter' ? 'mitarbeiterKartei' : 'teiliKartei',
                    alignment: 'right',
                },
            ]
        },
        {
            text: `${person.prename} ${person.lastname}`,
            style: 'header',
        },
        {
            text: `${person.solaAge} Jahre alt`,
        },
        {
            text: person.adressTown,
        },
        {
            text: `Wünsche: ${person.desires || 'keine'}`,
        },
    ],
    unbreakable: true,
});

const getConfig = assignedPersons => ({
    content: [
        {
            alignment: 'justify',
            columns: [
                {
                    columns: [[
                        assignedPersons.filter((_, index) => index % 2 === 0).map(getPersonKarteikarte),
                    ]],
                    width: '50%',
                },
                {
                    columns: [[
                        assignedPersons.filter((_, index) => index % 2 === 1).map(getPersonKarteikarte),
                    ]],
                    width: '50%',
                },
            ]
        },
    ],
    pageMargins: [ 20, 20, 20, 20 ],
});

export default getConfig;