import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabBar({ tabs = [] }) {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="tab bar">
          {tabs.map((tab, index) => <Tab label={tab.label} {...a11yProps(index)} key={`tab-panel-${index}`} />)}
        </Tabs>
      </AppBar>
      {
        tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            <Grid container spacing={2}>
              {tab.presentation}
            </Grid>
          </TabPanel>
        ))
      }
    </div>
  );
}
