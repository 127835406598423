import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import { useLogin } from '../hooks/useLogin';

import Drawer from './drawer/drawer';
import AllSolasProvider from './hooks/useAllSolas';
import AllPersonsProvider from './hooks/useAllPersons';
import UsersProvider from './hooks/useUsers';
import PaymentProvider from './hooks/usePayment';
import RolesProvider from './hooks/useRoles';
import OwnFieldsProvider from './hooks/useOwnFields';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
}));

export default function Authenticated() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const { logout } = useLogin();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <div className={classes.root}>
      <AllSolasProvider>
        <AllPersonsProvider>
          <UsersProvider>
            <PaymentProvider>
              <RolesProvider>
                <OwnFieldsProvider>
                    <CssBaseline />
                    <AppBar
                      position="fixed"
                      className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                      })}
                    >
                      <Toolbar>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          edge="start"
                          className={clsx(classes.menuButton, open && classes.hide)}
                        >
                          <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                          Sommerlager Zieko
                        </Typography>
                        <div className={classes.grow} />
                        <IconButton
                          color="inherit"
                          onClick={logout}
                          edge="end"
                          className={clsx(classes.menuButton)}
                        >
                          <LogoutIcon />
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                    <Drawer handleDrawerClose={handleDrawerClose} open={open}/>
                </OwnFieldsProvider>
              </RolesProvider>
            </PaymentProvider>
          </UsersProvider>
        </AllPersonsProvider>
      </AllSolasProvider>
    </div>
  );
}
