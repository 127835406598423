import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Droppable } from 'react-beautiful-dnd';

import GroupCard from './GroupCard';

export default function Group({ label, assignedPersonIds = [], groupedPersons = [], id, groupLabel, personsToAssign }) {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Droppable 
        droppableId={`${id}`}
      >
        {(provided) => (
          <div 
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <GroupCard
              label={label}
              assignedPersonIds={assignedPersonIds}
              personsToAssign={personsToAssign}
              groupedPersons={groupedPersons}
              placeholder={provided.placeholder}
              personPrefix={`${id}`}
              groupId={id}
              groupLabel={groupLabel}
            />
          </div>
        )}
      </Droppable>
    </Grid>
  );
}
