import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import { DragDropContext } from 'react-beautiful-dnd';

import Group from './Group';

import { useGroups } from '../../../hooks/useGroups';

export default function GroupsContainer({ groups, groupedPersons, groupLabel, confirmedAssignedIds }) {
  const { setGroup } = useGroups();

  const handleDragEnd = useCallback((dropEvent) => {

    const { source, destination } = dropEvent;

    // dropped outside the list
    if (!destination) {
        return;
    }

    if (destination.droppableId !== source.droppableId) {

      // handle destination adding
      if (!destination.droppableId.includes('confirmed')) {
        const fromGroup = groups.find(({ id }) => source.droppableId === `${id}`);
        if (fromGroup) {
          const droppedId = fromGroup.assignedPersonIds[source.index];
          
          const droppableGroup = groups.find(({ id }) => destination.droppableId === `${id}`);
          if (droppableGroup && !droppableGroup.assignedPersonIds.includes(droppedId)) {
            droppableGroup.assignedPersonIds.splice(destination.index, 0, droppedId);
            setGroup(droppableGroup);
          }
        }
      }

      // handle source deletion
      if (!source.droppableId.includes('confirmed')) {
        const droppableGroup = groups.find(({ id }) => source.droppableId === `${id}`);
        if (droppableGroup) {
          droppableGroup.assignedPersonIds.splice(source.index, 1);
          setGroup(droppableGroup);
        }
      }
    } else if (!destination.droppableId.includes('confirmed')) {
        // reorder in same group
        const droppableGroup = groups.find(({ id }) => source.droppableId === `${id}`);
        if (droppableGroup) {
          const temp = droppableGroup.assignedPersonIds[source.index];
          droppableGroup.assignedPersonIds[source.index] = droppableGroup.assignedPersonIds[destination.index];
          droppableGroup.assignedPersonIds[destination.index] = temp;
          setGroup(droppableGroup);
        }
    }
  }, [groups, setGroup]);

  return (
    <DragDropContext 
      onDragEnd={handleDragEnd}
    >
      <Grid container spacing={2}>
        {groups.map((group, index) => (
          <Group 
            {...group} 
            assignedPersonIds={(group.assignedPersonIds || []).filter(assignedId => confirmedAssignedIds.includes(assignedId))}
            key={index} 
            groupedPersons={groupedPersons}
            groupLabel={groupLabel}
          />
        ))}
      </Grid>
    </DragDropContext>
  );
}
