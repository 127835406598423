import React, { useCallback, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm, Controller } from "react-hook-form";

import { useLogin } from '../hooks/useLogin';
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Register() {
  const classes = useStyles();
  const { control, handleSubmit, errors, watch } = useForm();

  const { register, loggedInRole } = useLogin();


  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await register(data);
    setIsLoading(false);
  }, [register, setIsLoading]);

  if (loggedInRole && loggedInRole !== 'user' && loggedInRole !== 'abgelehnt') {
    return <Redirect to={'/admin'} />
  }

  return (
    <div>
      <Container maxWidth="sm">
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Login</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={''}
                  rules={{ required: true }}
                  render={(properties) => (
                    <TextField 
                      label="Benutzername" 
                      variant="outlined" 
                      autoComplete="username"
                      error={Boolean(errors['name'])}
                      {...properties}
                    />
                  )}
                />
                {errors.name && <FormHelperText style={{ textAlign: 'center' }} error>Bitte geben Sie einen Benutzernamen ein.</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="pass"
                  control={control}
                  defaultValue={''}
                  rules={{ required: true }}
                  render={(properties) => (
                    <TextField 
                      label="Passwort" 
                      type="password"
                      variant="outlined" 
                      autoComplete="current-password"
                      error={Boolean(errors['pass'])}
                      {...properties}
                    />
                  )}
                />
                {errors.pass && <FormHelperText style={{ textAlign: 'center' }} error>Bitte geben Sie ein Passwort ein.</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="pass2"
                  control={control}
                  defaultValue={''}
                  rules={{ required: true, validate: (value) => value === watch('pass') }}
                  render={(properties) => (
                    <TextField 
                      label="Passwort" 
                      type="password"
                      variant="outlined" 
                      autoComplete="current-password"
                      error={Boolean(errors['pass2'])}
                      {...properties}
                    />
                  )}
                />
                {errors.pass2 && <FormHelperText style={{ textAlign: 'center' }} error>Die Passwörter stimmen nicht überein</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>Anmelden</Button>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Link to="/login">Account vorhanden? Login</Link>
              </Grid>
              <Grid item xs={12}>
                <Divider/>
              </Grid>
              <Grid item xs={12}>
                <Link to="/">
                  <Button>Start</Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
