import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';

import { useSecret } from './useSecret';
import { useConfig } from './useConfig';
import getBackend from './getBackend';

import formatPersonData from '../utils/formatPersonData';

const MyContext = createContext();
const { Provider } = MyContext;

export const usePersons = () => useContext(MyContext);

export default function PersonsProvider({children}) { 
    const [persons, setPersons] = useState([]);
    const [selectedPerson, setSelectedPerson] = useState(false);
    const { secret, setSecret } = useSecret();
    const { config } = useConfig();

    const { enqueueSnackbar } = useSnackbar();

    const requestPersons = useCallback(
        async (usedSecret) => {
            try {
                const response = await fetch(
                    `${getBackend()}/loadAllFromSecret.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret: usedSecret,
                        })
                    }
                );

                const newPersons = await response.json() || [];
                setPersons(formatPersonData(newPersons, config));
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [enqueueSnackbar, config]
    );

    const doRequestPersons = useCallback(
        async () => {
            if (secret) {
                requestPersons(secret);
            }
        },
        [secret, requestPersons]
    );

    useEffect(() => {
        doRequestPersons();
    }, [doRequestPersons]);

    const doMemberSignup = useCallback(
        async (data) => {
            try {
                setSelectedPerson(data);
                const response = await fetch(
                    `${getBackend()}/anmeldungTeilnehmer.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...data,
                            secret,
                        })
                    }
                );

                const newPersonList = formatPersonData(await response.json(), config) || [];
                setPersons(newPersonList);

                // select last created person
                const newPersonFromList = newPersonList.length ? newPersonList[newPersonList.length - 1] : {};
                const newPerson = newPersonList.length ? (newPersonList.find(person => person.id === data.id) || newPersonFromList) : {}


                if (newPerson.secret) {
                    setSecret(newPerson.secret);
                } 

                if (newPerson.applied) {
                    enqueueSnackbar(data.id ? 'Daten gespeichert' : 'Anmeldung abgeschickt', { variant: 'success' });
                    setSelectedPerson(newPerson);
                    return true;
                }
                return false;
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Anmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
                return false;
            }
        },
        [secret, enqueueSnackbar, setSecret, config]
    );

    const doWorkerSignup = useCallback(
        async (data) => {
            try {
                setSelectedPerson(data);
                const response = await fetch(
                    `${getBackend()}/anmeldungMitarbeiter.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...data,
                            secret,
                        })
                    }
                );

                const newPersonList = formatPersonData(await response.json(), config) || [];
                setPersons(newPersonList);

                // select last created person
                const newPersonFromList = newPersonList.length ? newPersonList[newPersonList.length - 1] : {};
                const newPerson = newPersonList.length ? (newPersonList.find(person => person.id === data.id) || newPersonFromList) : {}

                if (newPerson.secret) {
                    setSecret(newPerson.secret);
                }

                if (newPerson.appliedMitarbeiter) {
                    enqueueSnackbar('Anmeldung abgeschickt', { variant: 'success' });
                    setSelectedPerson(newPerson);
                    return true;
                }
                return false;
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Anmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
                return false;
            }
        },
        [secret, enqueueSnackbar, setSecret, config]
    );

    const removePerson = useCallback(
        async (data) => {
            const response = await fetch(
                `${getBackend()}/deleteFromSecret.php`,
                { 
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...data,
                        secret,
                    })
                }
            );

            const newPersons = formatPersonData(await response.json(), config) || [];
            setPersons(newPersons);
        },
        [secret, config]
    );

    const [teilnehmer, setTeilnehmer] = useState([]);
    useEffect(() => {
        setTeilnehmer(persons.filter(person => get(person, 'solaTeilnahme.length', 0) > 0));
    }, [persons, setTeilnehmer]);
    
    const [mitarbeiter, setMitarbeiter] = useState([]);
    useEffect(() => {
        setMitarbeiter(persons.filter(person => get(person, 'solaMitarbeit.length', 0) > 0));
    }, [persons, setMitarbeiter]);

    const [coords, setCoords] = useState({});
    const getCoords = useCallback(
        async () => {
            const response = await fetch(`${getBackend()}/shared/getCoords.php`);

            const newCoords = await response.json() || [];

            setCoords(newCoords);
        },
        [setCoords]
    );

    useEffect(() => {
        getCoords();
    }, [getCoords]);

    return (
        <Provider 
            value={{
                persons, 
                requestPersons, 
                selectedPerson, 
                setSelectedPerson, 
                doMemberSignup, 
                doWorkerSignup, 
                removePerson, 
                teilnehmer, 
                mitarbeiter,

                coords
            }}
        >
            {children}
        </Provider>
    );
};
