
const getConfig = data => ({
    footer: (currentPage, pageCount) => [
        {
            text: '_________________________________________________________________',
            alignment: 'center',
        },
        {
            text: 'Bankverbindung',
            style: 'fatLabel',
            alignment: 'center',
        },
        {
            text: 'Kontoinhaber: Ev. Hoffnungsgemeinde Zieko',
            style: 'small',
            alignment: 'center',
        },
        {
            text: 'KD-Bank - BLZ: 350 601 90',
            style: 'small',
            alignment: 'center',
        },
        {
            text: 'Konto-Nr.: 156 580 201 8 (Gemeinde)        Konto-Nr.: 156 580 2034 (SOLA)',
            style: 'small',
            alignment: 'center',
        },
        '\n',
        {
            text: 'Dieses Schreiben wurde maschinell erstellt und gilt ohne händische Unterschrift.',
            style: 'small',
            alignment: 'center',
        },
    ],
    header: [],
    content: [
        {
            image: 'gemeindeLogo',
            width: 65,
            absolutePosition: { x: 75, y: 35},
        },
        {
            text: 'Evangelische Hoffnungsgemeinde Zieko',
            style: 'headerTitle',
            alignment: 'center',
        },
        {
            text: 'mit den Dörfern Buko, Buro, Düben, Klieken, Luko und Zieko',
            style: 'small',
            alignment: 'center',
        },
        '\n',
        {
            text: 'OT Zieko \u2022 Dorfstraße 2 \u2022 06869 Coswig (Anhalt)',
            style: 'headerTitle',
            alignment: 'center',
        },
        {
            text: 'Pfarramt: Tel. (034903)62645     Fax (034903)66558',
            style: 'halfSmall',
            alignment: 'center',
        },
        {
            alignment: 'right',
            text: (new Date()).toLocaleDateString(),
        },
        '\n',
        '\n',
        '\n',
        `${data.prename} ${data.lastname}`,
        `${data.adressStreet}`,
        '\n',
        `${data.adressPlz} ${data.adressTown}`,
        '\n',
        '\n',
        '\n',
        '\n',
        {
            text: 'Bestätigung',
            style: 'fat',
        },
        '\n',
        '\n',
        `Hiermit wird bestätigt, dass ${data.prename} ${data.lastname}, geboren am ${data.birthdate ? (new Date(data.birthdate)).toLocaleDateString() : 'GEBUTSDATUM UNBEKANNT'}, für die Ev. Hoffnungsgemeinde Zieko ohne die Zahlung einer Aufwandsentschädigung ehrenamtlich beim Sommerlager Zieko (${data.teensTerminStart ? (new Date(data.teensTerminStart)).toLocaleDateString() : 'TEENS TERMIN UNBEKANNT'} - ${data.kidsTerminEnd ? (new Date(data.kidsTerminEnd)).toLocaleDateString() : 'KIDS TERMIN UNBEKANNT'}) tätig sein wird.`,
        `Für die Ausübung der ehrenamtlichen Tätigkeit wird ein erweitertes Führungszeugnis nach §30a, 31 Bundeszentralregistergesetz benötigt. Die entsprechenden Voraussetzungen liegen vor.`,
        '\n',
        `Wir bitten um Übermittlung an den Antragsteller und beantragen gleichzeitig die Gebührenbefreiung.`,
        '\n',
        '\n',
        '\n',
        'Mit freundlichen Grüßen',
        '\n',
        {
            image: 'unterschrift',
            width: 200,
        },
        '\n',
        'Helma Mühlmann',
        {
            text: 'SOLA-Leitung\nTel.: 015147465888',
            style: 'small',
        },
    ],
    pageMargins: [ 75, 35, 35, 95 ],
});

export default getConfig;