import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

export default function TitleWithLink(props) {
    const { title, description, links } = props;

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={0} style={{ textAlign: 'center' }}>
                        {
                            links.map((link, index) => 
                                <Grid item xs={12} key={index}>
                                    <Button href={link.url} size={link.size} color="secondary">
                                        {link.title || link.url}
                                    </Button>    
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}