import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import FlyerButton from './FlyerButton';
import InfoCards from './cards/InfoCards';
import MitarbeitCards from './cards/MitarbeitCards';
import LinksSection from './links/LinksSection';
import ImageGallery from './ImageGallery';
import InfoIcon from '@material-ui/icons/Info';
import DetailDialog from './cards/DetailDialog';

import { useConfig } from '../../../hooks/useConfig';
import SocialLinks from "./links/SocialLinks";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    height: 500,
    backgroundImage: 'url("./img/wide.jpg")',
    backgroundSize: 'cover',
  },
  overlay: {
    height: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  margin: {
    margin: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  infoBg: {
    backgroundColor: theme.palette.primary.light,
  },
  solaBg: {
    backgroundColor: '#141213',
    color: '#fff',
  }
}));

export default function Login() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState('1');
  const handleSelectedTabChange = useCallback((event, newValue) => setSelectedTab(newValue), [setSelectedTab]);

  const [openReminder, setOpenReminder] = useState(true);
  const closeReminder = useCallback(() => setOpenReminder(false), [setOpenReminder]);

  const { 
    config: {
      popupEnabled,
      popupTitle,
      popupDescription,
      solaPreviewEnabled,
      solaTitle,
      teiliRegistrationEnabled,
      mitarbeiterRegistrationEnabled,
    }
  } = useConfig();

  return (
    <>
      <div className={classes.titlePaper}>
        <div className={classes.overlay}>
          {
            Boolean(popupEnabled)
            && (
              <DetailDialog
                title={
                  <Grid container direction="row" alignItems="center" spacing={2}> 
                    <Grid item>
                      <InfoIcon color="secondary" style={{ verticalAlign: "middle" }}/>
                    </Grid>
                    <Grid item>
                      {popupTitle}
                    </Grid>
                  </Grid>
                }
                description={popupDescription}
                details={<></>}
                open={openReminder}
                handleClose={closeReminder}
              />
            )
          }
          
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">
                Sommerlager Zieko
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.solaBg}>
            <SocialLinks />
          </Grid>
          {
            Boolean(solaPreviewEnabled) 
            ? (
              <>
                <Grid item xs={12} className={classes.solaBg}>
                  <Link to={'sola'}>
                    <FlyerButton/>
                  </Link>
                </Grid>
                <Grid item xs={12} className={classes.solaBg}>
                  <Typography variant="h3">{solaTitle}</Typography>
                  <Button variant="contained" color="primary" href={'#sola'} className={classes.margin}>
                    <InfoIcon className={classes.marginRight}/> Informationen ansehen
                  </Button>
                </Grid>
              </>
            )
            : <></>
          }
          {
            Boolean(teiliRegistrationEnabled) || Boolean(mitarbeiterRegistrationEnabled)
            ? (
              <Grid item xs={12} className={classes.solaBg} style={{ paddingBottom: '16px' }}>
                {
                  Boolean(teiliRegistrationEnabled) 
                  ? (
                    <Button variant="contained" color="primary" href={'#teilnehmer'} className={classes.margin}>
                      Anmeldung Teilnehmer
                    </Button>
                  )
                  : <></>
                }

                {
                  Boolean(mitarbeiterRegistrationEnabled) 
                  ? (
                    <Button variant="contained" color="primary" href={'#mitarbeiter'} className={classes.margin}>
                      Anmeldung Mitarbeiter
                    </Button>
                  )
                  : <></>
                }
              </Grid>
            )
            : <></>
          }
          
          <Grid item xs={12}>
            <TabContext value={selectedTab}>
              <AppBar position="static">
                <TabList onChange={handleSelectedTabChange} aria-label="simple tabs example">
                  <Tab label="Was ist SOLA" value="1" />
                  <Tab label="Bilder" value="2" />
                  <Tab label="Mitarbeit & Unterstützung" value="3" />
                </TabList>
              </AppBar>
              <TabPanel value="1" className={classes.infoBg}><InfoCards/></TabPanel>
              <TabPanel value="2" className={classes.infoBg}><ImageGallery/></TabPanel>
              <TabPanel value="3" className={classes.infoBg}><MitarbeitCards/></TabPanel>
            </TabContext>
          </Grid>
          <Grid item xs={12}>
            <LinksSection/>
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: '#7986cb85', padding: '24px 0' }}>
            <Container>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  SOLA verbindet Menschen, tritt Diskriminierung entgegen und fördert ein inklusives Miteinander
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h5" component="h2">
                  SOLA verbindet Menschen
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Als SOLA sind wir davon überzeugt, dass Jesus Christus Menschen verbindet.
                  <br/>
                  <br/>
                  Im SOLA fördern wir das Miteinander aller Menschen.
                  <br/>
                  <br/>
                  Zusammen setzen wir uns vor Ort dafür ein, wertschätzend und vertrauensvoll miteinander zu leben.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h5" component="h2">
                  SOLA tritt Diskriminierung entgegen
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Wir wissen uns als SOLA der unantastbaren Würde jedes einzelnen Menschen und dem Recht auf freie Persönlichkeitsentfaltung (Art. 1 und 2, GG) verpflichtet. 
                  Theologisch sehen wir diese Würde in der Gottebenbildlichkeit jedes Menschen als Geschöpf Gottes begründet.
                  <br/>
                  <br/>
                  Dies gilt für alle Menschen unabhängig von körperlicher und psychischer Beeinträchtigung, Alter, ethnischer Herkunft und Nationalität, Geschlecht, 
                  sexueller Orientierung, Religion und Weltanschauung und sozio-ökonomischem Status. Deshalb treten wir im SOLA jeglicher Form von Diskriminierung entschlossen entgegen.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h5" component="h2">
                  SOLA fördert ein inklusives Miteinander
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Im SOLA leben und fördern wir eine inklusive Haltung im Rahmen unserer Möglichkeiten und Ressourcen, die allen Menschen eine Teilnahme und Teilhabe am Programm und Auftrag 
                  des SOLA ermöglicht. Dennoch nehmen wir wahr, dass auch im SOLA Menschen Ausgrenzung erfahren. Daher setzen wir uns mit allen Möglichkeiten dafür ein, Ausgrenzung 
                  und Diskriminierung abzubauen und zu beseitigen. Bestehende Wahrnehmungs-, Normierungs- und Deutungsmuster werden gemeinsam reflektiert, damit Zugangs- und 
                  Beteiligungshemmschwellen erkannt, benannt und beseitigt werden können.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h5" component="h2">
                  SOLA ist eine lernende Gemeinschaft
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Wir wissen darum, dass die Verwirklichung eines inklusiven Miteinanders ein stetiger und selbstkritischer Prozess ist. SOLA ist in diesem Sinne immer eine lernende Gemeinschaft. 
                  Als solche sind wir auf einem gemeinsamen Weg, Hemmschwellen abzubauen, Diskriminierung zu beseitigen und Brücken zu bauen - denn im SOLA sind alle willkommen.
                </Typography>
              </Grid>
            </Grid>
          </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
