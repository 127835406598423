import React, { useMemo, useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { useSecret } from './useSecret';
import getBackend from './getBackend';

const MyContext = createContext();
const { Provider } = MyContext;

export const useLogin = () => useContext(MyContext);

const PERMISSIONS = {
    'admin': [
        'archive_sola',
        'anmeldungen_teili',
        'pay_teilnehmer',
        'anmeldungen_mitarbeiter',
        'additional_data_fields',
        'groups',
        'coords',
        'assign_roles',
        'config_popup',
        'config_registrations',
        'systemwide_persons',
        'csvexport',
    ],
    'Gruppenaufteilung': [
        'groups',
    ],
    'Gruppen/Bezahlung': [
        'pay_teilnehmer',
        'groups',
    ],
};

export default function LoginProvider({children}) { 
    const [loggedInRole, setLoggedInRole] = useState(false);
    //const [loggedInRole, setLoggedInRole] = useState('admin');
    const { secret, setSecret } = useSecret();

    const { enqueueSnackbar } = useSnackbar();

    const handleResponse = useCallback(async (response, silent) => {
        const loggedIn = await response.json() || {};

        if (loggedIn.secret) {
            setSecret(loggedIn.secret);
        }
        
        if (loggedIn.role) {
            setLoggedInRole(loggedIn.role);

            if (loggedIn.role === 'user' && !silent) {
                enqueueSnackbar(`Willkommen ${loggedIn.name}. Warten auf Administrator für Berechtigungen.`, { variant: 'success' });
            }
        }
    }, [setSecret, setLoggedInRole, enqueueSnackbar]);

    const login = useCallback(
        async (data) => {
            try {
                const response = await fetch(
                    `${getBackend()}/login.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }
                );

                await(handleResponse(response));
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Login fehlgeschlagen.', { variant: 'error' });
            }
        },
        [enqueueSnackbar, handleResponse]
    );

    const loginWithSecret = useCallback(
        async (usedSecret, silent) => {
            try {
                const response = await fetch(
                    `${getBackend()}/loginWithSecret.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ secret: usedSecret })
                    }
                );

                await(handleResponse(response, true));
            } catch(error) {
                console.log(error);
                if (!silent) {
                    enqueueSnackbar('Automatischer Login fehlgeschlagen.', { variant: 'error' });
                }
            }
        },
        [enqueueSnackbar, handleResponse]
    );
    
    useEffect(() => {
        if (secret) {
            loginWithSecret(secret, true);
        }
    }, [secret, loginWithSecret]);
    
    const register = useCallback(
        async (data) => {
            try {
                const response = await fetch(
                    `${getBackend()}/register.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }
                );

                await(handleResponse(response));
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Registrierung fehlgeschlagen.', { variant: 'error' });
            }
        },
        [enqueueSnackbar, handleResponse]
    );

    const logout = useCallback(
        () => {
            setLoggedInRole(false);
            setSecret(false);
        },
        [setSecret]
    );
    
    const permissions = useMemo(
        () => PERMISSIONS[loggedInRole] || [],
        [loggedInRole],
    );

    return (
        <Provider 
            value={{login, logout, register, loggedInRole, permissions}}
        >
            {children}
        </Provider>
    );
}
