import React, { useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ActionMenu from './ActionMenu';
import TeiliAnmeldebestätigungButton from './TeiliAnmeldebestätigungButton';
import MitarbeiterAnmeldebestätigungButton from './MitarbeiterAnmeldebestätigungButton';
import MitarbeiterBestätigungFührungszeugnisButton from './fuehrungszeugnis/MitarbeiterBestätigungFührungszeugnisButton';

import { useAllPersons } from '../hooks/useAllPersons';
import { getLabeldFields, mitarbeiterFields, teilnehmerFields } from '../../utils/getAllFields';
import { formatAppliedDate } from '../../utils/getAppliedDate';
import formatDate from '../../utils/formatDate';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  avatarW: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatarM: {
    backgroundColor: theme.palette.primary.light,
  },
  textW: {
    color: theme.palette.secondary.light,
    textAlign: 'right',
  },
  textM: {
    color: theme.palette.primary.light,
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
}));

const getSubHeader = ({ display, created, appliedDate, confirmedOn, confirmedOnMitarbeiter }) => {
  if (display === 'mitarbeiter') {
    if (confirmedOnMitarbeiter) {
      return `Bestätigt am ${format(new Date(confirmedOnMitarbeiter), 'dd.MM.yyy')}`
    }
    return `Angemeldet am ${formatAppliedDate({ appliedDate, created })}`
  }

  if (confirmedOn) {
      return `Bestätigt am ${format(new Date(confirmedOn), 'dd.MM.yyy')}`
    }
    return `Angemeldet am ${formatAppliedDate({ appliedDate, created })}`
};

export default function UserCard(props) {
  const { display } = props;
  const classes = useStyles();
  const [openDetails, setOpenDetails] = React.useState(false);

  const toggleDetailsDialog = () => {
    setOpenDetails(!openDetails);
  };

  const { setPersonConfirmed, updatePerson } = useAllPersons();

  const handleAproove = useCallback(() => {
    setPersonConfirmed(props.id, '1', props.display === 'mitarbeiter');
  }, [setPersonConfirmed, props.id, props.display]);

  const doUpdatePerson = useCallback(async (newData) => {
    await updatePerson(newData);
  }, [updatePerson]);

  const bestätigungsButton = display === 'teilis' ? 
    <TeiliAnmeldebestätigungButton
      onClick={handleAproove}
      {...props}  
      email={props['parentEmail']} 
    /> :
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={event => doUpdatePerson({ ...props, isTeenihelfer: event.target.checked })}
              checked={Boolean(props.isTeenihelfer)}
              color="primary"
            />
          }
          label="Ist Teenihelfer"
        />
      </Grid>
      <Grid item xs={12}>
        <MitarbeiterAnmeldebestätigungButton
          onClick={handleAproove}
          {...props}  
          lastName={props.lastname}
        />
      </Grid>
      <Grid item xs={12}>
        <MitarbeiterBestätigungFührungszeugnisButton
          {...props}  
          lastName={props.lastname}
        />
      </Grid>
    </Grid>;

  return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card className={classes.root}>
          <CardHeader
              avatar={
              <Avatar
                aria-label="gender" 
                className={props.gender === 'Weiblich' ? classes.avatarW : classes.avatarM}
              >
                  {props.prename[0]}
              </Avatar>
              }
              action={<ActionMenu {...props}/>}
              title={`${props.prename} ${props.lastname}`}
              subheader={getSubHeader(props)}
          />
          <CardContent>
              <Grid container>
                  <Grid item xs={4} className={classes.textLeft}><Typography variant="caption">Sola: </Typography></Grid>
                  <Grid item xs={8} className={classes.textRight}>{(props.display === 'teilis' ? props.solaTeilnahme : props.solaMitarbeit.join(', '))}</Grid>

                  <Grid item xs={4} className={classes.textLeft}><Typography variant="caption">Geburtsdatum: </Typography></Grid>
                  <Grid item xs={8} className={classes.textRight}>{(new Date(props.birthdate)).toLocaleDateString()}</Grid>

                  <Grid item xs={4} className={classes.textLeft}><Typography variant="caption">Alter (SOLA): </Typography></Grid>
                  <Grid item xs={8} className={classes.textRight}>{props.solaAge}</Grid>
              </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
              onClick={toggleDetailsDialog}
              aria-label="show more"
            >
              <Visibility />
            </IconButton>
          </CardActions>
          <CardActions>
            {bestätigungsButton}
          </CardActions>
        </Card>
        <Dialog onClose={toggleDetailsDialog} open={openDetails}>
          <DialogTitle onClose={toggleDetailsDialog}>Daten der Person</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={3} className={classes.textLeft}>
                  <Typography variant="caption">Anmeldedatum: </Typography>
                </Grid>
                <Grid item xs={6} sm={3} className={classes.textRight}>{formatAppliedDate(props)}</Grid>
                <Grid item xs={6} sm={3} className={classes.textLeft}>
                  <Typography variant="caption">Bestätigt am: </Typography>
                </Grid>
                <Grid item xs={6} sm={3} className={classes.textRight}>{formatDate(props.display === 'mitarbeiter' ? props.confirmedOnMitarbeiter : props.confirmedOn)}</Grid>
                <Grid item xs={12}><hr/></Grid>
                {
                  getLabeldFields(
                    props, 
                    [], 
                    display === 'teilis' ? teilnehmerFields : mitarbeiterFields
                  )
                    .filter(field => field.value !== null && field.value !== undefined && field.value !== '')
                    .map(field => (
                      <>
                        <Grid item xs={12} sm={4} className={classes.textLeft}>
                          <Typography variant="caption">{field.label}: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.textRight}>{field.value}</Grid>
                      </>
                    ))
                }
                <Grid item xs={12} onClick={toggleDetailsDialog}>{bestätigungsButton}</Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={toggleDetailsDialog} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
}
