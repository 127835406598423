import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForever from '@material-ui/icons/DeleteForever';
import ScreenShare from '@material-ui/icons/ScreenShare';
import Edit from '@material-ui/icons/Edit';

import { useGroups } from '../../hooks/useGroups';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ShareGroupButton({ 
  groupId,
  groupLabel,
}) {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);

  const { sharedItems, deleteSharedItem, addSharedItem, setEditingSharedItem } = useGroups();

  const onClose = useCallback(() => setOpen(false),[setOpen]);
  const onOpen = useCallback(() => setOpen(true),[setOpen]);
  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label="share"
      >
        <ScreenShare />
      </IconButton>
      <Dialog 
        open={open} 
        onClose={onClose} 
      >
        <DialogTitle onClose={onClose}>Gruppe veröffentlichen</DialogTitle>
        <DialogContent dividers>
          <List>
            {
              sharedItems
                .filter(item => item.groupId === groupId)
                .map(sharedItem => (
                  <ListItem key={sharedItem.id}>
                    <ListItemIcon>
                      <IconButton 
                        aria-label="edit"
                        onClick={() => setEditingSharedItem({...sharedItem, groupLabel })}
                      >
                        <Edit />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText
                      primary={sharedItem.label}
                      secondary={sharedItem.id}
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={() => deleteSharedItem(sharedItem.id)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
            }
            <TextField 
              label={'Angezeigter Name'}
              fullWidth
              value={value}
              onChange={event => setValue(event.target.value) }
              onBlur={event => setValue(event.target.value)}
              InputProps={{ 
                endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        if(value) {
                          addSharedItem({ 
                            label: value, 
                            id: `${Date.now()}${Math.floor(Math.random() * 1000)}`,
                            groupId,
                            columns: [],
                          });
                          setValue('');
                        }
                      }}
                    >
                      <AddCircleOutline/>
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
