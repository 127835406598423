import { isValidElement } from 'react';

const replaceConfigInAllStrings = (input, config) => {
    
    if (typeof input === 'string') {
        return Object.keys(config).reduce((result, nextKey) => result.replace(`{${nextKey}}`, config[nextKey] || ''), input);
    }

    if (Array.isArray(input)) {
        return input.map(nextInput => replaceConfigInAllStrings(nextInput, config));
    }

    if (isValidElement(input)) {
        return input;
    }

    if (typeof input === 'object') {
        return Object.keys(input).reduce(
            (result, nextKey) => {
                if (nextKey === 'control') {
                    return result;
                };

                return ({ 
                ...result, 
                    [nextKey]: replaceConfigInAllStrings(input[nextKey], config)
                });
            },
            input,
        );
    }

    return input;
};

export default replaceConfigInAllStrings;