import React, { useMemo, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import SelectableDrawerItem from './SelectableDrawerItem';
import { useLogin } from '../../hooks/useLogin';

const useStyles = makeStyles((theme) => ({
  drawerListItemIcon: {
    minWidth: theme.spacing(4),
  },
  selected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: theme.palette.primary.contrastText,
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
  selectedHeader: {
    backgroundColor: theme.palette.primary.light + " !important",
    color: theme.palette.primary.contrastText,
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
  gutters: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
}));

export default function CollapsableDrawerList({ setSelectedPage, selectedPage, listHeader = {}, items = [], defaultOpen = true, }) {
  const classes = useStyles();

  const [open, setOpen] = useState(defaultOpen);

  const handleToggleOpen = useCallback(() => setOpen(!open), [open, setOpen]);

  const { permissions } = useLogin(); 
  const showAtLeastOneItem = useMemo(
    () => items.some(item => (
      permissions.includes(item.pageToSelect)
      || permissions.includes(item.permission)
    )),
    [permissions, items],
  );

  if (!showAtLeastOneItem) {
    return null;
  }

  return (
    <>
      <List>
        <ListItem 
          button 
          classes={{ gutters: classes.gutters, selected: classes.selectedHeader }}
          onClick={handleToggleOpen}
          selected={items.some(item => selectedPage === item.pageToSelect)}
        >
          <ListItemIcon className={classes.drawerListItemIcon}>
            {listHeader.icon}
          </ListItemIcon>
          <ListItemText primary={listHeader.label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          {
            items.map((item, index) => (
              (
                permissions.includes(item.pageToSelect)
                || permissions.includes(item.permission)
              ) && (
                <SelectableDrawerItem 
                  key={`list-item-${index}`} 
                  {...item}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              )
            ))
          }
        </Collapse>
      </List>
      <Divider />
    </>
  );
}
