import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import ActionMenu from './ActionMenu';
import MakeUserRole from './MakeUserRole';

const useStyles = makeStyles((theme) => ({
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
}));

export default function UserCard(props) {
  const classes = useStyles();

  return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card>
          <CardHeader
              avatar={
              <Avatar>
                  {props.name[0]}
              </Avatar>
              }
              action={<ActionMenu {...props}/>}
              title={`${props.name}`}
          />
          <CardContent>
              <Grid container>
                  <Grid container spacing={3}>
                      <Grid item xs={4} className={classes.textLeft}><Typography variant="caption">Secret: </Typography></Grid>
                      <Grid item xs={8} className={classes.textRight}>{props.secret || ''}</Grid>

                      <Grid item xs={12} className={classes.textRight}><MakeUserRole {...props} /></Grid>
                  </Grid>
              </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
}
