import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns'

import { getLabeldFields, completeFields } from '../../../utils/getAllFields';
import { useOwnFields } from '../../hooks/useOwnFields';
import getAppliedDate from '../../../utils/getAppliedDate';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  avatarW: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatarM: {
    backgroundColor: theme.palette.primary.light,
  },
  textW: {
    color: theme.palette.secondary.light,
    textAlign: 'right',
  },
  textM: {
    color: theme.palette.primary.light,
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
}));

const getSubHeader = ({ display, created, appliedDate, confirmedOn, confirmedOnMitarbeiter }) => {
  if (display === 'mitarbeiter') {
    if (confirmedOnMitarbeiter) {
      return `Bestätigt am ${format(new Date(confirmedOnMitarbeiter), 'dd.MM.yyy')}`
    }
    return `Angemeldet am ${format(new Date(getAppliedDate({ appliedDate, created })), 'dd.MM.yyy')}`
  }

  if (confirmedOn) {
      return `Bestätigt am ${format(new Date(confirmedOn), 'dd.MM.yyy')}`
    }
    return `Angemeldet am ${format(new Date(getAppliedDate({ appliedDate, created })), 'dd.MM.yyy')}`
};

export default function UserCard(props) {
  const classes = useStyles();

  const { ownFields, updatePersonOwnField } = useOwnFields();

  const [openDetails, setOpenDetails] = useState(false);
  const toggleDetailsDialog = () => {
    setOpenDetails(!openDetails);
  };

  const [changedData, setChangedData] = useState(props);
  const setOwnData = useCallback((fieldId, value) => {
    setChangedData({ ...changedData, [fieldId]: value });
  }, [changedData, setChangedData]);

  useEffect(() => setChangedData(props), [props, setChangedData]);

  const updateFields = useCallback(() => {
    updatePersonOwnField(changedData);
  }, [updatePersonOwnField, changedData]) 

  return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card className={classes.root}>
          <CardHeader
              avatar={
              <Avatar
                aria-label="gender" 
                className={props.gender === 'Weiblich' ? classes.avatarW : classes.avatarM}
              >
                  {props.prename[0]}
              </Avatar>
              }
              title={`${props.prename} ${props.lastname}`}
              subheader={getSubHeader(props)}
              action={
                <IconButton
                  onClick={toggleDetailsDialog}
                  aria-label="show more"
                >
                  <Visibility />
                </IconButton>
              }
          />
          <CardContent>
              <Grid container spacing={2}>
                  <Grid item xs={4} className={classes.textLeft}><Typography variant="caption">Alter (SOLA): </Typography></Grid>
                  <Grid item xs={8} className={classes.textRight}>{props.solaAge}</Grid>
                  {
                    ownFields.map(({ label, fieldId }) => (
                      <Grid key={`${props.id}-${fieldId}`} item xs={12} className={classes.textRight}>
                        <TextField 
                          defaultValue={props[fieldId]}
                          label={label}
                          fullWidth
                          onChange={event => setOwnData(fieldId, event.target.value)}
                          onBlur={updateFields}
                        />
                      </Grid>
                    ))
                  }
              </Grid>
          </CardContent>
        </Card>
        <Dialog onClose={toggleDetailsDialog} open={openDetails}>
          <DialogTitle onClose={toggleDetailsDialog}>Daten der Person</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
                {
                  getLabeldFields(
                    props, 
                    [], 
                    completeFields
                  )
                    .filter(field => field.value !== null && field.value !== undefined && field.value !== '')
                    .map(field => (
                      <>
                        <Grid item xs={12} sm={4} className={classes.textLeft}>
                          <Typography variant="caption">{field.label}: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.textRight}>{field.value}</Grid>
                      </>
                    ))
                }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={toggleDetailsDialog} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
}
