import React from "react";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { Controller } from "react-hook-form";
import Typography from '@material-ui/core/Typography';

const ControlledMultiSelect = ({ 
  name, 
  defaultValue, 
  label, 
  required, 
  error,
  options,
  control,
  preLabel,
  postLabel,
}) => (
  <>
    { preLabel && <p>{preLabel}</p> }
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      rules={{ 
        validate: (value) => !required || (Boolean(value) && value.length > 0),
      }}
      render={(properties) => (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel id={`${name}-label`}>
            {`${label}${required ? ' *' : ''}`}
          </InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            label={`${label}${required ? ' *' : ''}`}
            multiple
            {...properties}
            MenuProps={{
                getContentAnchorEl: () => null,
            }}
            renderValue={(selected) => selected.join(', ')}
            error={error}
          >
            {
              options.map(option => (
                <MenuItem value={option.value ? option.value : option} key={option.value ? option.value : option} style={{ whiteSpace: 'break-spaces' }}>
                  <Checkbox checked={Boolean(properties.value && properties.value.includes(option.value ? option.value : option))} />
                  <ListItemText primary={option.label ? option.label : option} />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      )}
    />
    { postLabel && <Typography variant="body1">{postLabel}</Typography> }
  </>
);

export default ControlledMultiSelect;
