import React, { useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import sortBy from 'lodash/sortBy';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import OpenWith from '@material-ui/icons/OpenWith';
import DeleteIcon from '@material-ui/icons/Delete';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useGroups } from '../../../hooks/useGroups';
import { useAllPersons } from '../../../hooks/useAllPersons';

import AutoCompleteField from '../AutoCompleteField';
import { getPersonStatus, getGroupedPersons } from '../GroupContainer';
import SharedItemTableWrapper from './SharedItemTableWrapper';
import CopyList from '../CopyList';

export default function EditSharedItem() {
  const { allPersons } = useAllPersons();
  const { groups, editingSharedItem, setEditingSharedItem, setSharedItem } = useGroups();

  const onAddField = useCallback(
    (newColumn) => {
      if (newColumn) {
        setEditingSharedItem({
          ...editingSharedItem,
          columns: [
            ...editingSharedItem.columns || [],
            newColumn,
          ]
        });
      }
    },
    [editingSharedItem, setEditingSharedItem]
  );

  const groupedPersons = useMemo(() => 
    sortBy(
      getGroupedPersons(
        allPersons,
        editingSharedItem?.groupId === 'teensconfirmed' ? 'Teens' : 'Kids',
      ),
      'personGroup'
    )
  , [allPersons, editingSharedItem]);

  const displayedGroup = useMemo(
    () => groups.find(group => group.id === editingSharedItem.groupId) || {},
    [editingSharedItem, groups]
  );

  const displayedPersons = useMemo(
    () => {
      if (editingSharedItem.groupId === 'teensconfirmed') {
        return groupedPersons.filter(person => 
          (person.confirmed && person.solaTeilnahme === 'Teens')
          || (person.confirmedMitarbeiter && person.solaMitarbeit.includes('Teens'))
        );
      }

      if (editingSharedItem.groupId === 'kidsconfirmed') {
        return groupedPersons.filter(person => 
          (person.confirmed && person.solaTeilnahme === 'Kids')
          || (person.confirmedMitarbeiter && person.solaMitarbeit.includes('Kids'))
        );
      }
      
      if (!displayedGroup.assignedPersonIds) {
        return [];
      }

      return displayedGroup.assignedPersonIds
        .map(personId => groupedPersons.find(person => person.id === personId))
        .filter(person => Boolean(person));
    },
    [editingSharedItem, displayedGroup, groupedPersons]
  );

  const handleDragEnd = useCallback((dropEvent) => {
    const { source, destination } = dropEvent;

    // dropped outside the list
    if (!destination) {
        return;
    }
    
    const newColumns = Array.from(editingSharedItem.columns);
    const [removed] = newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, removed);

    setEditingSharedItem({
      ...editingSharedItem,
      columns: newColumns
    });
  }, [editingSharedItem, setEditingSharedItem]);

  const handleSave = useCallback(() => {
    setSharedItem(editingSharedItem);
  }, [setSharedItem, editingSharedItem]);

  const onDelete = useCallback((index) => {
    const newColumns = Array.from(editingSharedItem.columns);
    newColumns.splice(index, 1);
    setEditingSharedItem({ ...editingSharedItem, columns: newColumns });
  }, [setEditingSharedItem, editingSharedItem]);

  const toggleTeilnahme = useCallback(() => {
    setEditingSharedItem({ ...editingSharedItem, teilnahme: !editingSharedItem.teilnahme });
  }, [editingSharedItem, setEditingSharedItem]);

  const toggleMitarbeit = useCallback(() => {
    setEditingSharedItem({ ...editingSharedItem, mitarbeit: !editingSharedItem.mitarbeit });
  }, [editingSharedItem, setEditingSharedItem]);

  return (
    <Grid container spacing={3} style={{ maxWidth: 'calc(100vw - 265px)' }}>
      <Grid item xs={3}>
        <Button onClick={() => setEditingSharedItem(false)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4">{editingSharedItem.label}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Speichern
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CopyList
          title={'Öffentlicher Link'}
          list={[`https://www.solazieko.de/#/sharedgroup?sharedsecret=${editingSharedItem.id}`]}
          id={'copySharedItemLink'}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteField
          key={editingSharedItem.columns.length}
          shownField=""
          setShownField={onAddField}
          label="Spalte hinzufügen"
          hiddenFields={editingSharedItem.columns.map(column => column.key)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Spalten Reihenfolge:</Typography>
        <DragDropContext 
          onDragEnd={handleDragEnd}
        >
          <Droppable droppableId={`sharedItemList`}>
            {(provided, snapshot) => (
              <div 
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ 
                  backgroundColor: snapshot.isDraggingOver ? '#7986cb' : '#e4e4e4',
                  borderRadius: '8px',
                }}
              >
                <List component="ul" aria-label="main mailbox folders">
                  {
                    editingSharedItem.columns.map((column, index) => (
                      <Draggable draggableId={column.key} key={column.key} index={index}>
                        {(providedDrag) => (
                          <div 
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                          >
                            <ListItem>
                              <ListItemIcon>
                                <OpenWith />
                              </ListItemIcon>
                              <ListItemText primary={column.label} />
                              <ListItemSecondaryAction>
                                <IconButton 
                                  edge="end" 
                                  aria-label="delete"
                                  onClick={() => onDelete(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                </List>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={editingSharedItem.teilnahme}
                onChange={toggleTeilnahme}
                name="teilnahme"
                color="primary"
              />
            }
            label="Teilnehmer"
          />
          <FormControlLabel
            control={
              <Switch
                checked={editingSharedItem.mitarbeit}
                onChange={toggleMitarbeit}
                name="mitarbeit"
                color="primary"
              />
            }
            label="Mitarbeiter"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Vorschau:</Typography>
      </Grid>
      <SharedItemTableWrapper
        sharedItem={editingSharedItem}
        groupedPersons={displayedPersons}
      />
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button color="primary" variant="contained" onClick={handleSave}>
          Speichern
        </Button>
      </Grid>
    </Grid>
  );
}
