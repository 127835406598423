import React, { useState, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RotateLeft from '@material-ui/icons/RotateLeft';

import { useUsers } from '../hooks/useUsers';

export default function ActionMenu(user) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { setUserRole } = useUsers();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleAproove = useCallback(() =>{
    setUserRole(user.name, 'admin');
    handleClose();
  }, [handleClose, user, setUserRole]);

  const handleDeny = useCallback(() =>{
    setUserRole(user.name, 'abgelehnt');
    handleClose();
  }, [handleClose, user, setUserRole]);

  const handleReset = useCallback(() =>{
    setUserRole(user.name, 'user');
    handleClose();
  }, [handleClose, user, setUserRole]);

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { 
            (user.role !== 'admin') &&
            <MenuItem onClick={handleAproove}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <CheckCircleOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Zum Admin machen"/>
            </MenuItem>
        }
        { 
            (user.role !== 'abgelehnt') &&
            <MenuItem onClick={handleDeny}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <HighlightOff fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Ablehnen" />
            </MenuItem>
        }
        { 
            (user.role !== 'user') &&
            <MenuItem onClick={handleReset}>
                <ListItemIcon style={{ minWidth: '28px' }}>
                    <RotateLeft fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Status zurücksetzen" />
            </MenuItem>
        }
      </Menu>
    </div>
  );
}
