import React, { useMemo, useCallback, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Visibility from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import GetApp from '@material-ui/icons/GetApp';
import Print from '@material-ui/icons/Print';
import DeleteForever from '@material-ui/icons/DeleteForever';

import find from 'lodash/find';

import CopyList from '../CopyList';
import ShareGroupButton from '../ShareGroupButton';
import GroupPerson from './GroupPerson';
import { useGroups } from '../../../hooks/useGroups';
import { downloadPdf, printPDF, getAsDefinition } from '../../../../utils/pdfMake';
import getPersonKarteikartenConfig from './getPersonKarteikartenConfig';
import CSVExportButton from '../../../drawer/csvExportButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function GroupCard(props) {
  const { 
    label, 
    groupedPersons = [], 
    personsToAssign = [], 
    assignedPersonIds = [], 
    placeholder, 
    personPrefix,
    groupId,
    groupLabel,
  } = props;
  const classes = useStyles();

  const assignedPersons = useMemo(
    () => assignedPersonIds
      .map(id => find(groupedPersons, person => person.id === id))
      .filter(person => Boolean(person)), 
    [assignedPersonIds, groupedPersons]
  );

  const assignedPersonsWithPersonGroup = useMemo(
    () => {
      const personGroups = {
        'Mitarbeiter': [],
        'Teilnehmer': [],
      };

      assignedPersons.forEach((person, index) => {
        if (!personGroups[person.personGroup]) {
          personGroups[person.personGroup] = [];
        }

        personGroups[person.personGroup].push({ ...person, index, alreadyAssigned: !Boolean(personsToAssign?.find(({ id }) => id === person.id)) });
      });

      return Object.keys(personGroups).reduce((lastReturn, nextKey) => {
        lastReturn[nextKey] = lastReturn[nextKey].sort((personA, personB) => {
          if (personA.alreadyAssigned && !personB.alreadyAssigned) {
            return 1;
          }
          if (!personA.alreadyAssigned && personB.alreadyAssigned) {
            return -1;
          }

          return 0;
        });

        return lastReturn;
      }, personGroups);
    }, 
    [assignedPersons, personsToAssign]
  );

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [confirmDelete, setConformDelete] = useState(false);

  const openDetails = useCallback(() => setDetailsOpen(true), [setDetailsOpen]);
  const closeDetails = useCallback(() => setDetailsOpen(false), [setDetailsOpen]);

  const openConfirmDelete = useCallback(() => setConformDelete(true), [setConformDelete]);
  const closeConfirmDelete = useCallback(() => setConformDelete(false), [setConformDelete]);
  
  const { deleteGroup } = useGroups();

  const handleConfirmDelete = useCallback(() => {
    deleteGroup(groupId);
    closeConfirmDelete();
  }, [groupId, deleteGroup, closeConfirmDelete]);

  return (
    <Card className={classes.root}>
      <CardHeader
          title={label}
          action={
            <>
              <ShareGroupButton groupId={groupId} groupLabel={groupLabel}/>
              <IconButton
                onClick={openDetails}
                aria-label="show more"
              >
                <Visibility />
              </IconButton>
              <IconButton
                onClick={openConfirmDelete}
                aria-label="delete"
              >
                <DeleteForever />
              </IconButton>
            </>
          }
      />
      <CardContent>
        <Grid container spacing={2}>
          {
            Object.keys(assignedPersonsWithPersonGroup).map(personGroup => (
              <>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {personGroup}
                    </Grid>
                    {
                      assignedPersonsWithPersonGroup[personGroup].map((person, index) => (
                        <GroupPerson {...person} personPrefix={personPrefix} key={index} />
                      ))
                    }
                    {placeholder}
                  </Grid>
                </Grid>
              </>
            ))
          }
        </Grid>
      </CardContent>
      <Dialog 
        open={detailsOpen} 
        onClose={closeDetails} 
      >
        <DialogTitle onClose={closeDetails}>Details</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CSVExportButton persons={assignedPersons} title={'CSV Export'}/>
            </Grid>
            <Grid item xs={12}>
              <h4>Karteikarten</h4>
              <Grid container spacing={2}>
                <Grid item>
                    <IconButton 
                        onClick={
                            () => downloadPdf(
                                getAsDefinition(
                                  getPersonKarteikartenConfig(assignedPersons)
                                )
                            )
                        }
                        color="primary" 
                        aria-label="download pdf" 
                        component="span"
                    >
                        <GetApp />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton 
                        onClick={
                            () => printPDF(
                                getAsDefinition(
                                  getPersonKarteikartenConfig(assignedPersons)
                                )
                            )
                        }
                        color="primary" 
                        aria-label="print pdf" 
                        component="span"
                    >
                        <Print />
                    </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CopyList
                id={'allParentEmails'}
                title={'Eltern Emails'}
                list={
                  assignedPersons
                    .map(person => person.parentEmail)
                    .filter(email => Boolean(email))
                }
              />
            </Grid>
            {
              Object.keys(assignedPersonsWithPersonGroup).map((personGroup, index) => 
                <Grid item xs={12}>
                  <Divider/>
                  <CopyList
                    id={`ParentEmails-${index}`}
                    title={'Eltern Emails ' + personGroup}
                    list={
                      assignedPersonsWithPersonGroup[personGroup]
                            .map(person => person.parentEmail)
                            .filter(email => Boolean(email))
                    }
                  />
                </Grid>
              )
            }
            <Grid item xs={12}>
              <Divider/>
              <CopyList
                id={'allEmails'}
                title={'Eigene Emails'}
                list={
                  assignedPersons
                    .map(person => person.email)
                    .filter(email => Boolean(email))
                }
              />
            </Grid>
            {
              Object.keys(assignedPersonsWithPersonGroup).map((personGroup, index) => 
                <Grid item xs={12}>
                  <Divider/>
                  <CopyList
                    id={`emails-${index}`}
                    title={'Eigene Emails ' + personGroup}
                    list={
                      assignedPersonsWithPersonGroup[personGroup]
                            .map(person => person.email)
                            .filter(email => Boolean(email))
                    }
                  />
                </Grid>
              )
            }
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog 
        open={confirmDelete} 
        onClose={closeConfirmDelete} 
      >
        <DialogTitle onClose={closeConfirmDelete}>Löschen bestätigen</DialogTitle>
        <DialogContent dividers>
          Soll die Gruppe wirklich gelöscht werden?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
