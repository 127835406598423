import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { format } from 'date-fns';

import { usePayment } from '../hooks/usePayment';
import { useAllPersons } from '../hooks/useAllPersons';
import FormStep from '../../forms/components/FormStep';
import HasToPayForm from './HasToPayForm';
import getAppliedDate from '../../utils/getAppliedDate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  avatarW: {
    backgroundColor: theme.palette.secondary.light,
  },
  avatarM: {
    backgroundColor: theme.palette.primary.light,
  },
  textW: {
    color: theme.palette.secondary.light,
    textAlign: 'right',
  },
  textM: {
    color: theme.palette.primary.light,
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  divider: {
    margin: theme.spacing(1,0),
  }
}));

const getSubHeader = ({ payments = [] }) => {
  if (payments.length) {
    return `Letzte Bezahlung ${format(new Date(payments[payments.length -1].date), 'dd.MM.yyy')}`
  }

  return '';
};

const getPaymentText = payment => `${payment.type || ''} ${payment.notice ? `(${payment.notice})` : ''}`;

export default function PaymentCard(props) {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { addPayment, removePayment } = usePayment();
  const { requestAllPersons } = useAllPersons();

  useEffect(() => {
    setEditing(false);
  }, [props.id, setEditing]);

  const handleEditing = useCallback(() => setEditing(true), [setEditing]);
  const handleStopEditing = useCallback(() => setEditing(false), [setEditing]);

  const handleStopDeleting = useCallback(() => setDeleting(false), [setDeleting]);

  const handleAddPayment = useCallback(async (data) => {
    await addPayment({ personId: props.id, ...data });
    await requestAllPersons();
  }, [addPayment, props, requestAllPersons]);

  const handleRemovePayment = useCallback(async () => {
    await removePayment(deleting);
    handleStopDeleting();
    await requestAllPersons();
  }, [removePayment, deleting, handleStopDeleting, requestAllPersons]);

  return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card className={classes.root}>
          <CardHeader
              avatar={
              <Avatar
                aria-label="gender" 
                className={props.gender === 'Weiblich' ? classes.avatarW : classes.avatarM}
              >
                  {(props.prename || '')[0]}
              </Avatar>
              }
              action={
                <IconButton
                  onClick={handleEditing}
                  aria-label="edit"
                >
                  <Edit />
                </IconButton>
              }
              title={`${props.prename} ${props.lastname}`}
              subheader={getSubHeader(props)}
          />
          <CardContent>
              <Grid container>
                  <Grid item xs={6} className={classes.textLeft}><Typography variant="caption">Festgelegter TN-Betrag: </Typography></Grid>
                  <Grid item xs={6} className={classes.textRight}>{props.hasToPay} &euro;</Grid>

                  <Grid item xs={6} className={classes.textLeft}><Typography variant="caption">Bereits bezahlt: </Typography></Grid>
                  <Grid item xs={6} className={classes.textRight}>{props.paid} &euro;</Grid>

                  <Grid item xs={6} className={classes.textLeft}><Typography variant="caption">Übertrag: </Typography></Grid>
                  <Grid item xs={6} className={classes.textRight}>{props.paySum} &euro;</Grid>

                  <Grid item xs={12} className={classes.divider}><Divider/></Grid>

                  <Grid item xs={6} className={classes.textLeft}><Typography variant="caption">Anmeldedatum: </Typography></Grid>
                  <Grid item xs={6} className={classes.textRight}>{format(new Date(getAppliedDate(props)), 'dd.MM.yyy')}</Grid>

                  <Grid item xs={6} className={classes.textLeft}><Typography variant="caption">Bestätigt am: </Typography></Grid>
                  <Grid item xs={6} className={classes.textRight}>{format(new Date(props.confirmedOn), 'dd.MM.yyy')}</Grid>
              </Grid>
          </CardContent>
        </Card>
        <Dialog 
          open={editing} 
          onClose={handleStopEditing} 
          aria-labelledby={`edit-payment-dialog-${props.id}`}
          scroll={'paper'}
          maxWidth={'lg'}
        >
          <DialogTitle id={`edit-payment-dialog-${props.id}`}>Zahlungen</DialogTitle>
          <DialogContent dividers>
            <HasToPayForm {...props}/>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} sm={10} md={8}>
                <Divider/>
              </Grid>
              <Grid item xs={12} sm={10} md={8}>
                <List>
                  {
                    props.payments.map((payment) => (
                      <ListItem disableGutters key={payment.id}>
                        <ListItemAvatar>
                          {payment.amount} &euro;
                        </ListItemAvatar>
                        <ListItemText primary={getPaymentText(payment)} secondary={payment.date ? format(new Date(payment.date), 'dd.MM.yyy') : 'Datum unbekannt'} />
                        <ListItemIcon>
                          <IconButton onClick={() => setDeleting(payment.id)}>
                            <DeleteOutline/>
                          </IconButton>
                        </ListItemIcon>
                      </ListItem>
                    ))
                  }
                </List>
              </Grid>
              <Grid item xs={12} sm={10} md={8}>
                <Divider/>
              </Grid>
            </Grid>
            <FormStep
              handleNext={handleAddPayment}
              nextText={'Hinzufügen'}
              hideBack
              defaultValues={{ date: new Date(), type: 'Anzahlung' }}
              fields={[
                {
                  name: 'amount',
                  label: 'Menge',
                  type: 'number',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        &euro;
                      </InputAdornment>
                    ),
                  },
                  sm: 4,
                },
                {
                  name: 'type',
                  label: 'Typ',
                  sm: 4,
                },
                {
                  name: 'date',
                  label: 'Datum',
                  presentation: 'DatePicker',
                  sm: 4,
                },
                {
                  name: 'notice',
                  label: 'Notizen',
                  multiline: true,
                  rows: 2,
                },
              ]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleStopEditing} color="primary" variant="contained">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog 
          open={Boolean(deleting)} 
          onClose={handleStopDeleting} 
          aria-labelledby={`delete-payment-dialog-${props.id}`}
        >
          <DialogTitle id={`delete-payment-dialog-${props.id}`}>Wirklich entfernen?</DialogTitle>
          <DialogActions>
            <Button onClick={handleStopDeleting}>
              Abbrechen
            </Button>
            <Button onClick={handleRemovePayment} color="primary" variant="contained">
              Bestätigen
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
}
