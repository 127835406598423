import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import UnAuthenticated from './unauthenticated';
import Authenticated from './authenticated';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import localization from "date-fns/locale/de";

import { useLogin } from './hooks/useLogin';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none"
    }
  }
}, deDE);

function App() {
  const { loggedInRole } = useLogin();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localization}>
            <Router>
              <Switch>
                { 
                  (loggedInRole && loggedInRole !== 'user' && loggedInRole !== 'abgelehnt')
                  && (
                    <Route path="/admin">
                      <Authenticated/>
                    </Route>
                  )
                }
                <Route path="/">
                  <UnAuthenticated />
                </Route>
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
