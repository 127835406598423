import React from 'react';
import Grid from '@material-ui/core/Grid';

import { useGroups } from '../../hooks/useGroups';

import AutoCompleteField from './AutoCompleteField';


export default function PickShownField() {
  const { shownField, setShownField } = useGroups();

  return (
    <Grid container>
      <Grid item xs={12}>
        <AutoCompleteField shownField={shownField} setShownField={setShownField}/>
      </Grid>
    </Grid>
  );
}
