import React, { useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import { useUsers } from '../hooks/useUsers';
import { useRoles } from '../hooks/useRoles';

export default function MakeUserRole(user) {
  const { setUserRole } = useUsers();
  const { roles } = useRoles();

  const handleChange = useCallback((event) =>{
    setUserRole(user.name, event.target.value);
  }, [user, setUserRole]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`uers-role-${user.name}-label`}>
        Rolle
      </InputLabel>
      <Select
        labelId={`uers-role-${user.name}-label`}
        id={`uers-role-${user.name}`}
        label={`Rolle`}
        MenuProps={{
            getContentAnchorEl: () => null,
        }}
        renderValue={name => name}
        onChange={handleChange}
        value={user.role}
      >
        {
          roles.concat([{ name: 'abgelehnt' }]).map(({ name }) => (
            <MenuItem value={name} key={name} style={{ whiteSpace: 'break-spaces' }}>
              <Checkbox checked={user.role === name} />
              <ListItemText primary={name} />
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}
