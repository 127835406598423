import React, { useCallback } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import copyToClipboard from '../../../utils//copyToClipboard';
import { useSnackbar } from 'notistack';

export default function CopyList({ title, list, id, separator = ',' }) {
  const { enqueueSnackbar } = useSnackbar();
  const copyId = `copy-${id}`;
  const copyHTML = useCallback(() => {
    copyToClipboard(copyId);
    enqueueSnackbar('Erfolgreich kopiert', { variant: 'success' });
  }, [copyId, enqueueSnackbar]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <b>{title}</b>
      </Grid>
      <Grid item xs={10}>
        <div id={copyId} style={{ wordBreak: 'break-all' }}>
          {list.join(separator)}
        </div>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          color="primary"
          onClick={copyHTML}
        >
          <FileCopy />
        </IconButton>
      </Grid>
    </Grid>
  );
}
