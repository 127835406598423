import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { useSecret } from './useSecret';
import getBackend from './getBackend';

const MyContext = createContext();
const { Provider } = MyContext;

const DEFAULT_CONFIG = {
    kidsPrice: 130,
    teensPrice: 145,
};

export const useConfig = () => useContext(MyContext);

const getDateRangeString = (start, end) => `${start.getDate()}.${start.getMonth() + 1} - ${end.getDate()}.${end.getMonth() + 1}.${end.getFullYear()}`;

export default function ConfigProvider({children}) { 
    const [config, setConfig] = useState({});
    const { secret } = useSecret();

    const { enqueueSnackbar } = useSnackbar();

    const handleConfigResponse = useCallback(async (response) => {
        const newConfig = await response.json() || {};

        setConfig({ 
            ...DEFAULT_CONFIG,
            ...newConfig,
            solaKidsTermin: getDateRangeString(new Date(newConfig.kidsTerminStart), new Date(newConfig.kidsTerminEnd)),
            solaTeensTermin: getDateRangeString( new Date(newConfig.teensTerminStart), new Date(newConfig.teensTerminEnd)),
        });
    }, [setConfig]);

    const requestConfig = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/getConfig.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                        })
                    }
                );

                handleConfigResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, enqueueSnackbar, handleConfigResponse]
    );

    useEffect(() => {
        requestConfig();
    }, [secret, requestConfig]);
    
    const setBackendConfig = useCallback(
        async (name, value) => {
            setConfig({ ...config, [name]: value });

            try {
                const response = await fetch(
                    `${getBackend()}/admin/config/setConfig.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            name,
                            value,
                        })
                    }
                );

                handleConfigResponse(response);
                enqueueSnackbar('Konfiguration gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, setConfig, config, enqueueSnackbar, handleConfigResponse]
    );
    

    return (
        <Provider 
            value={{
                config, 
                requestConfig, 
                setBackendConfig, 
            }}
        >
            {children}
        </Provider>
    );
};
