import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useSecret } from '../../hooks/useSecret';
import formatPersonData from '../../utils/formatPersonData';
import getBackend from '../../hooks/getBackend';
import { useAllSolas } from './useAllSolas';
import { useConfig } from '../../hooks/useConfig';

const MyContext = createContext();
const { Provider } = MyContext;

export const usePayment = () => useContext(MyContext);

export default function PaymentProvider({children}) { 
    const [payments, setPayments] = useState([]);
    const { secret } = useSecret();
    const { prefix } = useAllSolas();
    const { config } = useConfig();

    const { enqueueSnackbar } = useSnackbar();

    const handlePaymentResponse = useCallback(async (response) => {
        const newPayments = await response.json() || [];
        setPayments(formatPersonData(newPayments, config));
    }, [setPayments, config]);

    const requestPayments = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/payments/getPayments.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handlePaymentResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePaymentResponse]
    );

    useEffect(() => {
        if (secret) {
            requestPayments();
        }
    }, [secret, requestPayments]);
    
    
    const addPayment = useCallback(
        async (data) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/payments/addPayment.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...data,
                            secret,
                            prefix,
                        })
                    }
                );

                handlePaymentResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePaymentResponse]
    );
    
    const removePayment = useCallback(
        async (id) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/payments/removePayment.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            id,
                            secret,
                            prefix,
                        })
                    }
                );

                handlePaymentResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePaymentResponse]
    );

    return (
        <Provider 
            value={{
                payments, 
                requestPayments, 
                addPayment,
                removePayment,
            }}
        >
            {children}
        </Provider>
    );
};
