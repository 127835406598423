import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useSecret } from '../../hooks/useSecret';
import { useAllPersons } from '../hooks/useAllPersons';
import getBackend from '../../hooks/getBackend';
import { useAllSolas } from './useAllSolas';

const MyContext = createContext();
const { Provider } = MyContext;

export const useOwnFields = () => useContext(MyContext);

export default function OwnFieldsProvider({children}) { 
    const [ownFields, setOwnFields] = useState([]);
    const { secret } = useSecret();
    const { prefix } = useAllSolas();
    const { handlePersonsResponse } = useAllPersons();

    const { enqueueSnackbar } = useSnackbar();

    const handleRolesResponse = useCallback(async (response) => {
        const newRoles = await response.json() || [];
        setOwnFields(newRoles);
    }, [setOwnFields]);

    const getOwnFields = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/ownfields/getOwnFields.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );

    useEffect(() => {
        if (secret) {
            getOwnFields();
        }
    }, [secret, getOwnFields]);
    
    
    const addOwnField = useCallback(
        async (label) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/ownfields/addOwnField.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            label,
                            prefix,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );
    
    const removeOwnField = useCallback(
        async (fieldId) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/ownfields/removeOwnField.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            fieldId,
                        })
                    }
                );

                handleRolesResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleRolesResponse]
    );
    
    const updatePersonOwnField = useCallback(
        async (newData) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/ownfields/updatePersonOwnField.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...newData,
                            personId: newData.id,
                            secret,
                            prefix,
                        })
                    }
                );

                handlePersonsResponse(response);
                enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );

    const [coords, setCoords] = useState({});
    const getCoords = useCallback(
        async () => {
            try {
                const response = await fetch(`${getBackend()}/shared/getCoords.php`);

                const newCoords = await response.json() || [];
                setCoords(newCoords);
            } catch(err) {
                console.log(err);
            }
        },
        [setCoords]
    );

    const updateCoord = useCallback(
        async (coord) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/coords/updateCoord.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...coord,
                            secret,
                            prefix,
                        })
                    }
                );

                const newCoords = await response.json() || [];
                setCoords(newCoords);

                enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [setCoords, prefix, enqueueSnackbar, secret]
    );

    useEffect(() => {
        getCoords();
    }, [getCoords]);

    return (
        <Provider 
            value={{
                ownFields, 
                setOwnFields, 
                getOwnFields,
                addOwnField,
                removeOwnField,
                updatePersonOwnField,

                coords,
                updateCoord,
            }}
        >
            {children}
        </Provider>
    );
};
