import React, { useCallback, useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { useAllSolas } from '../hooks/useAllSolas';
import { useLogin } from '../../hooks/useLogin';

const renderValue = selected => {
  if (Boolean(selected)) return selected;
  return 'Aktuelle Daten'
};

const newArchiveYear = (new Date()).getFullYear().toString();

export default function SOLASelection() {
  const { databaseSelection, setDatabaseSelection, allSolas, archiveCurrentSola } = useAllSolas();
  const { permissions } = useLogin();

  const setValue = useCallback(
    (event) => {
      const newValue = event.target.value;
      if (newValue === 'newSola') { // TODO: Modal dazwischenschalten!
        archiveCurrentSola(newArchiveYear);
        setDatabaseSelection(newArchiveYear);
      } else {
        setDatabaseSelection(newValue);
      }
    },
    [setDatabaseSelection, archiveCurrentSola],
  );

  const canAddNew = useMemo(
    () => !allSolas.includes(newArchiveYear),
    [allSolas],
  );

  return (
    <Select
      label={`Archiv`}
      style={{ width: '150px' }}
      MenuProps={{
          getContentAnchorEl: () => null,
      }}
      value={databaseSelection}
      onChange={setValue}
      renderValue={renderValue}
      displayEmpty
    >
      <MenuItem value={''} key={'Aktuell'}>
        <ListItemText primary={'Aktuelle Daten'} />
      </MenuItem>
      <Divider />
      {
        allSolas.map(option => (
          <MenuItem value={option} key={option}>
            <ListItemText primary={option} />
          </MenuItem>
        ))
      }
      {
        permissions.includes('archive_sola') 
          ? (
            canAddNew
            ? (
              <MenuItem value={'newSola'} key={'newSola'}>
                <ListItemText primary={`Aktuelle Daten archivieren: ${newArchiveYear}`} />
              </MenuItem>
            )
            : (
              <MenuItem disabled value={'newSola'} key={'newSola'}>
                <ListItemText primary={`Aktuelle Daten archivieren nicht möglich`} secondary={`${newArchiveYear} bereits vorhanden`} />
              </MenuItem>
            )
          )
          : <></>
      }
    </Select>
  );
}
