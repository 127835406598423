import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';

import { useSecret } from '../../hooks/useSecret';
import getBackend from '../../hooks/getBackend';
import formatPersonData from '../../utils/formatPersonData';

import { useAllSolas } from './useAllSolas';
import { useConfig } from '../../hooks/useConfig';

const MyContext = createContext();
const { Provider } = MyContext;

export const useAllPersons = () => useContext(MyContext);

export default function AllPersonsProvider({children}) { 
    const [allPersons, setAllPersons] = useState([]);
    const { secret } = useSecret();
    const { prefix } = useAllSolas();
    const { config } = useConfig();

    const { enqueueSnackbar } = useSnackbar();

    const handlePersonsResponse = useCallback(async (response) => {
        const newPersons = await response.json() || [];
        setAllPersons(formatPersonData(newPersons, config));
    }, [setAllPersons, config]);

    const requestAllPersons = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/adminfields/getAllPersons.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handlePersonsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );

    useEffect(() => {
        if (secret) {
            requestAllPersons();
        }
    }, [secret, requestAllPersons]);
    
    const setPersonConfirmed = useCallback(
        async (personId, confirmedStatus, isMitarbeiter) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/adminfields/setPersonConfirmed.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            personId,
                            confirmedStatus,
                            isMitarbeiter,
                        })
                    }
                );

                handlePersonsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );
    
    const setPersonHasToPay = useCallback(
        async (personId, hasToPay) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/adminfields/setPersonHasToPay.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            personId,
                            hasToPay,
                        })
                    }
                );

                await handlePersonsResponse(response);
                enqueueSnackbar('Gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );
    
    const updatePerson = useCallback(
        async (person) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/adminfields/updatePerson.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...person,
                            secret,
                            prefix,
                        })
                    }
                );

                await handlePersonsResponse(response);
                enqueueSnackbar('Gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );
    
    const removePerson = useCallback(
        async (id) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/adminfields/removePerson.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            id,
                            secret,
                            prefix,
                        })
                    }
                );

                await handlePersonsResponse(response);
                enqueueSnackbar('Gelöscht', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handlePersonsResponse]
    );
    
    const [teilis, setTeilis] = useState([]);
    useEffect(() => {
        setTeilis(allPersons.filter(person => get(person, 'solaTeilnahme.length', 0) > 0 && person.applied > 0));
    }, [allPersons, setTeilis]);
    
    const [mitarbeiter, setMitarbeiter] = useState([]);
    useEffect(() => {
        setMitarbeiter(allPersons.filter(person => get(person, 'solaMitarbeit.length', 0) > 0 && person.appliedMitarbeiter > 0));
    }, [allPersons, setMitarbeiter]);

    return (
        <Provider 
            value={{
                allPersons, 
                requestAllPersons, 
                teilis, 
                mitarbeiter,
                setPersonConfirmed,
                setPersonHasToPay,
                updatePerson,
                removePerson,
                handlePersonsResponse,
            }}
        >
            {children}
        </Provider>
    );
};
