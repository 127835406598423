import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';

const MyContext = createContext();
const { Provider } = MyContext;

export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const useSecret = () => useContext(MyContext);

export default function SecretProvider({ children }) { 
    const [secret, setSecretState] = useState(localStorage.getItem('secret') || false);

    const setSecret = useCallback(newSecret => {
        if (newSecret !== secret) {
            setSecretState(newSecret);

            if (!newSecret) {
                localStorage.removeItem('secret');
            } else {
                localStorage.setItem('secret', newSecret);
            }
        }
    }, [setSecretState, secret]);

    useEffect(
        () => {
            const urlSecret = getParameterByName('secret');
            if (urlSecret) {
                setSecret(urlSecret);
            }
        },
        [setSecret]
    )

    return (
        <Provider 
            value={{secret, setSecret}}
        >
            {children}
        </Provider>
    );
};
