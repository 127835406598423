import React from "react";
import { DatePicker } from '@material-ui/pickers';
import { Controller } from "react-hook-form";
import Typography from '@material-ui/core/Typography';


const ControlledDatePicker = ({ 
  name, 
  defaultValue, 
  label, 
  required, 
  error,
  control,
  extendedProps,
  preLabel,
  postLabel,
}) => (
  <>
    { preLabel && <p>{preLabel}</p> }
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={{ required }}
      render={(properties) => (
        <DatePicker
          label={`${label}${required ? ' *' : ''}`}
          emptyLabel="Bitte auswählen"
          inputVariant="outlined"
          openTo="year"
          format="dd.MM.yyyy"
          fullWidth
          disableFuture
          cancelLabel="Abbrechen"
          okLabel="Bestätigen"
          error={error}
          {...properties}
          {...extendedProps}
          ref={undefined}
        />
      )}
    />
    { postLabel && <Typography variant="body1">{postLabel}</Typography> }
  </>
);

export default ControlledDatePicker;
