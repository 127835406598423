import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    height: 500,
    backgroundImage: 'url("./img/wide.jpg")',
    backgroundSize: 'cover',
  },
  overlay: {
    height: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.5),
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  margin: {
    margin: theme.spacing(2),
  },
  infoBg: {
    backgroundColor: theme.palette.grey[600],
  }
}));

export default function Impressum() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titlePaper}>
        <div className={classes.overlay}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">
                Sommerlager Zieko
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Paper style={{ margin: '16px  0', padding: '16px', textAlign: 'left'}}>
                <section> <b><font size="+2">Impressum</font></b> <br/>
                    &nbsp;
                    <p><b>Angaben gemäß § 5 TMG</b> </p>
                    <p>Evangelische Hoffnungsgemeinde Zieko - SOLA <br/>
                        Dorfstraße 2 <br/>
                        06869 Coswig OT Zieko </p>
                    <p><b>Vertreten durch:</b> <br/>
                        Kirchenratsvorsitzenden Andreas Nestmann </p>
                    <p><b>Kontakt:</b> <br/>
                        Tel.:&nbsp;&nbsp;&nbsp; 034903-496159 / 034903-62645 <br/>
                        Fax.:&nbsp;&nbsp;  034903-66558 <br/>
                        Email: <img alt="info email" src={'./img/emails/dark/info.png'}/> </p>
                    <p><b>Aufsichtsbehörde</b> <br/>
                        Evangelische Landeskirche Anhalts <br/>
                        Friedrichstraße 22-24 <br/>
                        06844 Dessau-Roßlau <br/>
                        Tel.: 0340-25260 </p>
                    <p><b>Haftungsausschluss</b> </p>
                    <p><b>Haftung für Inhalte</b> <br/>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt
                        erstellt. Für die Richtigkeit, Vollständigkeit und
                        Aktualität der Inhalte können wir jedoch keine Gewähr
                        übernehmen. Als Diensteanbieter sind wir gemäß
                        § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
                        den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
                        TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                        übermittelte oder gespeicherte fremde Informationen zu
                        überwachen oder nach Umständen zu forschen, die auf eine
                        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen nach den
                        allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                        diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                        Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
                        Inhalte umgehend entfernen. </p>
                    <p><b>Haftung für Links</b> <br/>
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                        deren Inhalte wir keinen Einfluss haben. Deshalb können wir
                        für diese fremden Inhalte auch keine Gewähr
                        übernehmen. Für die Inhalte der verlinkten Seiten ist
                        stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                        Verlinkung auf mögliche Rechtsverstöße
                        überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
                        der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend entfernen. </p>
                    <p><b>Urheberrecht</b> <br/>
                    Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte
                    und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung außerhalb der Grenzen des Urheberrechtes
                    bedürfen der Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten,
                    nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                    dieser Seite nicht vom Betreiber erstellt wurden, werden die
                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
                    als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                    werden wir derartige Inhalte umgehend entfernen. <br/>
                    &nbsp; <br/>
                    &nbsp; <br/>
                    &nbsp;&nbsp;&nbsp; <br/>
                    &nbsp;Evangelische Hoffnungsgemeinde Zieko&nbsp; | 034903-496159 / 034903-62645</p>
                </section>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
