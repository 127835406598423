import React, { useMemo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import get from 'lodash/get';

import { useOwnFields } from '../../hooks/useOwnFields';
import { completeFields } from '../../../utils/getAllFields';


export default function AutoCompleteField({ shownField, setShownField, label, hiddenFields = [] }) {
  const { ownFields } = useOwnFields();

  const fields = useMemo(
    () => completeFields
      .map(field => ({
        ...field,
        key: get(field, 'name', ''), 
        label: get(field, 'label', ''),
      }))
      .concat(ownFields.map(({ label, fieldId }) => ({ label, key: fieldId, name: fieldId })))
      .filter(field => Boolean(field.key) && Boolean(field.label) && !hiddenFields.includes(field.key)), 
    [ownFields, hiddenFields]
  );

  const handleChange = useCallback(
    (event, value) => {
      setShownField(value);
    },
    [setShownField]
  );

  return (
      <Autocomplete
        id="select-shown-field"
        autoComplete
        autoHighlight
        value={shownField}
        onChange={handleChange}
        options={fields}
        getOptionLabel={(option) => option.label}
        style={{ minWidth: 300 }}
        renderInput={(params) => <TextField {...params} label={label || "Angezeigtes Feld"} variant="outlined" />}
      />
    );
}
