import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  imageButton: {
    backgroundColor: '#141213',
    width: '100%',
  },
  image: {
    width: '50%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  }
}));

export default function ButtonBases() {
  const classes = useStyles();

  return (
    <Grid container spacing={0}>
        <Grid item xs={12}>
            <ButtonBase
              focusRipple
              className={classes.imageButton}
            >
              <img
                src={'/sola_flyer/Flyer_Vorderseite.png'}
                className={classes.image}
                alt="SOLA Flyer"
              />
            </ButtonBase>
        </Grid>
    </Grid>
  );
}