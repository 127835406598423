import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  drawerListItemIcon: {
    minWidth: theme.spacing(4),
  },
  selected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: theme.palette.primary.contrastText,
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
}));

export default function SelectableDrawerItem({ setSelectedPage, selectedPage, pageToSelect, badgeCount, label, icon }) {
  const classes = useStyles();

  return (
    <ListItem 
      button 
      classes={{ selected: classes.selected }}
      selected={selectedPage === pageToSelect}
      onClick={() => setSelectedPage(pageToSelect)}
    >
      <Badge badgeContent={badgeCount || 0} color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
        <ListItemIcon className={classes.drawerListItemIcon}>{icon}</ListItemIcon>
      </Badge>
      <ListItemText primary={label} />
    </ListItem>
  );
}
