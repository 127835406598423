import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

export default function MitarbeiterBedingungen() {
  return (
    <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <iframe 
                  style={{ width: '100%', height: '90vH' }}
                  src={'./bedingungen/mitarbeiterVorwort.html'}
                  title={'Mitarbeiter Vorwort'}
                />
            </Grid>
        </Grid>
    </Container>
  );
}