import React from "react";
import { Switch, Link, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import Login from "./login";
import Register from "./register";
import Teilnehmer from "../forms/teilnehmer";
import Mitarbeiter from "../forms/mitarbeiter";
import WelcomePage from "./home/welcomePage/welcomePage.js";
import Impressum from "./home/Impressum.js";
import Datenschutz from "./home/Datenschutz.js";
import Sola from "./home/Sola.js";
import Kontakt from "./home/Kontakt.js";
import Freizeitpass from "./freizeitpass/";
import SharedItem from "./shareditems/SharedItem";

import { useConfig } from '../hooks/useConfig';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  navButton: {
    color: "#fff",
  },
  navIcon: {
    margin: theme.spacing(1),
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();

  const { 
    config: {
      solaPreviewEnabled,
      teiliRegistrationEnabled,
      mitarbeiterRegistrationEnabled,
    }
  } = useConfig();

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <ButtonBase className={classes.navButton}>
              <HomeIcon className={classes.navIcon}/>
              <Typography className={classes.title} variant="h6"  component="span">
                SOLA Zieko
              </Typography>
            </ButtonBase>
          </Link>
          <div className={classes.grow} />
          <Link to="/login">
            <IconButton edge="end" className={classes.navButton}>
              <AccountCircle />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <Switch>
        {
          Boolean(solaPreviewEnabled) 
          && (
            <Route path="/sola">
              <Sola />
            </Route>
          )
        }
        {
          Boolean(teiliRegistrationEnabled) 
          && (
            <Route path="/teilnehmer">
              <Teilnehmer />
            </Route>
          )
        }
        {
          Boolean(mitarbeiterRegistrationEnabled) 
          && (
            <Route path="/mitarbeiter">
              <Mitarbeiter />
            </Route>
          )
        }
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route path="/kontakt">
          <Kontakt />
        </Route>
        <Route path="/freizeitpass">
          <Freizeitpass />
        </Route>
        <Route path="/sharedgroup">
          <SharedItem />
        </Route>
        <Route path="/">
          <WelcomePage />
        </Route>
        <Redirect to="/" />
      </Switch>
      <Divider/>
      <Container style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Grid container spacing={3}>
          <Grid item>
            <Button color="primary" href={'#'}>
              Start
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" href={'#impressum'}>
              Impressum
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" href={'#datenschutz'}>
              Datenschutz
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" href={'#kontakt'}>
              Kontakt
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
