import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { useOwnFields } from '../../hooks/useOwnFields';

export default function CoordsManagement() {
  const { coords, updateCoord } = useOwnFields();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card style={{ width: '100%' }}>
          <CardHeader title={`Startpunkt Teens`} />
          <CardContent>
            <TextField 
              defaultValue={coords.startingTeens}
              label={`Startpunkt Teens`}
              fullWidth
              onChange={event => updateCoord({ 
                name: 'startingTeens', 
                coords: event.target.value,
                id: coords.ids.startingTeens,
              })}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Card style={{ width: '100%' }}>
          <CardHeader title={`Startpunkt Kids`} />
          <CardContent>
            <TextField 
              defaultValue={coords.startingKids}
              label={`Startpunkt Kids`}
              fullWidth
              onChange={event => updateCoord({ 
                name: 'startingKids', 
                coords: event.target.value,
                id: coords.ids.startingKids,
              })}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
