import React, { useState, useMemo, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';

import { useSecret } from '../../hooks/useSecret';
import getBackend from '../../hooks/getBackend';

const MyContext = createContext();
const { Provider } = MyContext;

export const useAllSolas = () => useContext(MyContext);

export default function AllSolasProvider({children}) { 
    const [databaseSelection, setDatabaseSelection] = useState('');
    const [allSolas, setAllSolas] = useState([]);
    const { secret } = useSecret();

    const { enqueueSnackbar } = useSnackbar();

    const handleAllSolasResponse = useCallback(async (response) => {
        const newSolas = await response.json() || [];
        setAllSolas(newSolas.filter(sola => sola !== 'AKTUELL'));
    }, [setAllSolas]);

    const requestAllSolas = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/database/getAllSolas.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                        })
                    }
                );

                handleAllSolasResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, enqueueSnackbar, handleAllSolasResponse]
    );

    const archiveCurrentSola = useCallback(
        async (prefix) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/database/archiveCurrentSola.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handleAllSolasResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, enqueueSnackbar, handleAllSolasResponse]
    );

    useEffect(() => {
        if (secret) {
            requestAllSolas();
        }
    }, [secret, requestAllSolas]);

    const prefix = useMemo(
        () => databaseSelection ? `${databaseSelection}-` : '',
        [databaseSelection],
    );

    return (
        <Provider 
            value={{
                databaseSelection, 
                setDatabaseSelection, 
                allSolas, 
                archiveCurrentSola,
                prefix,
            }}
        >
            {children}
        </Provider>
    );
};
