import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Send from '@material-ui/icons/Send';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import EmailButton from './EmailButton';
import { getFieldsForEmail, mitarbeiterFields } from '../../utils//getAllFields';
import copyToClipboard from '../../utils//copyToClipboard';
import { usePayment } from '../hooks/usePayment';
import { useConfig } from '../../hooks/useConfig';
import { enritchPayments } from '../payment/';
import { formatAppliedDate } from '../../utils/getAppliedDate';
import get from 'lodash/get';

export default function MitarbeiterAnmeldebestätigungButton(props) {
  const { onClick } = props;
  const { payments } = usePayment();
  const { 
      config: { 
          kidsTerminStart,
          kidsTerminEnd,
          teensTerminStart,
          teensTerminEnd,
      }, 
  } = useConfig();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const copyId = `copy-${props.id}`;
  const copyHTML = useCallback(() => {
    copyToClipboard(copyId);
  }, [copyId]);

  const person = enritchPayments(props, payments);
  
  const usedStartDate = get(person, 'solaMitarbeit', []).includes('Teens') ? teensTerminStart : kidsTerminStart;
  const usedEndDate = get(person, 'solaMitarbeit', []).includes('Kids') ? kidsTerminEnd : teensTerminEnd;
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<Send/>}
        onClick={handleOpen}
      >
        {'Anmeldung bestätigen'}
      </Button>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Anmeldebestätigung</DialogTitle>
        <DialogActions>
          <Button onClick={copyHTML} color="primary">
            Kopieren
          </Button>
          <EmailButton
            onClick={onClick}
            email={props.email}
            subject={'Anmeldebestätigung Sommerlager Zieko für ' + props.prename + person.solaMitarbeit.length > 1 ? ' zu den SOLAs ' : ' zum SOLA ' + person.solaMitarbeit.join(', ')}
            message={''}
            label={'Email öffnen'}
          />
        </DialogActions>
        <DialogContent dividers>
          <div id={copyId}>
            <p>{person.gender === 'Weiblich' ? 'Liebe' : 'Lieber'} {person.prename},</p>
            {/* <p>
              {'die Mitarbeiter-Anmeldung '}
              {person.solaMitarbeit.length > 1 ? 'zu den SOLAs ' : 'zum SOLA '} 
              {person.solaMitarbeit.join(', ')} 
              {` vom ${formatAppliedDate(person)} ist angekommen. Damit bist du bei uns registriert.`}
            </p> */}
            <p>
              mit der offiziellen Bestätigung hat es etwas gedauert, entschuldige bitte.<br/>
              {`Aber nun hier endlich, deine ${ person.isTeenihelfer ? 'Anmeldung als Teenihelfer' : 'Mitarbeiter-Anmeldung'} vom ${formatAppliedDate(person)} `}
              {person.solaMitarbeit.length > 1 ? 'zu den SOLAs ' : 'zum SOLA '} 
              {person.solaMitarbeit.join(', ')} 
              {` vom ${(new Date(usedStartDate)).toLocaleDateString()} zum ${(new Date(usedEndDate)).toLocaleDateString()} ist angekommen. Damit bist du bei uns registriert.`} <br/>
              {
                person.isTeenihelfer 
                  ? 'Wir freuen uns, dass du den Teilnehmern auf dem SOLA deine Zeit, Kraft, Ideen und Zuwendung schenken, unsere Mitarbeitermannschaft bereichern, selbst Neues lernen und deine Begabgungen entdecken willst!' 
                  : 'Wir freuen uns, dass du den Teilnehmern auf dem SOLA wieder deine Zeit, Kraft, Ideen und Zuwendung schenken und die Mitarbeitermannschaft bereichern willst!'
              }
              <br/>
              {
                person.isTeenihelfer 
                  ? 'Bitte plane unbedingt deine Teilnahme für das Vorbereitungs- und Schulungstreffen in Zieko ein:' 
                  : 'Bitte plane unbedingt deine Teilnahme für das Vorbereitungstreffen in Zieko ein:'
              }
              <ul>
                <li>
                  {
                    person.isTeenihelfer 
                      ? 'ab Samstgabend, 1. Juni bis Sonntagmittag, 2. Juni' 
                      : 'ab Freitagabend, 30. Mai bis Sonntagnachmittag, 2. Juni'
                  }
                </li>
              </ul>
              {!person.isTeenihelfer && 'An diesem Wochenende wollen wir den Programmablauf besprechen, noch offene Programmpunkte planen und ggf. "testen", wichtige Schulungen durchführen, einen gemütlichen Abend miteinander haben, mit dem Anspielteam und der Band proben, Dekosachen und andere noch offene Sachen vorbereiten.'}
              In der nächsten Woche kommt noch mal eine Mail mit einem Nuudle. Trage dort bitte schnellstmöglich ein, wann du beim VBT dabei bist.
            </p>
            <p>
              Folgende Daten hast du bei der Anmeldung abgegeben:
              <br/>
              {getFieldsForEmail(person, [], mitarbeiterFields)}
            </p>
            <p>
              Du kannst diese noch einmal überprüfen und ggf. korrigieren, wenn du folgenden Link benutzen:
              <br/>
              <a href={`https://www.solazieko.de/#/mitarbeiter?secret=${person.secret}`}>https://www.solazieko.de/#/teilnehmer?secret={person.secret}</a>
              <br/>
              Alle Daten werden vertraulich behandelt und sind nur für die Verwendung im Rahmen des SOLAs bestimmt.
            </p>
            <p>
              Für das SOLA benötigen wir ein erweitertes polizeiliches Führungszeugnis von dir. Aus technischen Gründen erhältst du in einer extra Mail ein Schreiben, mit dem du das Führungszeugnis kostenlos beantragen kannst.
            </p>
            <p>Wenn du noch Fragen hast, melde dich bitte bei mir.</p>
            <p>
              Viele Grüße<br/>
              Helma
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
