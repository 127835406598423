import formatDate from './formatDate';

const getAppliedDate = ({ created, appliedDate }) => {
    if (appliedDate && appliedDate !== '0000-00-00 00:00:00') {
        return appliedDate;
    }

    return created;
};

export const formatAppliedDate = props => formatDate(getAppliedDate(props));

export default getAppliedDate;
