import React, { useMemo, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { useGroups } from '../../hooks/useGroups';

import AddGroupButton from './AddGroupButton';
import GroupsContainer from './dnd/GroupsContainer';

export const getPersonStatus = (person, groupLabel) => ({
  mitarbeiter: get(person, 'solaMitarbeit.length', 0) > 0 && person.appliedMitarbeiter && person.solaMitarbeit?.includes(groupLabel),
  teilnehmer: Boolean(person.solaTeilnahme) && person.applied && person.solaTeilnahme === groupLabel,
});

export const getPersonGroup = (person, enableTeenihelfer) => {
  if (enableTeenihelfer && person.isTeenihelfer) {
    return 'Teenihelfer';
  } 
  
  if (person.mitarbeiter) {
    return 'Mitarbeiter';
  }

  return 'Teilnehmer';
};

export const getGroupedPersons = (persons, groupLabel) => sortBy(
  persons
    .map(person => ({ ...person, ...getPersonStatus(person, groupLabel) }))
    .map(person => ({ ...person, personGroup: getPersonGroup(person, groupLabel !== 'Teens') })),
  'personGroup'
);

export default function GroupContainer({ groupLabel, allPersons }) {
  const { groups } = useGroups();

  const groupedPersons = useMemo(() => getGroupedPersons(allPersons, groupLabel), [allPersons, groupLabel]);

  const displayedGroups = useMemo(() => 
    groups.filter(group => group.groupLabel === groupLabel)
  , [groups, groupLabel]);

  const teensConfirmed = useMemo(
    () => groupedPersons
      .filter(person => 
        (person.confirmed && person.solaTeilnahme === 'Teens')
        || (person.confirmedMitarbeiter && person.solaMitarbeit.includes('Teens'))
      )
      .map(person => person.id),
    [groupedPersons]
  );

  const kidsConfirmed = useMemo(
    () => groupedPersons
      .filter(person => 
        (person.confirmed && person.solaTeilnahme === 'Kids')
        || (person.confirmedMitarbeiter && person.solaMitarbeit.includes('Kids'))
      )
      .map(person => person.id),
    [groupedPersons]
  );

  const aufbauConfirmed = useMemo(
    () => groupedPersons
      .filter(person => person.confirmedMitarbeiter && person.solaMitarbeit.includes('Aufbau'))
      .map(person => person.id),
    [groupedPersons]
  );

  const confirmedAssignedIds = useMemo(
    () => groupLabel === 'Teens' 
      ? teensConfirmed 
      : (groupLabel === 'Kids' ? kidsConfirmed : aufbauConfirmed),
    [groupLabel, teensConfirmed, kidsConfirmed, aufbauConfirmed]
  );

  const personsToAssign = useMemo(
    () => {
      const personsAssigned = uniq(
        displayedGroups
          .map(group => group.assignedPersonIds)
          .flat()
      );
      
      const personIdsToAssign = confirmedAssignedIds
        .filter(personId => !personsAssigned.includes(personId));

      return personIdsToAssign
        .map(personId => groupedPersons.find(person => person.id === personId))
        .filter(person => Boolean(person));
    },
    [displayedGroups, confirmedAssignedIds, groupedPersons]
  );

  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleSelectedGroupChange = useCallback(
    event => {
      const newSelectedGroups = event.target.value;

      setSelectedGroups(newSelectedGroups.includes('all') ? [] : newSelectedGroups);
    },
    [setSelectedGroups]
  );

  const visibleGroups = useMemo(
    () => {
      if (!selectedGroups.length) {
        return displayedGroups;
      }

      return displayedGroups.filter(group => selectedGroups.includes(group.id));
    },
    [displayedGroups, selectedGroups]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id={`selectGroupsLabel`}>
            {`Angezeigte Gruppen`}
          </InputLabel>
          <Select
            labelId={`selectGroupsLabel`}
            id={`selectGroups`}
            label={`Angezeigte Gruppen`}
            multiple
            MenuProps={{
                getContentAnchorEl: () => null,
            }}
            renderValue={
              (selected) => selected
                .map(groupId => displayedGroups.find(group => group.id === groupId))
                .map(group => group ? group.label : 'Nicht gefunden')
                .join(', ')
            }
            onChange={handleSelectedGroupChange}
            value={selectedGroups}
          >
            <MenuItem value={'all'} key={'all'} style={{ whiteSpace: 'break-spaces' }}>
              <Checkbox checked={!selectedGroups.length} />
              <ListItemText primary={'Alle'} />
            </MenuItem>
            {
              displayedGroups.map(group => (
                <MenuItem value={group.id} key={group.id} style={{ whiteSpace: 'break-spaces' }}>
                  <Checkbox checked={selectedGroups.includes(group.id)} />
                  <ListItemText primary={group.label} />
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        { 
          personsToAssign.length 
            ? <Tooltip
              arrow
              title={
                <>
                  {
                    personsToAssign
                      .map(
                        person => (
                          <div>
                            {`${person.prename} ${person.lastname}`}
                          </div>
                        )
                      )
                  }
                </>
              }
              placement="right-end"
            >
              <Button
                color="default"
                endIcon={<Info/>}
              >
                {`${personsToAssign.length} übrig`}
              </Button>
            </Tooltip>
            : <></>
        }
      </Grid>
      <Grid item xs={12}>
        <GroupsContainer 
          groups={[
            {
              label: 'Bestätigt',
              id: groupLabel === 'Teens' 
                  ? 'teensconfirmed' 
                  : (groupLabel === 'Kids' ? 'kidsconfirmed' : 'aufbauconfirmed'),
              assignedPersonIds: confirmedAssignedIds,
              personsToAssign: personsToAssign,
            }
          ].concat(visibleGroups)}
          groupedPersons={groupedPersons}
          groupLabel={groupLabel}
          confirmedAssignedIds={confirmedAssignedIds}
        />
      </Grid>
      <Grid item xs={12}>
        <AddGroupButton groupLabel={groupLabel}/>
      </Grid>
    </Grid>
  );
}
