import React, { useMemo } from 'react';
import ColumnManagement from './ColumnManagement';
import UserCard from './UserCard';
import TabBar from '../../components/TabBar';

export default function AdditionalDataFieldsManagement({ teilnehmer, mitarbeiter }) {

  const teiliCards = useMemo(() => 
    teilnehmer
      .map(person => <UserCard {...person} display={'teilis'} key={person.id}/>),
    [teilnehmer]
  );

  const mitarbeiterCards = useMemo(() => 
    mitarbeiter
      .map(person => <UserCard {...person} display={'mitarbeiter'} key={person.id}/>),
    [mitarbeiter]
  );

  return (
    <>
      <ColumnManagement/>
      <TabBar
        tabs={[
          {
            label: `Teilnehmer (${teiliCards.length})`,
            presentation: teiliCards,
          },
          {
            label: `Mitarbeiter (${mitarbeiterCards.length})`,
            presentation: mitarbeiterCards,
          },
        ]}
      />
    </>
  );
}
