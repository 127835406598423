import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TitleWithLink from './TitleWithLink';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function LinksSection() {
  const classes = useStyles();

  return (
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <TitleWithLink
            title={'Veranstalter'}
            description={
                'Das SOLA Zieko wird von der Evangelischen Hoffnungsgemeinde Zieko veranstaltet. Wir ' +
                'arbeiten eng mit Mitarbeitern aus verschiedenen Gemeinden und Verbänden zusammen und ' +
                'tun dies bis auf ganz wenige Ausnahmen ehrenamtlich neben unserem Beruf oder unserer Ausbildung.'
            }
            links={[{
              title: 'Ev. Hoffnungsgemeinde Zieko',
              url: 'http://www.hoffnungsgemeinde-zieko.de/',
              size: 'large'
            }]}
          />
          <TitleWithLink
            title={'SOLA ist eine Bewegung'}
            description={
                'Deutschlandweit finden in verschiedenen Regionen jedes Jahr ca. 40 SOLA`s statt, durchgeführt von Kirchgemeinden und Verbänden.'
            }
            links={[{
              title: 'www.sola-deutschland.de',
              url: 'http://www.sola-deutschland.de',
              size: 'large'
            }]}
          />
          <TitleWithLink
            title={'Kindern Urlaub schenken'}
            description={
                'Über die Aktion "Kindern Urlaub schenken" konnten schon mehrere Kinder an unserem Sommerlager teilnehmen.'
            }
            links={
              [
                {
                  title: 'Aktion Kindern Urlaub schenken',
                  url: 'http://www.urlaubschenken.de/',
                  size: 'large'
                },
              ]
            }
          />
        </Grid>
      </Container>
    
  );
}