import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import ListAlt from '@material-ui/icons/ListAlt';
import Work from '@material-ui/icons/Work';
import GroupWork from '@material-ui/icons/GroupWork';
import GetApp from '@material-ui/icons/GetApp';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Room from '@material-ui/icons/Room';
import Settings from '@material-ui/icons/Settings';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import People from '@material-ui/icons/People';
import Storage from '@material-ui/icons/Storage';

import Anmeldungen from '../anmeldungen';
import Payment from '../payment';
import AdditionalDataFields from '../verwaltung/additionalDataFields';
import Groups from '../verwaltung/groups';
import Coords from '../verwaltung/coords';
import UserAdministration from '../userAdministration';
import SOLASelection from './SOLASelection';
import CollapsableDrawerList from './CollapsableDrawerList';
import FilterBar from './FilterBar';
import CSVExportButton from './csvExportButton';
import PopupConfig from '../config/PopupConfig';
import AnmeldungConfig from '../config/AnmeldungConfig';
import SystemWidePersons from '../SystemWidePersons/SystemWidePersons';
import { completeFields, mitarbeiterFields, teilnehmerFields } from '../../utils/getAllFields';

import { useAllPersons } from '../hooks/useAllPersons';
import { useLogin } from '../../hooks/useLogin';

const drawerWidth = 240;

const getEnabledFilters = selectedPage => {
  switch (selectedPage) {
    case 'pay_teilnehmer': 
      return ['selectedSola'];
    case 'groups': 
      return [];
    default: 
      return ['adressDistrict', 'gender', 'selectedSola'];
  }
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  drawerListItemIcon: {
    minWidth: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  selected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: theme.palette.primary.contrastText,
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
  gutters: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
}));

export default function MyDrawer({ handleDrawerClose, open }) {
  const classes = useStyles();
  const theme = useTheme();
  const { permissions } = useLogin();
  const [selectedPage, setSelectedPage] = useState(permissions[0] || '');
  
  const { teilis, mitarbeiter, allPersons } = useAllPersons();

  const [filteredTeilis, setFilteredTeilis] = useState([]);
  const [filteredMitarbeiter, setFilteredMitarbeiter] = useState([]);

  const neueAnmeldungenTeilis = useMemo(() => filteredTeilis.filter(person => person.applied === 1 && (!person.confirmed || person.confirmed === 0)), [filteredTeilis]);
  const bestätigtTeilis = useMemo(() => filteredTeilis.filter(person => person.applied === 1 && person.confirmed === 1), [filteredTeilis]);
  const abgelehntTeilis = useMemo(() => filteredTeilis.filter(person => person.applied === 1 && person.confirmed === 2), [filteredTeilis]);

  const neueAnmeldungenMitarbeiter = useMemo(() => filteredMitarbeiter.filter(person => person.appliedMitarbeiter === 1 && (!person.confirmedMitarbeiter || person.confirmedMitarbeiter === 0)), [filteredMitarbeiter]);
  const bestätigtMitarbeiter = useMemo(() => filteredMitarbeiter.filter(person => person.appliedMitarbeiter === 1 && person.confirmedMitarbeiter === 1), [filteredMitarbeiter]);
  const abgelehntMitarbeiter = useMemo(() => filteredMitarbeiter.filter(person => person.appliedMitarbeiter === 1 && person.confirmedMitarbeiter === 2), [filteredMitarbeiter]);

  return (
    <>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <SOLASelection/>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <CollapsableDrawerList
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            listHeader={{ icon: <PeopleOutline/>, label: 'Teilnehmer' }}
            items={[
              {
                pageToSelect: 'anmeldungen_teili',
                badgeCount: neueAnmeldungenTeilis.length,
                icon: <InboxIcon/>,
                label: 'Anmeldungen',
              },
              {
                pageToSelect: 'pay_teilnehmer',
                icon: <EuroSymbol/>,
                label: 'Bezahlstatus',
              },
            ]}
          />
          <CollapsableDrawerList
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            listHeader={{ icon: <SupervisorAccount/>, label: 'Mitarbeiter' }}
            items={[
              {
                pageToSelect: 'anmeldungen_mitarbeiter',
                badgeCount: neueAnmeldungenMitarbeiter.length,
                icon: <InboxIcon/>,
                label: 'Anmeldungen',
              }
            ]}
          />
          <CollapsableDrawerList
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            listHeader={{ icon: <Work/>, label: 'Verwaltung' }}
            items={[
              {
                pageToSelect: 'additional_data_fields',
                icon: <ListAlt/>,
                label: 'Zusätzliche Daten',
              },
              {
                pageToSelect: 'groups',
                icon: <GroupWork/>,
                label: 'SOLA Gruppen',
              },
              {
                pageToSelect: 'coords',
                icon: <Room/>,
                label: 'Koordinaten',
              },
            ]}
          />
          <CollapsableDrawerList
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            listHeader={{ icon: <Settings/>, label: 'Einstellungen' }}
            items={[
              {
                pageToSelect: 'config_popup',
                icon: <OpenInBrowser/>,
                label: 'Homepage Popup',
              },
              {
                pageToSelect: 'config_registrations',
                icon: <PeopleOutline/>,
                label: 'SOLA / Anmeldungen',
              },
            ]}
          />
          <CollapsableDrawerList
            setSelectedPage={() => {}}
            selectedPage={selectedPage}
            defaultOpen={false}
            listHeader={{ icon: <GetApp/>, label: 'CSV Export' }}
            items={[
              {
                pageToSelect: '',
                permission: 'csvexport',
                icon: <SupervisedUserCircle/>,
                label: <CSVExportButton persons={allPersons} fields={completeFields} title={'Alle Personen'}/>,
              },
              {
                pageToSelect: '',
                permission: 'csvexport',
                icon: <PeopleOutline/>,
                label: <CSVExportButton persons={bestätigtTeilis} fields={teilnehmerFields} title={'Bestätigte Teilnehmer'}/>,
              },
              {
                pageToSelect: '',
                permission: 'csvexport',
                icon: <SupervisorAccount/>,
                label: <CSVExportButton persons={bestätigtMitarbeiter} fields={mitarbeiterFields} title={'Bestätigte Mitarbeiter'}/>,
              },
            ]}
          />
          <CollapsableDrawerList
            defaultOpen={false}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            listHeader={{ icon: <Storage/>, label: 'System-weit' }}
            items={[
              {
                pageToSelect: 'assign_roles',
                icon: <VerifiedUser/>,
                label: 'Benutzer verwalten',
              },
              // {
              //   pageToSelect: 'systemwide_persons',
              //   icon: <People/>,
              //   label: 'Alle Personen',
              // },
            ]}
          />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
            <div className={classes.drawerHeader} />
            { 
              selectedPage !== 'assign_roles' &&
              selectedPage !== 'coords' &&
              selectedPage !== 'config_popup' &&
              selectedPage !== 'config_registrations' &&
              selectedPage !== 'systemwide_persons' &&
              <FilterBar
                teilis={teilis}
                mitarbeiter={mitarbeiter}
                setFilteredTeilis={setFilteredTeilis}
                setFilteredMitarbeiter={setFilteredMitarbeiter}
                enabledFilters={getEnabledFilters(selectedPage)}
              />
            }
            
            {
                selectedPage === 'anmeldungen_teili' && 
                <Anmeldungen 
                    display={'teilis'} 
                    neueAnmeldungen={neueAnmeldungenTeilis}
                    bestätigt={bestätigtTeilis}
                    abgelehnt={abgelehntTeilis}
                />
            }
            {
                selectedPage === 'pay_teilnehmer' && 
                <Payment 
                    bestätigt={bestätigtTeilis}
                />
            }
            {
                selectedPage === 'anmeldungen_mitarbeiter' && 
                <Anmeldungen 
                    display={'mitarbeiter'} 
                    neueAnmeldungen={neueAnmeldungenMitarbeiter}
                    bestätigt={bestätigtMitarbeiter}
                    abgelehnt={abgelehntMitarbeiter}
                />
            }
            {
                selectedPage === 'additional_data_fields' && 
                <AdditionalDataFields 
                  teilnehmer={filteredTeilis}
                  mitarbeiter={filteredMitarbeiter}
                />
            }
            {
                selectedPage === 'groups' && 
                <Groups 
                  bestätigtTeilis={bestätigtTeilis}
                  bestätigtMitarbeiter={bestätigtMitarbeiter}
                />
            }
            {
                selectedPage === 'coords' && 
                <Coords />
            }
            {
                selectedPage === 'assign_roles' && 
                <UserAdministration />
            }
            {
                selectedPage === 'config_popup' && 
                <PopupConfig />
            }
            {
                selectedPage === 'config_registrations' && 
                <AnmeldungConfig />
            }
            {
                // selectedPage === 'systemwide_persons' && 
                // <SystemWidePersons allPersons={allPersons} />
            }
        </main>
    </>
  );
}
