import React from 'react';
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: './img/diashow/01-Lager.jpg',
    thumbnail: './img/diashow/01-Lager.jpg',
  },
  {
    original: './img/diashow/02-bauen3.JPG',
    thumbnail: './img/diashow/02-bauen3.JPG',
  },
  {
    original: './img/diashow/03-Gruppenbilder1.jpg',
    thumbnail: './img/diashow/03-Gruppenbilder1.jpg',
  },
  {
    original: './img/diashow/04-bauen2.JPG',
    thumbnail: './img/diashow/04-bauen2.JPG',
  },
  {
    original: './img/diashow/05-Lagerleben1.JPG',
    thumbnail: './img/diashow/05-Lagerleben1.JPG',
  },
  {
    original: './img/diashow/06-Lagerleben2.JPG',
    thumbnail: './img/diashow/06-Lagerleben2.JPG',
  },
  {
    original: './img/diashow/07-bauen1.JPG',
    thumbnail: './img/diashow/07-bauen1.JPG',
  },
  {
    original: './img/diashow/08-Gruppenbilder2.JPG',
    thumbnail: './img/diashow/08-Gruppenbilder2.JPG',
  },
  {
    original: './img/diashow/09-Lagerleben3.JPG',
    thumbnail: './img/diashow/09-Lagerleben3.JPG',
  },
  {
    original: './img/diashow/10-Workshop1.JPG',
    thumbnail: './img/diashow/10-Workshop1.JPG',
  },
  {
    original: './img/diashow/11-wandern.JPG',
    thumbnail: './img/diashow/11-wandern.JPG',
  },
  {
    original: './img/diashow/12-baden.JPG',
    thumbnail: './img/diashow/12-baden.JPG',
  },
  {
    original: './img/diashow/13-Wasserrutsche.JPG',
    thumbnail: './img/diashow/13-Wasserrutsche.JPG',
  },
  {
    original: './img/diashow/14-Theater.jpg',
    thumbnail: './img/diashow/14-Theater.jpg',
  },
  {
    original: './img/diashow/15-band.JPG',
    thumbnail: './img/diashow/15-band.JPG',
  },
  {
    original: './img/diashow/16-Lagerleben4.JPG',
    thumbnail: './img/diashow/16-Lagerleben4.JPG',
  },
  {
    original: './img/diashow/17-Weihnachts-SOLA.JPG',
    thumbnail: './img/diashow/17-Weihnachts-SOLA.JPG',
  },
];

export default function MyGallery() {
    return <ImageGallery items={images} />;
};
