import React, { useCallback, useMemo } from "react";
import cloneDeep from 'lodash/cloneDeep';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { usePersons } from '../../hooks/usePersons';
import { useConfig } from '../../hooks/useConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
    },
    extendedIcon: {
        marginRight: theme.spacing(1), 
    }
}));

export default function PersonSelect({ gotoForm, disableNew, onlyTeili }) {
  const { selectedPerson, setSelectedPerson, removePerson, persons } = usePersons();
  const { 
    config: { 
        solaTitle,
    }, 
  } = useConfig();

  const classes = useStyles();

  const teilnehmerApplied = useMemo(() => persons.filter(person => person.applied), [persons]);
  const mitarbeiterApplied = useMemo(() => persons.filter(person => person.appliedMitarbeiter), [persons]);
  const personsNotApplied = useMemo(() => persons.filter(person => !person.appliedMitarbeiter && !person.applied), [persons]);

  const addNewPerson = useCallback(() => {
    const newPerson = cloneDeep(selectedPerson || personsNotApplied[0] || teilnehmerApplied[0] || mitarbeiterApplied[0] || {});
    delete newPerson.applied;
    delete newPerson.appliedMitarbeiter;
    delete newPerson.id;
    delete newPerson['prename'];
    delete newPerson.entity;
    delete newPerson.lastUpdated;

    setSelectedPerson(newPerson);
    gotoForm();
  }, [selectedPerson, personsNotApplied, teilnehmerApplied, mitarbeiterApplied, setSelectedPerson, gotoForm]);

  const handleSelect = useCallback((person) => {
    setSelectedPerson(person);
    gotoForm();
  }, [setSelectedPerson, gotoForm]);

  return (
    <Grid container spacing={3} className={classes.root}>
        { !disableNew &&
            <Grid item xs={12}>
                <Fab
                    color="primary" 
                    variant="extended"
                    onClick={addNewPerson}
                >
                    <AddIcon className={classes.extendedIcon}/>
                    Neue Anmeldung
                </Fab>
            </Grid>
        }
        { 
            personsNotApplied.length && !onlyTeili ? 
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Noch nicht angemeldet:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                { 
                                    personsNotApplied.map(person => 
                                        <Grid item key={person.id}>
                                            <Chip
                                                icon={<FaceIcon/>}
                                                label={person['prename']}
                                                color={'primary'}
                                                variant={person.id === selectedPerson.id ? 'default' : 'outlined'}
                                                onClick={() => handleSelect(person)}
                                                onDelete={() => removePerson(person)}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>:
                ""
        }
        { 
            teilnehmerApplied.length ? 
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Teilnehmer angemeldet für {solaTitle}:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                { 
                                    teilnehmerApplied.map(person => 
                                        <Grid item key={person.id}>
                                            <Chip
                                                icon={<FaceIcon/>}
                                                label={person['prename']}
                                                color={'primary'}
                                                variant={person.id === selectedPerson.id ? 'default' : 'outlined'}
                                                onClick={() => handleSelect(person)}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>:
                ""
        }
        { 
            mitarbeiterApplied.length && !onlyTeili ? 
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Mitarbeiter angemeldet für {solaTitle}:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                { 
                                    mitarbeiterApplied.map(person => 
                                        <Grid item key={person.id}>
                                            <Chip
                                                icon={<FaceIcon/>}
                                                label={person['prename']}
                                                color={'primary'}
                                                variant={person.id === selectedPerson.id ? 'default' : 'outlined'}
                                                onClick={() => handleSelect(person)}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>:
                ""
        }
    </Grid>
  );
}
