import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';
import cloneDeep from 'lodash/cloneDeep';

import { useSecret } from '../../hooks/useSecret';
import getBackend from '../../hooks/getBackend';
import { useAllSolas } from './useAllSolas';

const MyContext = createContext();
const { Provider } = MyContext;

export const useGroups = () => useContext(MyContext);

export default function GroupsProvider({ children, setEditingSharedItem, editingSharedItem }) { 
    const [groups, setGroups] = useState([]);
    const { secret } = useSecret();
    const { prefix } = useAllSolas();

    const { enqueueSnackbar } = useSnackbar();

    const handleGroupsResponse = useCallback(async (response) => {
        const newGroups = await response.json() || [];
        setGroups(newGroups.map(group => ({ ...group, assignedPersonIds: JSON.parse(group.assignedPersonIds || '[]')})));
    }, [setGroups]);

    const requestGroups = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/getGroups.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handleGroupsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleGroupsResponse]
    );

    const addGroup = useCallback(
        async (group) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/addGroup.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            ...group,
                        })
                    }
                );

                handleGroupsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleGroupsResponse]
    );

    const setGroup = useCallback(
        async (group) => {
            const newGroups = cloneDeep(groups);
            const editedGroupIndex = newGroups.findIndex(oldGroup => oldGroup.id === group.id);
            newGroups[editedGroupIndex] = group;
            setGroups(newGroups);

            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/setGroup.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            ...group,
                        })
                    }
                );

                handleGroupsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [groups, secret, prefix, enqueueSnackbar, handleGroupsResponse, setGroups]
    );

    const deleteGroup = useCallback(
        async (groupId) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/deleteGroup.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            groupId,
                        })
                    }
                );

                handleGroupsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleGroupsResponse]
    );

    const [shownField, setShownField] = useState(false);

    const [sharedItems, setSharedItems] = useState([]);

    const handleSharedItemsResponse = useCallback(async (response) => {
        const newItems = await response.json() || [];
        setSharedItems(newItems.map(item => ({
            ...item,
            columns: 
                item.columns && typeof item.columns === 'string' 
                ? JSON.parse(item.columns) : 
                [],
        })));
    }, [setSharedItems]);

    
    const getSharedItems = useCallback(
        async () => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/getSharedItems.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                        })
                    }
                );

                handleSharedItemsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleSharedItemsResponse]
    );

    const addSharedItem = useCallback(
        async (newItem) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/addSharedItem.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            ...newItem,
                        })
                    }
                );

                handleSharedItemsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleSharedItemsResponse]
    );

    const setSharedItem = useCallback(
        async (item) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/setSharedItem.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            ...item,
                        })
                    }
                );

                handleSharedItemsResponse(response);
                enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' });
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleSharedItemsResponse]
    );

    const deleteSharedItem = useCallback(
        async (id) => {
            try {
                const response = await fetch(
                    `${getBackend()}/admin/groups/deleteSharedItem.php`,
                    { 
                        method: 'POST', 
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            secret,
                            prefix,
                            id,
                        })
                    }
                );

                handleSharedItemsResponse(response);
            } catch(error) {
                console.log(error);
                enqueueSnackbar('Server Verbindung fehlgeschlagen. Bitte versuchen Sie es später erneut', { variant: 'error' });
            }
        },
        [secret, prefix, enqueueSnackbar, handleSharedItemsResponse]
    );
    

    useEffect(() => {
        if (secret) {
            requestGroups(secret);
            getSharedItems(secret);
        }
    }, [secret, requestGroups, getSharedItems]);

    return (
        <Provider 
            value={{ 
                groups, 
                requestGroups, 
                addGroup, 
                setGroup, 
                deleteGroup,

                shownField,
                setShownField,

                sharedItems,
                addSharedItem,
                deleteSharedItem,
                setSharedItem,

                editingSharedItem, 
                setEditingSharedItem,
            }}
        >
            {children}
        </Provider>
    );
};
