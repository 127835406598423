import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { Draggable } from 'react-beautiful-dnd';

import { getFieldValue } from '../../../../utils/getAllFields';
import { useGroups } from '../../../hooks/useGroups';

export default function GroupPerson(person) {
  const { id, index, prename, lastname, gender, personPrefix, solaAge, alreadyAssigned } = person;
  const { shownField } = useGroups();

  return (
    <Grid item>
      <Draggable draggableId={`${personPrefix}-${id}`} index={index}>
        {(provided) => (
          <div 
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
              <Chip 
                avatar={<Avatar>{(prename[0] || '').toUpperCase()}</Avatar>} 
                label={
                  <>
                    {`${prename} ${lastname} (${solaAge})`}
                    {
                      shownField 
                      ? <><br/>{getFieldValue(person, shownField)}</>
                      : <></>
                    }
                  </>
                }
                color={gender === 'Weiblich' ? 'secondary' : 'primary'}
                variant={alreadyAssigned ? 'outlined' : 'default'}
              />
          </div>
        )}
      </Draggable>
    </Grid>
  );
}
