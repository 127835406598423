const TiktokIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" width="1em" height="1em">
    <rect height="412.22" rx="55.43" width="412.22" x="49.89" y="49.89" fill="currentColor"/>
    <path d="M348.31,201V190.9s-22.7-1.43-39.31-19.4C319.78,191.19,337.8,198.44,348.31,201Z" fill="#80c7cf"/>
    <path d="M171.34,274.28c15.3-30.41,42.44-36,56-36.77V226s-43.34-5-64.33,36.72c0,0-21.87,41.89,8.4,76C154,307.38,171.34,274.28,171.34,274.28Z" fill="#80c7cf"/>
    <path d="M235.11,341.37c34-5.8,33.87-34,33.87-34V148.75h27a77,77,0,0,1-2.89-11.59H260.7V295.81s.9,31.23-33.87,34c0,0-15.14,1.19-26.42-8.71C210,343.36,235.11,341.37,235.11,341.37Z" fill="#80c7cf"/>
    <path d="M301.37,148.75H296a60.94,60.94,0,0,0,13,22.75A70.31,70.31,0,0,1,301.37,148.75Z" fill="#e03855"/>
    <path d="M188.45,291.67c.5,14.8,5.63,23.82,12,29.39a48.5,48.5,0,0,1-3.67-17.79s.82-32.86,38.92-31.75v-34a56.49,56.49,0,0,0-8.28-.05v22.41C189.28,258.82,188.45,291.67,188.45,291.67Z" fill="#e03855"/>
    <path d="M348.31,201V227.9c-36.72,3.86-55.22-26.51-55.22-26.51V295c-2.21,32.31-18.77,46.11-18.77,46.11-44.36,41-87.8,10.77-87.8,10.77a81.7,81.7,0,0,1-15.07-13.16c4.79,8.63,12.21,17.12,23.35,24.75,0,0,43.07,31.75,87.8-10.76,0,0,17.34-14.55,18.77-46.11V213s18.5,30.37,55.22,26.5v-37A53.5,53.5,0,0,1,348.31,201Z" fill="#e03855"/>
    <path d="M186.52,351.86s44.13,30.45,87.8-10.77c0,0,17.8-13.44,18.77-46.11V201.39s18.5,30.37,55.22,26.51V201c-10.51-2.6-28.53-9.85-39.31-29.54a60.94,60.94,0,0,1-13-22.75H269V307.41s.09,28.16-33.87,34c0,0-25.09,2-34.7-20.31-6.33-5.57-11.46-14.59-12-29.39,0,0,.83-32.85,38.93-31.75V237.51c-13.6.8-40.74,6.36-56,36.77,0,0-17.3,33.1.11,64.42A81.7,81.7,0,0,0,186.52,351.86Z" fill="#fff"/>
  </svg>
);

export default TiktokIcon;
