import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import DetailDialog from './DetailDialog';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  container: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
  media: {
    height: 180,
    width: '100%',
  },
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  flexBox: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  fullWidth: {
      width: '100%',
  }
}));

export default function MediaCard(props) {
    const { title, image, description, details, actionProps, md = 4, sm = 6, xs = 12 } = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Grid item xs={xs} sm={sm} md={md}>
            <Card className={classes.card}>
                <CardActionArea onClick={handleClickOpen} className={classes.flexBox} {...actionProps}>
                    <div className={classes.fullWidth}>
                        <CardMedia
                            className={classes.media}
                            image={image}
                            title={title}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component={typeof description === 'string' ? 'p' : 'div'}>
                                {description}
                            </Typography>
                        </CardContent>
                    </div>
                    <CardActions>
                        <Button variant="outlined" color="primary" component="span">
                            Mehr
                        </Button>
                    </CardActions>
                </CardActionArea>
            </Card>
            {
                details && 
                <DetailDialog
                    title={title}
                    description={description}
                    details={details}
                    open={open}
                    handleClose={handleClose}
                />
            }
        </Grid>
    );
}