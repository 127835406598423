import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { getFieldValue } from '../../../../utils/getAllFields';

export default function SharedItemTable({ groupedPersons, personGroup, sharedItem = {} }) {
  const displayedPersons = useMemo(
    () => groupedPersons.filter(person => person.personGroup === personGroup),
    [personGroup, groupedPersons]
  );

  return displayedPersons.length ? (
    <Grid item xs={12}>
      <Typography variant="p">{personGroup}</Typography>
      <TableContainer component={Paper} style={{ width: '100%'}}>
        <Table>
          <TableHead>
            <TableRow>
              {
                sharedItem.columns.map((column, index) => (
                  <TableCell key={index} style={{ whiteSpace: 'nowrap' }}>{column.label}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              displayedPersons
                .sort((a,b) => {
                  if (a.lastname === b.lastname) {
                    return 0;
                  }

                  return a.lastname > b.lastname ? -1 : 1;
                })
                .map((person) => (
                  <TableRow key={person.id}>
                      {
                        sharedItem.columns.map((column, index) => (
                          <TableCell key={index}>{getFieldValue(person, column)}</TableCell>
                        ))
                      }
                  </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  ) : <></>;
}
