import React, { useMemo } from 'react';
import UserCard from './UserCard';
import RoleManagement from './RoleManagement';
import { useUsers } from '../hooks/useUsers';
import TabBar from '../components/TabBar';

const filteredRoles = ['admin', 'user', 'abgelehnt'];

export default function UserAdministration() {
  const { users } = useUsers();

  const admins = useMemo(() => users.filter(person => person.role === filteredRoles[0]), [users]);
  const usersWaiting = useMemo(() => users.filter(person => person.role === filteredRoles[1]), [users]);
  const abgelehnt = useMemo(() => users.filter(person => person.role === filteredRoles[2]), [users]);
  const others = useMemo(() => users.filter(person => !filteredRoles.includes(person.role)), [users]);

  return (
    <>
      <RoleManagement/>
      <TabBar
        tabs={[
          {
            label: `Benutzer warten auf Rechte (${usersWaiting.length})`,
            presentation: usersWaiting.map(person => <UserCard {...person} />)
          },
          {
            label: `Administratoren (${admins.length})`,
            presentation: admins.map(person => <UserCard {...person} />)
          },
          {
            label: `Abgelehnt (${abgelehnt.length})`,
            presentation: abgelehnt.map(person => <UserCard {...person} />)
          },
          {
            label: `Andere (${others.length})`,
            presentation: others.map(person => <UserCard {...person} />)
          },
        ]}
      />
    </>
  );
}
