import React from 'react';

import SharedItemTable from './SharedItemTable';

export default function SharedItemTableWrapper({ groupedPersons, sharedItem }) {
  const allActive = !sharedItem.mitarbeit && !sharedItem.teilnahme;

  console.log(groupedPersons);
  return (
    <>
      {
        (sharedItem.mitarbeit || allActive) && (
          <>
            <SharedItemTable
              sharedItem={sharedItem}
              groupedPersons={groupedPersons}
              personGroup={'Mitarbeiter'}
            />
            <SharedItemTable
              sharedItem={sharedItem}
              groupedPersons={groupedPersons}
              personGroup={'Teenihelfer'}
            />
          </>
        )
      }
      {
        (sharedItem.teilnahme || allActive) && (
          <SharedItemTable
            sharedItem={sharedItem}
            groupedPersons={groupedPersons}
            personGroup={'Teilnehmer'}
          />
        )
      }
    </>
  );
}
