import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardWithDialog from './CardWithDialog';
import DailyRoutine from './DailyRoutine';

const cards = [
  {
    title: 'Was ist SOLA?',
    description: 'SOLA ist ein Zeltlager der besonderen Art für Kinder und Teenager. Auf einer grünen Wiese am Waldrand entsteht eine kleine Zeltstadt mit vielen Angeboten.',
    details: 
      'Abenteuer, Aktionen, Sport, Spiel und Spaß werden verbunden mit der Botschaft der Bibel ' +
      'und in die Lebenswelt der Teilnehmer "übersetzt". Dies geschieht sowohl in morgendlichen ' +
      'Gesprächrunden in den Zeltgruppen als auch bei gemeinsamen Abenden im Großzelt und ' +
      'nebenbei beim Gespräch über das eigene Leben, Gott und die Welt.',
    image: './img/Was_ist_SOLA.JPG',
  },
  {
    title: 'Unterbringung',
    description: 'Die Teilnehmer leben eine Woche lang mit ihren Mitarbeitern in 3-4 Familiengruppen.',
    details: 'In jeder Familie leben ca. 20-25 Teilnehmer mit ihren 6 - 8 Mitarbeitern in meist 2 Jungs- und 2 Mädchenzelten. Jede Familie hat eine Mutter und einen Vater, die diese Gruppe leiten.',
    image: './img/Unterbringung.JPG',
  },
  {
    title: 'Bauwerke',
    description: 'Das Familiengebiet wird nach eigenen Vorstellungen gestaltet. Dazu hat jede Gruppe eigenes Werkzeug und es gibt genügend Bauholz. Es entstehen z.B. Türme, Tore, Schaukeln oder auch ein eigener Pool.',
    details: 'Die Bauwerke werden während der Bauzeit und zum Abschluss fotografiert und nach dem SOLA abgebaut.',
    image: './img/Bauwerke2.JPG',
  },
  {
    title:'Programm',
    description: 'Das Programm ist stark erlebnisorientiert ausgerichtet. Jedes Jahr gibt es ein neues Lagerthema, das mit viel Aufwand vorbereitet wird.',
    details:
      'In einem Jahr sind wir Israeliten in Babylon, im anderen Jahr Schauspieler in Hollywood oder Sklaven im Amerika des 18. ' +
      'Jahrhunderts.Kids und Mitarbeiter leben spielerisch in dieser Rolle und Handlung, die sich durch die gemeinsame Woche zieht. ' +
      'Höhen und Tiefen werden zusammen durchlebt, Lösungen für verfahrene Situationen gesucht, Rivalitäten sinnvoll ausgetragen. ' +
      'Das sind prägende Erlebnisse, die man so schnell nicht vergisst.',
    image: './img/Programm1.jpg',
  },
  {
    title: 'Tagesablauf',
    description: 'Wir strukturieren jeden Tag, sodass alle Teilnehmer und Mitarbeiter in einen gemeinsamen Rhythmus kommen.',
    details:
      <>
        <DailyRoutine/>
        {
          'Bei besonderen Aktionen wie Tageswanderungen oder Nachtaktionen kann der Tagesablauf anders aussehen. ' +
          'Eine Gruppe hat immer Nachtwache und teilt sich die Nacht auf. ' +
          'Die Nachtruhe gilt für Teilnehmer und Mitarbeiter gleichermaßen, die Mitarbeiter stehen allerdings früher auf.'
        }
      </>,
    image: './img/Tagesablauf1.JPG',
  },
  {
    title: 'Wir sind',
    description: 'eine Gruppe von engagierten Christen, die das SOLA 1999 als gute Möglichkeit entdeckt hat, Kindern und Jugendlichen eine spannende Ferienzeit zu gestalten und ihnen damit oft neue Horizonte zu öffnen.',
    details:
      'Das passiert im Miteinander, im Nachdenken über sich, die Welt und Gott, im Entdecken von Fähigkeiten und Begabungen und ' +
      'Akzeptieren von (eigenen) Grenzen und Regeln des Zusammenlebens. Bei allem ist uns wichtig, die Kreativität ' +
      'der Teilnehmer anzuregen, sie zu ermutigen, sich aktiv in die Gemeinschaft einzubringen und sie als einzigartige Persönlichkeiten zu stärken',
    image: './img/wir_sind.JPG',
  },
];

export default function MediaCard() {
  return (
      <Container 
        style={{
          textAlign: 'left',
          marginTop: '32px',
          marginBottom: '32px',
        }}
      >
        <Grid container spacing={3}>
          {cards.map((card, index) => <CardWithDialog key={index} {...card}/>)}
        </Grid>
      </Container>
  );
}